import React, {useEffect, useState} from "react";
import {Box, Container, useMediaQuery} from "@mui/material";
import styled from "styled-components";
import fifaLogo from "assets/images/logos/fifa_logo.svg";
import {MenuIcons} from "./MenuIcons";
import {MenuLinks, SubMenuLinks} from "./MenuLinks";
import {DropdownMenu, DropdownButton} from "./Dropdown";
import {HamburgerMenu} from "./HamburgerMenu";
import {DesktopSubNavigation} from "./DesktopSubNavigation";
import {useSelector} from "react-redux";
import {isMenuOpen} from "modules/selectors";
import {debounce} from "lodash";
import {Exist} from "components/Exist";
import {media} from "assets/css/media";
import {LanguageUnsupported} from "./LanguageUnsupported";
import {useTranslation} from "react-i18next";
import {MobileSubNavigation} from "./MobileSubNavigation";
import {IS_APP} from "modules";

const Wrapper = styled.header`
	display: flex;
	align-items: center;
	flex-direction: column;
	background-color: #045694;
	position: sticky;
	top: 0;
	z-index: 500;

	@media screen and (max-width: ${media.mobile}) {
		height: max-content;
	}
`;

const StyledContainer = styled(Container)`
	border-bottom: 0.5px solid #a5acbb;
`;

const InnerWrapper = styled.div`
	height: 31px;
	display: flex;
	align-items: center;
	justify-content: flex-end;

	@media (max-width: ${media.tablet}) {
		display: none;
	}
`;

const DesktopLinks = styled.div`
	display: flex;
	align-items: center;
	gap: 24px;
`;

const ExternalLink = styled.a`
	font-size: 12px;
	font-weight: 500;
	color: #e4e8f0;
	transition: 0.2s color ease-in-out;

	&:hover {
		color: #fff;
	}
`;

const DesktopMenuWrapper = styled.div`
	position: relative;
	height: 64px;
	width: 100%;
	padding-inline-start: 24px;
	padding-inline-end: 24px;
	display: flex;
	justify-content: space-between;
	@media (max-width: ${media.tablet}) {
		display: none;
	}
`;

const DesktopMenuInnerWrapper = styled.div`
	display: flex;
	align-items: center;
	overflow-x: hidden;
`;

const MobileMenuWrapper = styled.div`
	display: none;
	align-items: center;
	height: 59px;
	@media (max-width: ${media.tablet}) {
		display: flex;
	}
`;

const Logo = styled.img`
	width: 104px;
`;

const DesktopMenu: React.FC = () => (
	<DesktopMenuWrapper>
		<DesktopMenuInnerWrapper>
			<DropdownButton />
			<a href="https://www.fifa.com/fifaplus">
				<Logo src={fifaLogo} alt="FIFA" />
			</a>
			<MenuLinks />
		</DesktopMenuInnerWrapper>
		<MenuIcons />

		<SubMenuLinks />
	</DesktopMenuWrapper>
);

const MobileMenu: React.FC = () => {
	const isMobile = useMediaQuery(`(max-width: ${media.tablet})`);
	const showMobileMenu = isMobile || IS_APP;

	return (
		<MobileMenuWrapper>
			<Box
				sx={{
					flex: "1",
				}}>
				<Exist when={showMobileMenu}>
					<HamburgerMenu />
				</Exist>
				<Exist when={!showMobileMenu}>
					<DropdownButton />
				</Exist>
			</Box>
			<Box
				sx={{
					flex: "1",
					textAlign: "center",
				}}>
				<a href="https://www.fifa.com/fifaplus">
					<Logo src={fifaLogo} alt="FIFA" />
				</a>
			</Box>
			<Box
				sx={{
					flex: "1",
				}}>
				<MenuIcons />
			</Box>
		</MobileMenuWrapper>
	);
};

export const Header: React.FC = React.memo(() => {
	const {t} = useTranslation();
	const [isScrolledDown, setIsScrolledDown] = useState(false);
	const isMobileMenuOpen = useSelector(isMenuOpen);
	const mobileMenuOpenClass = isMobileMenuOpen ? "open" : "";
	const scrolledClass = isScrolledDown ? "header-fill" : "";

	const handleScrollChanges = () => {
		setIsScrolledDown(window.scrollY > 0);
	};

	useEffect(() => {
		const debounceHandleScroll = debounce(handleScrollChanges, 60);
		window.addEventListener("scroll", debounceHandleScroll);
		return () => {
			window.removeEventListener("scroll", debounceHandleScroll);
		};
	}, []);

	return (
		<Wrapper className={`${mobileMenuOpenClass} ${scrolledClass}`}>
			<StyledContainer maxWidth={false}>
				<InnerWrapper>
					<DesktopLinks>
						<ExternalLink href="https://www.fifa.com/fifaplus/en/tickets">
							{t("header.fifa.tickets")}
						</ExternalLink>
						<ExternalLink href="https://www.plus.fifa.com/">
							{t("header.fifa.fifa_plus")}
						</ExternalLink>
						<ExternalLink href="https://store.fifa.com/en-gb?utm_source=partner&utm_medium=fifa-plus&utm_campaign=top+navigation">
							{t("header.fifa.store")}
						</ExternalLink>
						<ExternalLink href="https://www.fifa.com/en">
							{t("header.fifa.inside_fifa")}
						</ExternalLink>
					</DesktopLinks>
				</InnerWrapper>
				<MobileMenu />
			</StyledContainer>
			<DropdownMenu />
			<DesktopMenu />
			<DesktopSubNavigation />
			<MobileSubNavigation />
			<LanguageUnsupported />
		</Wrapper>
	);
});

Header.displayName = "Header";
