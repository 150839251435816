import React, {SyntheticEvent} from "react";
import emptyPlayer from "assets/images/emptyPlayerLong.png";
import styled from "styled-components";
import {IMAGES_URL} from "modules/constants";

const Wrapper = styled.div`
	overflow: hidden;
`;

const Img = styled.img`
	position: relative;
	vertical-align: middle;
`;

interface IProps {
	feedId: number;
	alt?: string;
	className?: string;
	size?: "large" | "small";
	fallback?: string;
}

export const PlayerImage: React.FC<IProps> = ({
	feedId,
	alt,
	className,
	size = "small",
	fallback = emptyPlayer,
}) => {
	const playerImage = `${IMAGES_URL}image/headshots/${feedId}_webimage.png`;

	const loadFallback = (e: SyntheticEvent<HTMLImageElement, Event>) => {
		const image = e.target as HTMLImageElement;
		if (image.src !== fallback) {
			image.src = fallback;
			image.setAttribute("data-default-jersey", "true");
		}
	};

	return (
		<Wrapper className="player-image-wrapper">
			<Img src={playerImage} alt={alt} className={className} onError={loadFallback} />
		</Wrapper>
	);
};

interface IEmptyPlayerProps {
	alt?: string;
	className?: string;
	size?: "large" | "small";
	fallback?: string;
}

export const EmptyPlayerImage: React.FC<IEmptyPlayerProps> = ({
	alt,
	className,
	size = "small",
	fallback = emptyPlayer,
}) => (
	<Wrapper className="player-image-wrapper">
		<Img src={fallback} alt={alt} className={className} />
	</Wrapper>
);
