import {IStore} from "modules/types/store";
import {createSelector} from "reselect";

export const getNotificationBarState = (state: IStore) => state.notification_bar;

export const getIsNotificationBarOpen = createSelector(
	getNotificationBarState,
	({isNotificationOpen}) => isNotificationOpen
);

export const getIsSecondaryNotificationBarOpen = createSelector(
	getNotificationBarState,
	({isSecondaryNotificationOpen}) => isSecondaryNotificationOpen
);

export const getIsPlayZoneBannerOpen = createSelector(
	getNotificationBarState,
	({isPlayZoneBannerOpen}) => isPlayZoneBannerOpen
);
