import {createSelector} from "reselect";
import {IStore} from "modules/types/store";
import {filter, find, findIndex, includes, last} from "lodash";
import {ProjectName, StatusType} from "modules/enums";
import {PROJECT_NAME, PROJECTS_WITHOUT_CHECK_ON_LEAGUES} from "modules/constants";
import {getLeaderboard} from "modules/selectors/leaderboard";

const getLeaguesState = (state: IStore) => state.leagues;

export const getLeagues = createSelector(getLeaguesState, (state) => state.leagues);

export const getLeaguesForJoin = createSelector(getLeaguesState, (state) => state.leaguesForJoin);

export const getLeagueCreated = createSelector(getLeaguesState, ({leagueCreated}) => leagueCreated);

export const getLeagueJoined = createSelector(getLeaguesState, ({leagueJoined}) => leagueJoined);

export const getLeagueRankings = createSelector(
	getLeaguesState,
	({leagueRankings}) => leagueRankings
);

export const getLeagueUsers = createSelector(getLeaguesState, ({leagueUsers}) => leagueUsers);

export const getLeagueForJoin = createSelector(getLeaguesState, ({leagueForJoin}) => leagueForJoin);

export const getLastCreatedLeague = createSelector(getLeagues, (leagues) => last(leagues));

export const getLeaguesRequestState = createSelector(
	getLeaguesState,
	({requestState}) => requestState
);

export const getLeagueById = createSelector(
	getLeagues,
	(leagues) => (id: number) => find(leagues, {id})
);

export const isInvitesModalOpen = createSelector(
	getLeaguesState,
	({isInvitesSentModalOpen}) => isInvitesSentModalOpen
);

export const getRemoveUserModal = createSelector(
	getLeaguesState,
	({removeUserModal}) => removeUserModal
);

export const getLeaveLeagueModal = createSelector(
	getLeaguesState,
	({leaveLeagueModal}) => leaveLeagueModal
);

export const getContestsForLeagues = createSelector(
	getLeaguesState,
	({contestsForLeagues}) => contestsForLeagues
);

export const getScheduledContests = createSelector(getContestsForLeagues, (contests) =>
	filter(contests, ({status}) => status === StatusType.Scheduled)
);

export const getContestsForCreateLeague = createSelector(getContestsForLeagues, (contests) => {
	if (includes([ProjectName.Fives, ProjectName.Goalscorer], PROJECT_NAME)) {
		return filter(contests, ({status}) => status !== StatusType.Complete);
	}

	return filter(contests, ({status}) => status === StatusType.Scheduled);
});

export const getIsRoundComplete = createSelector(
	getContestsForLeagues,
	(contests) => (roundId: number) =>
		contests.find((c) => c.id === roundId)?.status === StatusType.Complete
);

export const getCompleteActiveContests = createSelector(getContestsForLeagues, (contests) =>
	filter(contests, ({status}) => status !== StatusType.Scheduled)
);

export const getCompletedContests = createSelector(getContestsForLeagues, (contests) =>
	filter(contests, ({status}) => status === StatusType.Complete)
);

export const getLastCompletedContest = createSelector(getCompletedContests, (contests) =>
	last(contests)
);

export const hasScoringStarted = createSelector(
	getContestsForLeagues,
	getLeaderboard,
	(contests, leaderboard) => {
		if (PROJECTS_WITHOUT_CHECK_ON_LEAGUES.includes(PROJECT_NAME as ProjectName)) {
			return leaderboard.rankings.length > 0;
		}
		return findIndex(contests, ({status}) => status !== StatusType.Scheduled) > -1;
	}
);

export const getContestById = createSelector(
	getContestsForLeagues,
	(contests) => (id: number) => find(contests, {id})
);

export const getRankingFilters = createSelector(
	getLeaguesState,
	({leagueRankingFilters}) => leagueRankingFilters
);

export const getGotcLeague = createSelector(getLeaguesState, (state) => state.gotcLeague);
