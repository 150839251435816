import React from "react";
import {Navigate, Outlet, RouteProps, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {getIsUserLogged} from "modules/selectors";
import {get} from "lodash";
import {PROJECT_NAME, ProjectName} from "modules";

const redirectUrls: Record<string, string> = {
	[ProjectName.Fantasy]: "/team",
};

export const NotAuthOnlyRoute: React.FC<RouteProps> = () => {
	const location = useLocation();
	const rootPrivateURL = get(redirectUrls, PROJECT_NAME, "/");
	const backURL = get(location, "state.from.pathname", rootPrivateURL);

	return useSelector(getIsUserLogged) ? <Navigate to={backURL} replace /> : <Outlet />;
};
