import React, {Fragment, SyntheticEvent, useCallback, useEffect} from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {media} from "assets/css/media";
import {
	changeRouteLanguage,
	DROPDOWN_MENU_LINKS,
	IDropDownLink,
	LANGUAGES,
	TOP_HEADER_HEIGHT,
	TOP_HEADER_HEIGHT_LD,
} from "modules";
import {ReactComponent as Arrow} from "assets/images/icons/arrow.svg";
import {IconButton, useMediaQuery} from "@mui/material";
import {partial, uniqueId} from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {getIsDropdownMenuOpen} from "modules/selectors";
import {toggleDropdownMenu} from "modules/actions";
import fifaLogo from "assets/images/logos/fifa_logo.svg";
import {DropdownButton} from "./DropdownButton";
import {Exist} from "components/Exist";

const Title = styled.li`
	padding: 33px 20px 4px;
	color: #a5acbb;
	border-bottom: 1px solid #a5acbb;
	font-size: 10px;
	text-transform: uppercase;
	font-weight: 500;
`;

const Subtitle = styled.li`
	padding: 33px 20px 4px;
	color: #505b73;
	font-size: 10px;
	font-weight: 500;
	position: relative;

	&:after {
		content: "";
		height: 1px;
		width: 80%;
		background-color: #505b73;
		position: absolute;
		left: 20px;
		bottom: 0;
	}
`;

const List = styled.ul<{bgc?: string}>`
	position: relative;
	list-style: none;
	display: inline-block;
	height: 100%;
	overflow: auto;
	transition: visibility 0.3s linear, opacity 0.3s linear, width 0.3s linear;
	background: ${({bgc}) => bgc || "#045694"};
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}

	&.first {
		min-width: 320px;
	}

	&.deep {
		visibility: hidden;
		opacity: 0;
		width: 0;
	}

	&.show,
	&:hover {
		visibility: visible;
		opacity: 1;
		width: auto;
	}

	&.show.deep,
	&.deep:hover {
		min-width: 330px;
	}
`;

interface ILinkProps {
	colour?: string;
	hover?: string;
}

const Link = styled.a<ILinkProps>`
	display: flex;
	flex-grow: 1;
	text-transform: uppercase;
	font-size: 14px;
	line-height: 20px;
	padding: 14px 0 14px 0;
	padding-inline-start: 20px;
	color: ${({colour}) => colour || "#E4E8F0"};
	background: transparent;
	// for as button
	border: 0;
	margin: 0;
	background: none;

	&:hover {
		color: ${({hover}) => hover || "#FFF"};
	}
`;

const ItemWrapper = styled.li<{bgc?: string}>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: ${({bgc}) => bgc};

	&.single ${Link} {
		padding-inline-end: 20px;
	}
`;

const ArrowButton = styled(IconButton)<ILinkProps>`
	svg {
		transform: rotate(180deg);
		height: 16px;
		fill: ${({colour}) => colour || "#ffffff"};
	}
`;

const Menu = styled.div`
	display: flex;
	position: absolute;
	width: 100%;
	top: ${TOP_HEADER_HEIGHT}px;
	height: calc(100vh - ${TOP_HEADER_HEIGHT}px);
	z-index: 500;
	transition: background-color 0.2s;

	&.ltr {
		left: 0;
		transform: translateX(-100%);
	}

	&.rtl {
		right: 0;
		transform: translateX(100%);

		${ArrowButton} svg {
			transform: rotate(0deg);
		}
	}

	&.open {
		transform: unset;
		background: rgba(3, 18, 43, 0.3);
	}

	@media (max-width: ${media.tablet}) {
		top: ${TOP_HEADER_HEIGHT_LD}px;
		height: calc(100vh - ${TOP_HEADER_HEIGHT_LD}px);
	}
`;

const LogoWrapper = styled.div<{bgc?: string}>`
	height: 117px;
	background: ${({bgc}) => bgc || "#045694"};
	padding-top: 28px;
	text-align: center;
	position: relative;
`;

const DropdownButtonWrapper = styled.div`
	position: absolute;
	top: 32px;
	left: 22px;
`;

interface IItemLink extends IDropDownLink {
	menuId?: string;
}

const mouseEnterHandler = (menuId?: string) => {
	menuId?.split("-").forEach((id, i, array) => {
		document.getElementById(array.slice(0, i + 1).join("-"))?.classList.add("show");
	});
};

const mouseLeaveHandler = () => {
	const arr = document.querySelectorAll(".menu-nav-list.show");
	Array.from(arr).forEach((el) => el.classList.remove("show"));
};

const ItemLink: React.FC<IItemLink> = ({subLinks, bgc, colour, hover, href, title, menuId}) => {
	const {t} = useTranslation();
	const hasSubLinks = !!subLinks;
	const className = hasSubLinks ? "sub-links" : "single";

	return (
		<ItemWrapper
			bgc={bgc}
			className={className}
			onMouseEnter={partial(mouseEnterHandler, menuId)}
			onMouseLeave={mouseLeaveHandler}>
			{href ? (
				<Link href={href} colour={colour} hover={hover}>
					{t(title)}
				</Link>
			) : (
				<Link as="button" colour={colour} hover={hover}>
					{t(title)}
				</Link>
			)}

			{hasSubLinks && (
				<ArrowButton colour={colour} hover={hover}>
					<Arrow />
				</ArrowButton>
			)}
		</ItemWrapper>
	);
};

export const LanguageSelector: React.FC = () => {
	const {t} = useTranslation();

	return (
		<ItemWrapper
			onMouseEnter={partial(mouseEnterHandler, "menu-language-list")}
			onMouseLeave={mouseLeaveHandler}>
			<Link as="button">{t("subheader.fifa.language")}</Link>

			<ArrowButton>
				<Arrow />
			</ArrowButton>
		</ItemWrapper>
	);
};

const LanguageSelectorList: React.FC = () => {
	const {i18n} = useTranslation();

	const changeLanguage = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const language = e.currentTarget.dataset.language;

		if (!language) {
			return;
		}

		const oldLanguage = i18n.language;
		void i18n.changeLanguage(language);
		changeRouteLanguage(oldLanguage, language);
	};

	const clickHandler = useCallback((e: SyntheticEvent) => {
		e.stopPropagation();
	}, []);

	return (
		<List
			bgc="#E4E8F0"
			className="deep menu-nav-list"
			id="menu-language-list"
			onClick={clickHandler}>
			{LANGUAGES.map((language) => (
				<ItemWrapper key={language.code}>
					<Link
						as="button"
						data-language={language.code}
						onClick={changeLanguage}
						colour="#505B73"
						hover="#00b8ff">
						{language.name}
					</Link>
				</ItemWrapper>
			))}
		</List>
	);
};

const getId = (parentId?: string) => (parentId ? `${parentId}-${uniqueId()}` : uniqueId());

interface IListWrapper {
	arr: IDropDownLink[];
	deep?: number;
	parentId?: string;
	bgc?: string;
	colour?: string;
	hover?: string;
	logo?: string;
}

const ListWrapper: React.FC<IListWrapper> = ({
	arr,
	deep = 0,
	parentId = "",
	bgc,
	colour,
	hover,
	logo,
}) => {
	const {t} = useTranslation();
	const arrays: React.ReactNode[] = [];
	const className = deep ? "deep menu-nav-list" : "first menu-nav-list";

	const mouseEnterHandler = () => {
		parentId.split("-").forEach((id, i, array) => {
			document.getElementById(array.slice(0, i + 1).join("-"))?.classList.add("show");
		});
	};

	const mouseLeaveHandler = () => {
		if (parentId) {
			document.getElementById(parentId)?.classList.remove("show");
		}
	};

	const clickHandler = useCallback((e: SyntheticEvent) => {
		e.stopPropagation();
	}, []);

	return (
		<Fragment>
			<List
				onClick={clickHandler}
				className={className}
				id={parentId}
				bgc={bgc}
				onMouseEnter={mouseEnterHandler}
				onMouseLeave={mouseLeaveHandler}>
				<LogoWrapper bgc={bgc}>
					<Exist when={!deep}>
						<DropdownButtonWrapper>
							<DropdownButton />
						</DropdownButtonWrapper>
						<a href="https://www.fifa.com/fifaplus">
							<img src={fifaLogo} alt="FIFA" width="104" />
						</a>
					</Exist>

					<Exist when={Boolean(logo)}>
						<img src={logo} alt="FIFA" width="104" />
					</Exist>
				</LogoWrapper>

				{arr.map((link) => {
					const id = getId(parentId);

					if (link.subLinks) {
						arrays.push(
							<ListWrapper
								key={link.title}
								arr={link.subLinks.links}
								deep={deep + 1}
								parentId={id}
								bgc={link.subLinks.bgc}
								colour={link.subLinks.colour}
								hover={link.subLinks.hover}
								logo={link.subLinks.logo}
							/>
						);
					}

					if (link.subtitle) {
						return (
							<Fragment key={link.title}>
								<Subtitle>{t(link.subtitle)}</Subtitle>
								<ItemLink {...link} menuId={id} colour={colour} hover={hover} />
							</Fragment>
						);
					}

					return (
						<ItemLink
							{...link}
							key={link.title}
							menuId={id}
							colour={colour}
							hover={hover}
						/>
					);
				})}

				{!deep && (
					<Fragment>
						<Title>{t("header.title.more")}</Title>
						<ItemLink
							title={"header.dropdown.tickets"}
							href="https://www.fifa.com/fifaplus/en/tickets"
						/>
						<ItemLink
							title={"header.dropdown.fifa_plus"}
							href="https://www.plus.fifa.com/"
						/>
						<ItemLink
							title={"header.dropdown.fifa_store"}
							href="https://store.fifa.com/en-gb?utm_source=partner&utm_medium=fifa-plus&utm_campaign=top+navigation"
						/>
						<ItemLink
							title={"header.dropdown.inside_fifa"}
							href="https://www.fifa.com/en"
						/>

						<Title>{t("header.title.preferences")}</Title>
						<LanguageSelector />
					</Fragment>
				)}
			</List>

			{arrays}
		</Fragment>
	);
};

export const DropdownMenu: React.FC = React.memo(() => {
	const {i18n} = useTranslation();
	const dispatch = useDispatch();
	const isOpen = useSelector(getIsDropdownMenuOpen);
	const isShow = useMediaQuery(`(min-width: ${media.tablet})`);
	const openMenuClass = isOpen ? " open" : "";

	useEffect(() => {
		if (isOpen && isShow) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "visible";
		}
	}, [isOpen, isShow]);

	useEffect(() => {
		if (!isShow) {
			dispatch(toggleDropdownMenu(false));
		}
	}, [isShow, dispatch]);

	const closeHandler = useCallback(() => {
		dispatch(toggleDropdownMenu(false));
	}, [dispatch]);

	return (
		<Menu className={i18n.dir() + openMenuClass} id="menu-nav-container" onClick={closeHandler}>
			<ListWrapper arr={DROPDOWN_MENU_LINKS} />
			<LanguageSelectorList />
		</Menu>
	);
});

DropdownMenu.displayName = "DropdownMenu";
