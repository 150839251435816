import {createAction} from "redux-act";
import {PromoName} from "modules/enums";
import {IPrompt} from "modules/reducers";

export const openPromoBanner = createAction();
export const closePromoBanner = createAction();

export interface IPromptPayload {
	game: PromoName;
}

export interface IPromptResponse {
	prompt: IPrompt;
}

export const fetchPrompt = createAction<IPromptPayload>();
export const fetchPromptSuccess = createAction<IPromptResponse>();
export const fetchPromptFailed = createAction<Error["message"]>();
