import {HTTPClient as HTTPClientCore, IRequestConfig} from "./HTTPClient";
import axios, {AxiosError} from "axios";
import {isObject, set} from "lodash";
import ApiError from "./ApiError";
import {
	API_URL,
	JSON_URL,
	PROJECT_API_PATH,
	PROJECT_GLOBAL_DATA_PATH,
	PROJECT_JSON_PATH,
} from "modules/constants";
import {
	IApiResponse,
	IDictionary,
	ILeague,
	ISaveShare,
	ISaveShareResponse,
	IUser,
} from "modules/types";
import {CANCEL} from "redux-saga";
import i18n from "i18next";
import {
	GenericApiErrorResponse,
	IFaq,
	IGamezoneLevel,
	IGeoLocation,
	IHelpPages,
	ILeagueRankingsResponse,
	ILeaguesResponse,
	ILeagueUsersResponse,
	IPlayer,
	IPLayerSingleStats,
	IRankingsResponse,
	IRound,
	ISettings,
	ISquad,
} from "modules/reducers";
import {
	IBackdoorPayload,
	ICreateLeaguePayload,
	IFetchLeaderboardPayload,
	IFetchLeagueForJoinPayload,
	IFetchLeaguesForJoinPayload,
	IFetchLeaguesPayload,
	IInvitePayload,
	IJoinLeaguePayload,
	ILeagueRankingsPayload,
	ILeagueUsersPayload,
	ILeaveLeaguePayload,
	ILoginAppPayload,
	ILoginPayload,
	IPayloadAchievementsCount,
	IPayloadUserLevel,
	IPromptPayload,
	IPromptResponse,
	IRegisterAppPayload,
	IRegisterPayload,
	IRemoveUserPayload,
	IUpdateLeaguePayload,
	IUpdatePayload,
} from "modules/actions";
import {IGamezoneBanner} from "modules/reducers/gamezone_baner";

class HTTPClient extends HTTPClientCore {
	/**
	 * Overridden method adds CancelToken symbol, that allow redux-saga
	 * "takeLatest" function to cancel any requests automatically.
	 * http://fe-common-utils.s3-website-eu-west-1.amazonaws.com/classes/httpclient.html
	 */
	public makeRequest<T>(config: IRequestConfig): Promise<T> {
		const source = axios.CancelToken.source();

		const request = super.makeRequest<T>({
			...config,
			cancelToken: source.token,
		});

		return set<Promise<T>>(request, CANCEL, () => source.cancel());
	}
}

const onCatchNetworkError = ({response, message = "Network error"}: AxiosError<IApiResponse>) => {
	const data = response?.data;
	const error = isObject(data)
		? {
				...data,
				errors: data.errors.map((e) => new ApiError(e.message, response?.status)),
		  }
		: {
				errors: [new ApiError(message, response?.status)],
		  };

	return Promise.reject(error).catch((err) => ApiError.CHECK(err as IApiResponse));
};

export const getLang = () => {
	const currentLang = i18n.language;
	return currentLang || "en";
};

export const APIClient = new HTTPClient({
	baseURL: API_URL,
	withCredentials: true,
	onCatchNetworkError,
});

export const JSONClient = new HTTPClient({
	baseURL: JSON_URL + PROJECT_GLOBAL_DATA_PATH,
	onCatchNetworkError,
});

export const FIFAJSONClient = new HTTPClient({
	baseURL: "https://api.fifa.com/",
});

export const ProjectJSONClient = new HTTPClient({
	baseURL: JSON_URL + PROJECT_JSON_PATH,
});

type TUserResponse = IApiResponse<{user: IUser}>;

export const Api = {
	JSON: {
		checksums: () => JSONClient.get<IDictionary<string>>("checksums.json"),
		helpPages: () =>
			ProjectJSONClient.get<GenericApiErrorResponse<IHelpPages>>("help_pages.json"),
		faq: () => ProjectJSONClient.get<GenericApiErrorResponse<IFaq[]>>("faq.json"),
		rounds: () => JSONClient.get<IRound[]>("rounds.json"),
		squads: () => JSONClient.get<ISquad[]>("squads_fifa.json"),
		players: () => JSONClient.get<IPlayer[]>("players.json"),
		statPerPlayer: (id: number) =>
			JSONClient.get<IPLayerSingleStats[]>(`player_stats/${id}.json`),
		countries: () => JSONClient.get<Record<string, string>>("countries.json"),
		translatedCountries: () =>
			ProjectJSONClient.get<Record<string, string>>(`countries/${getLang()}.json`),
		settings: () => JSONClient.get<ISettings>("settings.json"),
		esigeo: () => FIFAJSONClient.get<IGeoLocation[]>("api/geo/esigeo.json"),
	},
	Auth: {
		login: (params: ILoginPayload) =>
			APIClient.post<TUserResponse>(
				`${getLang()}/${PROJECT_GLOBAL_DATA_PATH}auth/sso/login`,
				params
			),
		register: (params: IRegisterPayload) =>
			APIClient.post<TUserResponse>(
				`${getLang()}/${PROJECT_GLOBAL_DATA_PATH}auth/sso/register`,
				params
			),
		allFieldsRegister: (params: ILoginPayload) =>
			APIClient.post<TUserResponse>(
				`${getLang()}/${PROJECT_GLOBAL_DATA_PATH}auth/sso/all-fields/register`,
				params
			),
		logout: () => APIClient.post(`${getLang()}/${PROJECT_GLOBAL_DATA_PATH}auth/logout`),
		loginApp: (params: ILoginAppPayload) =>
			APIClient.post<TUserResponse>(
				`${getLang()}/${PROJECT_GLOBAL_DATA_PATH}auth/sso/app/login`,
				params
			),
		registerApp: (params: IRegisterAppPayload) =>
			APIClient.post<TUserResponse>(
				`${getLang()}/${PROJECT_GLOBAL_DATA_PATH}auth/sso/app/register`,
				params
			),
		allFieldsRegisterApp: (params: ILoginAppPayload) =>
			APIClient.post<TUserResponse>(
				`${getLang()}/${PROJECT_GLOBAL_DATA_PATH}auth/sso/all-fields/app/register`,
				params
			),
		backdoor: (params: IBackdoorPayload) =>
			APIClient.post<TUserResponse>(
				`${getLang()}/${PROJECT_GLOBAL_DATA_PATH}auth/backdoor`,
				params
			),
	},
	User: {
		user: () => APIClient.get<TUserResponse>(`${getLang()}/${PROJECT_GLOBAL_DATA_PATH}user`),
		update: (params: IUpdatePayload) =>
			APIClient.post<TUserResponse>(
				`${getLang()}/${PROJECT_GLOBAL_DATA_PATH}user/update`,
				params
			),
	},
	Predictions: {},
	Rankings: {
		leaderboard: (params: IFetchLeaderboardPayload) =>
			APIClient.get<IApiResponse<IRankingsResponse>>(
				`${getLang()}/${PROJECT_API_PATH}ranking`,
				params
			),
	},
	Contact: {},
	Leagues: {
		leagues: (params: IFetchLeaguesPayload) =>
			APIClient.get<IApiResponse<ILeaguesResponse>>(
				`${getLang()}/${PROJECT_API_PATH}leagues`,
				params
			),
		league: (params: ICreateLeaguePayload) =>
			APIClient.post<IApiResponse<ILeague>>(`${getLang()}/${PROJECT_API_PATH}league`, params),
		update: ({leagueId, ...params}: IUpdateLeaguePayload) =>
			APIClient.post<IApiResponse<ILeague>>(
				`${getLang()}/${PROJECT_API_PATH}league/${leagueId}`,
				params
			),
		invite: ({leagueId, invites}: IInvitePayload) =>
			APIClient.post<IApiResponse<ILeague>>(
				`${getLang()}/${PROJECT_API_PATH}league/${leagueId}/invite`,
				{invites}
			),
		showForJoin: (params: IFetchLeaguesForJoinPayload) =>
			APIClient.get<IApiResponse<ILeaguesResponse>>(
				`${getLang()}/${PROJECT_API_PATH}league/show-for-join`,
				params
			),
		join: ({code}: IJoinLeaguePayload) =>
			APIClient.post<IApiResponse<ILeague>>(
				`${getLang()}/${PROJECT_API_PATH}league/${code}/join`
			),
		leave: ({leagueId}: ILeaveLeaguePayload) =>
			APIClient.get<IApiResponse<ILeague>>(
				`${getLang()}/${PROJECT_API_PATH}league/${leagueId}/leave`
			),
		ranking: ({leagueId, ...params}: ILeagueRankingsPayload) =>
			APIClient.get<IApiResponse<ILeagueRankingsResponse>>(
				`${getLang()}/${PROJECT_API_PATH}ranking/league/${leagueId}`,
				params
			),
		users: ({leagueId, ...params}: ILeagueUsersPayload) =>
			APIClient.get<IApiResponse<ILeagueUsersResponse>>(
				`${getLang()}/${PROJECT_API_PATH}league/${leagueId}/league-users`,
				params
			),
		remove: ({leagueId, userId}: IRemoveUserPayload) =>
			APIClient.post<IApiResponse<ILeague>>(
				`${getLang()}/${PROJECT_API_PATH}league/${leagueId}/user/${userId}`
			),
		showByCode: (params: IFetchLeagueForJoinPayload) =>
			APIClient.get<IApiResponse<ILeague>>(
				`${getLang()}/${PROJECT_API_PATH}league/show-by-code`,
				params
			),
		gotcLeague: () =>
			APIClient.get<IApiResponse<ILeaguesResponse>>(
				`${getLang()}/${PROJECT_API_PATH}leagues/gotc`
			),
	},
	Common: {
		time: () =>
			APIClient.get<IApiResponse<{time: string}>>(
				`${getLang()}/${PROJECT_GLOBAL_DATA_PATH}time/current`
			),
	},
	Share: {
		save: (params: ISaveShare) =>
			APIClient.post<IApiResponse<ISaveShareResponse>>(
				`${getLang()}/${PROJECT_GLOBAL_DATA_PATH}share/save`,
				params
			),
	},
	GamezoneBaner: {
		get: (params: IPayloadAchievementsCount) =>
			APIClient.get<IApiResponse<IGamezoneBanner>>(
				`${getLang()}/gamezone/achievements/my-achievements-count`,
				params
			),
		getUserLevel: (params: IPayloadUserLevel) =>
			APIClient.get<IApiResponse<IGamezoneLevel>>(
				`${getLang()}/gamezone/ranking/user/${params.userId}/level`
			),
	},
	Prompt: {
		get: (params: IPromptPayload) =>
			APIClient.get<IApiResponse<IPromptResponse>>(`${getLang()}/prompt`, params),
	},
};

export * from "./ApiError";

export default Api;
