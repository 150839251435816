import {IStore} from "modules/types/store";
import {createSelector} from "reselect";
import {compact, filter, get, includes, keyBy, memoize, reduce} from "lodash";
import {
	getSelectedRound,
	getSelectedRoundFixtures,
	getSquadById,
	getSquadTournamentById,
	getTournaments,
} from "modules/selectors";
import {PlayerStatus, TournamentStatus} from "modules";
import {ITournament} from "modules/reducers";

export const getPlayerState = (state: IStore) => state.players;

export const getPlayers = createSelector(getPlayerState, (state) => state.players);

export const getPlayersRequestState = createSelector(getPlayerState, (state) => state.requestState);

export const getNoEliminatedPlayers = createSelector(getPlayers, (players) =>
	players.filter((player) => player.status !== PlayerStatus.Eliminated)
);

export const getPlayersStats = createSelector(getPlayerState, (state) => state.playersStats);

export const getPlayersById = createSelector(getPlayers, (players) => keyBy(players, "id"));

export const getPlayerById = createSelector(
	getPlayersById,
	(playersById) =>
		(playerId: number | null = 0) =>
			playersById[playerId || 0]
);

const getSquadNextFixture = (tournaments: ITournament[], squadId: number = 0) => {
	const tournament = tournaments
		.filter(({homeSquadId, awaySquadId}) => [homeSquadId, awaySquadId].includes(squadId))
		.find((tournament) =>
			[TournamentStatus.Active, TournamentStatus.Scheduled].includes(tournament.status)
		);

	if (!tournament) {
		return;
	}

	if (tournament.awaySquadId === squadId) {
		return tournament.homeSquadId;
	}

	return tournament.awaySquadId;
};

export const getPlayerNextFixture = createSelector(getTournaments, (tournaments) =>
	memoize((squadId: number = 0) => getSquadNextFixture(tournaments, squadId))
);

export const getSelectedRoundPlayerNextFixture = createSelector(
	getSelectedRoundFixtures,
	(tournaments) => memoize((squadId: number = 0) => getSquadNextFixture(tournaments, squadId))
);

export const getPlayerFixtures = createSelector(getTournaments, (tournaments) =>
	memoize((squadId: number = 0) =>
		tournaments.filter(({homeSquadId, awaySquadId}) =>
			[homeSquadId, awaySquadId].includes(squadId)
		)
	)
);

export const getPlayerFixturesSquads = createSelector(
	getTournaments,
	getSquadById,
	(tournaments, getSquadByIdSelector) =>
		(squadId: number = 0) => {
			return compact(
				tournaments
					.filter(({homeSquadId, awaySquadId}) =>
						[homeSquadId, awaySquadId].includes(squadId)
					)
					.map((tournament) => {
						if (tournament.awaySquadId === squadId) {
							return tournament.homeSquadId;
						}

						return tournament.awaySquadId;
					})
					.map((id) => getSquadByIdSelector(id))
			);
		}
);

export const getSinglePlayerStats = createSelector(
	getPlayerState,
	(state) => state.singlePlayerStat
);

export const getSinglePlayerRoundStats = createSelector(
	getSinglePlayerStats,
	(stats) => (id: number) => stats?.find(({tournamentId}) => tournamentId === id)
);

export const getPlayerRoundPoints = createSelector(
	getPlayersById,
	(playersById) =>
		(playerId: number = 0, round: number = 0) => {
			const player = playersById[playerId];

			if (!player) {
				return;
			}

			return get(player.stats.roundScores, round);
		}
);

export const getPlayerTotalPoints = createSelector(
	getPlayersById,
	(playersById) =>
		(playerId: number = 0) => {
			const player = playersById[playerId];

			if (!player) {
				return 0;
			}

			return reduce(player.stats.roundScores, (points, acc) => points + acc, 0);
		}
);

export const getPlayersBySquadId = createSelector(
	[getNoEliminatedPlayers],
	(players) => (squadIds: number[]) => {
		return filter(players, (player) => includes(squadIds, player.squadId));
	}
);

export const getPlayersForMatch = createSelector(
	[getNoEliminatedPlayers],
	(players) => (homeSquadId: number, awaySquadId: number) => {
		return filter(players, (player) => includes([homeSquadId, awaySquadId], player.squadId));
	}
);

export const getPlayerTournamentIsComplete = createSelector(
	getSquadTournamentById,
	(getSquadTournamentByIdSelector) => (squadId: number) => {
		const tournament = getSquadTournamentByIdSelector(squadId);
		return tournament?.status === TournamentStatus.Complete;
	}
);

export const getPlayerTournamentStatus = createSelector(
	getSelectedRound,
	(round) => (squadId: number) => {
		const tournaments = get(round, "tournaments", []).filter((tournament) =>
			[tournament.homeSquadId, tournament.awaySquadId].includes(squadId)
		);

		const isActive = tournaments.some(({status}) => status === TournamentStatus.Active);
		const isDNP = !tournaments.length;

		return {
			isActive,
			isDNP,
		};
	}
);
