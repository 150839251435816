import {createAction} from "redux-act";
import {IGamezoneBanner, IGamezoneLevel} from "modules/reducers/gamezone_baner";

export interface IPayloadAchievementsCount {
	game: string;
}

export interface IPayloadUserLevel {
	userId: number;
}

export const fetchAchievementsCount = createAction<IPayloadAchievementsCount>();
export const fetchAchievementsCountSuccess = createAction<IGamezoneBanner>();
export const fetchAchievementsCountFailed = createAction();

export const fetchUserLevel = createAction<IPayloadUserLevel>();
export const fetchUserLevelSuccess = createAction<IGamezoneLevel>();
export const fetchUserLevelFailed = createAction();
