import React, {SyntheticEvent} from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getIsNotificationBarOpen, getIsSecondaryNotificationBarOpen} from "modules/selectors";
import {closeNotificationBar, closeSecondaryNotificationBar} from "modules/actions";
import {media} from "assets/css/media";
import {PureButton} from "components/Button";
import {ReactComponent as Close} from "assets/images/icons/close.svg";
import {Link} from "react-router-dom";

const Wrapper = styled.div`
	background-color: #e9e9e9;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 8px;
	padding: 16px 60px;

	@media (max-width: ${media.mobile}) {
		padding: 16px;
	}
`;

const Label = styled.p`
	font-weight: 500;
	font-size: 12px;
`;

export const WhoAmIBanner: React.FC = () => {
	const {t} = useTranslation("whoami");
	const dispatch = useDispatch();
	const isOpen = useSelector(getIsNotificationBarOpen);

	const closeNotification = () => {
		dispatch(closeNotificationBar());
	};

	if (!isOpen) {
		return null;
	}

	return (
		<Wrapper>
			<Label>{t("leaderboard.notification.text")}</Label>
			<PureButton onClick={closeNotification}>
				<Close />
			</PureButton>
		</Wrapper>
	);
};

export const WhoAmIGameBanner: React.FC = () => {
	const {t} = useTranslation("whoami");
	const dispatch = useDispatch();
	const isOpen = useSelector(getIsSecondaryNotificationBarOpen);

	const closeNotification = (e: SyntheticEvent) => {
		e.preventDefault();
		e.stopPropagation();
		dispatch(closeSecondaryNotificationBar());
	};

	if (!isOpen) {
		return null;
	}

	return (
		<Wrapper as={Link} to="/help/guidelines">
			<Label>{t("predictor_home.banner.points")}</Label>
			<PureButton onClick={closeNotification}>
				<Close />
			</PureButton>
		</Wrapper>
	);
};
