import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {Exist} from "components/Exist";
import {PureButton} from "components/Button";
import {useTranslation} from "react-i18next";
import {changeRouteLanguage, LANGUAGES} from "modules";
import {ReactComponent as LanguageIcon} from "assets/images/icons/language.svg";

const Wrapper = styled.div`
	position: relative;
`;

const LanguageToggle = styled(PureButton)`
	svg {
		vertical-align: middle;
	}

	&:hover svg {
		fill: #00b8ff;
	}
`;

const LanguageMenu = styled.ul`
	list-style: none;
	position: absolute;
	top: 150%;
	left: -200%;
	border-radius: 5px;
	z-index: 4;
	background-color: ${({theme}) => theme.header.header_background};
	-moz-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
	-webkit-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
`;

const LanguageLink = styled.li`
	color: #fff;
	font-size: 12px;
	line-height: 1.6;
	padding: 8px;
	padding-inline-end: 24px;
	border-bottom: 0.2px solid rgba(255, 255, 255, 0.2);
	cursor: pointer;
	transition: color 0.2s ease-in-out;

	&:hover {
		color: ${({theme}) => theme.header.header_hover_color};
	}

	&:last-child {
		border-bottom: none;
	}
`;

export const LanguageSelector: React.FC = () => {
	const {i18n} = useTranslation();
	const menuRef = useRef<HTMLUListElement>(null);
	const [showMenu, setShowMenu] = useState(false);

	const toggleMenu = () => {
		setShowMenu(!showMenu);
	};

	const closeMenu = () => {
		setShowMenu(false);
	};

	useEffect(() => {
		if (showMenu && menuRef?.current) {
			menuRef.current.focus();
		}
	}, [menuRef, showMenu]);

	const changeLanguage = (e: React.SyntheticEvent<HTMLLIElement>) => {
		const language = e.currentTarget.dataset.language;

		if (!language) {
			menuRef.current?.blur();
			return;
		}

		const oldLanguage = i18n.language;
		void i18n.changeLanguage(language);
		changeRouteLanguage(oldLanguage, language);
		menuRef.current?.blur();
	};

	return (
		<Wrapper>
			<LanguageToggle onClick={toggleMenu} className={showMenu ? "open" : ""}>
				<LanguageIcon />
			</LanguageToggle>
			<Exist when={showMenu}>
				<LanguageMenu tabIndex={0} ref={menuRef} onBlur={closeMenu}>
					{LANGUAGES.map((language) => (
						<LanguageLink
							key={language.code}
							data-language={language.code}
							onClick={changeLanguage}>
							{language.name}
						</LanguageLink>
					))}
				</LanguageMenu>
			</Exist>
		</Wrapper>
	);
};
