export enum FantasyClassicTournamentStage {
	GroupStage,
	RD16,
	QUARTERFINALS,
	SEMIFINALS,
	FINAL,
}

export const FantasyClassicRoundType: Record<number, FantasyClassicTournamentStage> = {
	1: FantasyClassicTournamentStage.GroupStage,
	2: FantasyClassicTournamentStage.GroupStage,
	3: FantasyClassicTournamentStage.GroupStage,
	4: FantasyClassicTournamentStage.RD16,
	5: FantasyClassicTournamentStage.QUARTERFINALS,
	6: FantasyClassicTournamentStage.SEMIFINALS,
	7: FantasyClassicTournamentStage.FINAL,
};
