import {
	ContextType,
	RefObject,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import {
	Navigator as BaseNavigator,
	UNSAFE_NavigationContext as NavigationContext,
	useLocation,
	useNavigate,
} from "react-router-dom";
import {debounce} from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {PROJECT_NAME, TUTORIAL} from "modules/constants";
import {ProjectName} from "modules/enums";
import {fetchContestsJSON} from "apps/FIVES/modules/actions";
import type {Blocker, History, Transition} from "history";
import {prepareLanguageForRouter} from "modules/utils/Language";
import {DateTime} from "luxon";
import {fetchRounds} from "modules/actions";
import Fuse from "fuse.js";

import {fetchContest} from "apps/FANTASY_DAILY/modules/actions";
import {fetchContests} from "apps/GOALSCORER/modules/actions";
import {ISubNavigationItem} from "modules/types";
import {getIsUserLogged} from "modules/selectors";
import {fetchRounds as fetchRoundsBracketsPredictor} from "apps/BRACKET_PREDICTOR/modules/actions";

export const usePrevious = <T = undefined>(value: T): T | undefined => {
	const ref = useRef<T>();

	useEffect(() => {
		ref.current = value;
	});

	return ref.current;
};

export const useURLQuery = () => new URLSearchParams(useLocation().search);

export const useScrollTo = (ref: RefObject<HTMLElement> | undefined | null) => () => {
	const current = ref?.current;
	if (current) {
		window.scrollTo({
			top: current.getBoundingClientRect().top + window.scrollY - 20,
			behavior: "smooth",
		});
	}
};

export const useFetchContests = () => {
	const dispatch = useDispatch();
	const action = {
		[ProjectName.Fives]: fetchContestsJSON,
		[ProjectName.Predictor]: fetchRounds,
		[ProjectName.Fantasy]: fetchRounds,
		[ProjectName.BracketsPredictor]: fetchRoundsBracketsPredictor,
		[ProjectName.FantasyDaily]: fetchContest,
		[ProjectName.WhoAmI]: fetchContest,
		[ProjectName.Gott]: fetchContest,
		[ProjectName.Goalscorer]: fetchContests,
	}[PROJECT_NAME];

	useEffect(() => {
		if (action) {
			dispatch(action());
		}
	}, [dispatch, action]);
};

interface INavigator extends BaseNavigator {
	block: History["block"];
}

type NavigationContextWithBlock = ContextType<typeof NavigationContext> & {navigator: INavigator};

/**
 * @source https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874
 */
const useBlocker = (blocker: Blocker, when = true) => {
	const {navigator} = useContext(NavigationContext) as NavigationContextWithBlock;

	useEffect(() => {
		if (!when) {
			return;
		}

		const unblock = navigator.block((tx: Transition) => {
			const autoUnblockingTx = {
				...tx,
				retry() {
					// Automatically unblock the transition so it can play all the way
					// through before retrying it. TODO: Figure out how to re-enable
					// this block if the transition is cancelled for some reason.
					unblock();
					tx.retry();
				},
			};

			blocker(autoUnblockingTx);
		});

		return unblock;
	}, [navigator, blocker, when]);
};

export const useBlockNavigation = (when: boolean) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [showPrompt, setShowPrompt] = useState(false);
	const [lastLocation, setLastLocation] = useState<Transition | null>(null);
	const [confirmedNavigation, setConfirmedNavigation] = useState(false);
	const baseName = prepareLanguageForRouter();

	const cancelNavigation = useCallback(() => {
		setShowPrompt(false);
	}, []);

	const handleBlockedNavigation: Blocker = useCallback(
		(nextLocation) => {
			const isRouteChanged = nextLocation.location.pathname !== location.pathname;

			if (!confirmedNavigation && isRouteChanged) {
				setShowPrompt(true);
				setLastLocation(nextLocation);

				return false;
			}

			return true;
		},
		[confirmedNavigation, location.pathname]
	);

	const confirmNavigation = useCallback(() => {
		setShowPrompt(false);
		setConfirmedNavigation(true);
	}, []);

	useEffect(() => {
		if (confirmedNavigation && lastLocation) {
			const newUrl = lastLocation.location.pathname.split(baseName)[1];
			navigate(newUrl || lastLocation.location.pathname);
		}
	}, [confirmedNavigation, lastLocation, navigate, baseName]);

	useBlocker(handleBlockedNavigation, when);

	return [showPrompt, confirmNavigation, cancelNavigation] as const;
};

export const useTime = (refreshCycle = 100) => {
	const [now, setNow] = useState(getTime());

	useEffect(() => {
		const intervalId = setInterval(() => setNow(getTime()), refreshCycle);

		return () => clearInterval(intervalId);
	}, [refreshCycle, setNow]);

	return now;
};

export const getTime = () => {
	return DateTime.local();
};

export const useTimer = (end: string, onComplete?: () => void) => {
	const now = useTime(500);
	const diff = DateTime.fromISO(end).diff(now);
	const isComplete = diff.milliseconds <= 0;

	useEffect(() => {
		if (isComplete && onComplete) {
			onComplete();
		}
	}, [onComplete, isComplete]);

	if (isComplete) {
		return now.diff(now);
	}

	return diff;
};

export const useIsShowTutorial = () => {
	const location = useLocation();
	const isShow = TUTORIAL[PROJECT_NAME];

	if (Array.isArray(isShow)) {
		return isShow.includes(location.pathname);
	}

	return isShow;
};

export const useQuery = () => {
	return new URLSearchParams(useLocation().search);
};

export const useFuse = <T>(list: T[], query: string, options?: Fuse.IFuseOptions<T>) => {
	const [result, setResult] = useState<T[]>(list);

	const fuse = useMemo(() => new Fuse(list, options), [list, options]);

	const setResultDebounce = useMemo(() => debounce(setResult, 100), [setResult]);

	useEffect(() => {
		const value = fuse.search(query).map(({item}) => item);

		if (!query) {
			setResult(list);
		} else {
			setResultDebounce(value);
		}
	}, [list, query, fuse, setResultDebounce, setResult]);

	return result;
};

export const useShowNavigationLink = () => {
	const isAuth = useSelector(getIsUserLogged);
	const location = useLocation();

	return (link: ISubNavigationItem) => {
		if (link.hidden) {
			return false;
		}

		if (link.authorizationRequired && !isAuth) {
			return false;
		}

		const hiddenPaths = link.hiddenPaths?.filter((path) => location.pathname.includes(path));

		return !(hiddenPaths && hiddenPaths.length);
	};
};

interface ISize {
	width: number | undefined;
	height: number | undefined;
}

export const useWindowSize = (): ISize => {
	// Initialize state with undefined width/height so server and client renders match
	// Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
	const [windowSize, setWindowSize] = useState<ISize>({
		width: undefined,
		height: undefined,
	});
	useEffect(() => {
		// Handler to call on window resize
		function handleResize() {
			// Set window width/height to state
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}
		// Add event listener
		window.addEventListener("resize", handleResize);
		// Call handler right away so state gets updated with initial window size
		handleResize();
		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleResize);
	}, []); // Empty array ensures that effect is only run on mount
	return windowSize;
};
