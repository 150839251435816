import {createAction} from "redux-act";
import {IPlayerPosition, PlayerStatus} from "modules";
import {FilterStats} from "apps/FANTASY_DAILY/modules/enums";
import {IColumnSortDirection} from "apps/FANTASY_DAILY/modules/types";

export const openPlayerPool = createAction();
export const closePlayerPool = createAction();

export const openFilters = createAction();
export const closeFilters = createAction();

export interface IFiltersPayload {
	search?: string;
	stat?: FilterStats;
	country?: number;
	position?: IPlayerPosition[];
	status?: PlayerStatus;
}

export const setFilters = createAction<IFiltersPayload>();

export const setColumnSort = createAction<IColumnSortDirection>();
