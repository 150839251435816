import {IStore} from "modules/types/store";
import {createSelector} from "reselect";

export const getPromptState = (state: IStore) => state.prompt;

export const getIsPromoBannerOpen = createSelector(
	getPromptState,
	({isPromoBannerOpen}) => isPromoBannerOpen
);

export const getPrompt = createSelector(getPromptState, ({prompt}) => prompt);
