import React from "react";
import {IS_APP} from "modules";
import {SessionApp} from "./SessionApp";
import {SessionWeb} from "./SessionWeb";

interface IProps {
	children: React.ReactNode;
}

export const HOCSession: React.FC<IProps> = ({children}) =>
	IS_APP ? <SessionApp>{children}</SessionApp> : <SessionWeb>{children}</SessionWeb>;
