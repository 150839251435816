import React, {useEffect} from "react";
import styled from "styled-components";
import {Box} from "@mui/material";
import {BurgerButton, Exist, HOCAccordion} from "components";
import {PureButton} from "components/Button";
import {SubLink} from "components/Header/SubLink";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getIsUserLogged, isMenuOpen} from "modules/selectors";
import {toggleMenu} from "modules/actions";
import {
	DEVICE,
	IS_APP,
	MOBILE_MENU_NAVIGATION,
	useIsShowTutorial,
	ViewPortVisibility,
} from "modules";
import {ISubNavigationItem} from "modules/types";
import openIn from "assets/images/icons/open_in.svg";
import {ChevronLeft} from "@mui/icons-material";
import {MobileLanguageSelector} from "components/Header/MobileLanguageSelector";
import {TutorialLink} from "./TutorialLink";
import {useLocation} from "react-router-dom";

const Menu = styled.ul`
	list-style: none;
	padding: 0;
	--defaultPaddingBottom: 12px;
	padding-bottom: var(--defaultPaddingBottom);
	/* iOS Safari 11.2, Safari 11 */
	padding-bottom: calc(
		var(--defaultPaddingBottom) + constant(safe-area-inset-bottom, var(--defaultPaddingBottom))
	);
	/* iOS Safari 11.4+, Safari 11.1+, Chrome 69+, Opera 56+ */
	padding-bottom: calc(
		var(--defaultPaddingBottom) + env(safe-area-inset-bottom, var(--defaultPaddingBottom))
	);
	padding-inline-start: 44px;
	position: fixed;
	top: 48px;
	height: calc(100vh - 48px);
	width: 100%;
	z-index: 1;
	left: 0;
	background-color: #045694;
	transform: translateX(-100%);
	transition: 0.25s;
	overflow: auto;

	&.open {
		transform: unset;
		z-index: 599;
	}

	li {
		margin-bottom: 28px;
	}
`;

const LinkWrapper = styled.div`
	&.spaced {
		padding-top: 90px;
	}
	&.top-link {
		margin-top: 20px;
	}

	a,
	.back-button,
	.more-button {
		flex-grow: 1;
		padding: 14px 0;
		display: block;
		color: #ffffff;
		font-style: normal;
		font-size: 14px;
		text-align: start;
		line-height: 160%;
		span {
			color: #ffffff;
		}

		&:hover,
		&:active,
		&:focus {
			color: ${({theme}) => theme.header.header_hover_color};
			span {
				color: ${({theme}) => theme.header.header_hover_color};
			}
		}
	}
`;

const ExternalLink = styled.a`
	display: flex !important;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding-inline-end: 22px !important;

	&.bold {
		font-weight: 600;
	}

	span {
		margin-inline-end: auto;
	}

	&.ltr {
		span,
		svg {
			position: relative;
			left: -12px;
		}
	}

	&.rtl {
		span,
		svg {
			position: relative;
			right: -12px;
		}

		svg {
			transform: rotate(180deg);
		}
	}

	.coca-cola-logo {
		height: 20px;
		width: auto;
	}
`;

export const HamburgerMenu: React.FC = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const {i18n, t} = useTranslation();
	const isOpen = useSelector(isMenuOpen);
	const openClass = isOpen ? "open" : "";
	const isAuth = useSelector(getIsUserLogged);
	const isShowTutorial = useIsShowTutorial();

	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "visible";
		}
	}, [isOpen]);

	const toggleMobileMenu = () => {
		dispatch(toggleMenu(!isOpen));
	};

	useEffect(() => {
		dispatch(toggleMenu(false));
	}, [dispatch, location]);

	const returnLinks = (item: ISubNavigationItem) => (
		<LinkWrapper>
			<SubAccordion item={item} />
		</LinkWrapper>
	);

	const closeGameZone = () => {
		if (DEVICE === "Android") {
			window.android?.postMessage(
				JSON.stringify({
					action: "closeGameZone",
				})
			);
		}

		if (DEVICE === "iOS") {
			window.webkit?.messageHandlers.ios.postMessage({
				action: "closeGameZone",
			});
		}
	};

	return (
		<Box
			sx={{
				position: "relative",
			}}>
			<BurgerButton className={openClass} onClick={toggleMobileMenu} />

			<Menu className={openClass}>
				<LinkWrapper className="top-link">
					{IS_APP ? (
						<ExternalLink
							as={PureButton}
							onClick={closeGameZone}
							className={`${i18n.dir()} back-button bold`}>
							<ChevronLeft />
							<span>{t("subheader.fifa.wc_back")}</span>
						</ExternalLink>
					) : (
						<ExternalLink
							href="https://www.fifa.com/fifaplus"
							className={`${i18n.dir()} bold`}>
							<ChevronLeft />
							<span>{t("subheader.fifa.wc_back")}</span>
						</ExternalLink>
					)}
				</LinkWrapper>
				{MOBILE_MENU_NAVIGATION.map((item) => {
					const showMenu =
						!item.authorizationRequired || (item.authorizationRequired && isAuth);

					return (
						<Exist when={showMenu} key={item.name}>
							{returnLinks(item)}
						</Exist>
					);
				})}

				<LinkWrapper className="spaced">
					<Exist when={isShowTutorial}>
						<TutorialLink className="menu-tutorial-button" />
					</Exist>
					<MobileLanguageSelector />
					<ExternalLink href="https://www.fifa.com/">
						<span>{t("subheader.fifa.fifa_com")}</span>
						<img src={openIn} alt="open" />
					</ExternalLink>
				</LinkWrapper>
			</Menu>
		</Box>
	);
};

const SubLinkWrapper = styled.div`
	padding-inline-start: 20px;
	> a.active {
		color: ${({theme}) => theme.header.header_hover_color};
	}
`;

interface ISubAccordionProps {
	item: ISubNavigationItem;
}

const SubAccordion: React.FC<ISubAccordionProps> = ({item}) => {
	const isSubItemsVisible =
		item.subItems &&
		item.subItems.length !== 0 &&
		item.subItemsVisibility !== ViewPortVisibility.DesktopOnly;

	if (item.hidden) {
		return null;
	}

	if (isSubItemsVisible) {
		return (
			<HOCAccordion item={<SubLink item={item} />} fullWidth={item.isDropdown}>
				{item.subItems?.map((item) => (
					<SubLinkWrapper key={item.name}>
						<SubLink item={item} />
					</SubLinkWrapper>
				))}
			</HOCAccordion>
		);
	}

	return <SubLink item={item} />;
};
