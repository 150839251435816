import {createAction} from "redux-act";
import {ITournamentStatus} from "apps/FANTASY_DAILY/modules/enums";
import {StatusType} from "modules";

export interface ITournament {
	id: number;
	venueName: string;
	venueId: number;
	date: string;
	homeSquadId: number;
	awaySquadId: number;
	homeSquadName: string;
	awaySquadName: string;
	homeScore: number | null;
	awayScore: number | null;
	status: ITournamentStatus;
}

export interface IContest {
	id: number;
	status: StatusType;
	number: number;
	startDate: string;
	endDate: string;
	tournaments: ITournament[];
	isAllowed: boolean;
}

export const fetchContest = createAction();
export const fetchContestSuccess = createAction<IContest[]>();
export const fetchContestError = createAction<Error | string | unknown>();

export const selectContest = createAction<number>();
