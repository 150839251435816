import React from "react";
import {useSelector} from "react-redux";
import {getJsonState} from "modules/selectors/json";
import {SectionContent, SectionTitle, SectionWrapper} from "./common";
import {useTranslation} from "react-i18next";

export const Guideline: React.FC = () => {
	const {t, i18n} = useTranslation();
	const {helpPages} = useSelector(getJsonState);

	if (!helpPages) {
		return null;
	}

	const data = helpPages.how_to_play?.find((guide) => guide.lang === i18n.language);

	return (
		<SectionWrapper>
			<SectionTitle>{t("help.fifa.how_to")}</SectionTitle>
			<SectionContent dangerouslySetInnerHTML={{__html: data?.text || ""}} />
		</SectionWrapper>
	);
};
