import {ProjectName, PROJECT_NAME} from "modules";
import styled, {css} from "styled-components";
import {media} from "assets/css/media";
import React from "react";

export const PureButton = styled.button<{isDisabled?: boolean}>`
	border: 0;
	padding: 0;
	margin: 0;
	background: none;
	cursor: ${({isDisabled}) => (isDisabled ? "initial" : "pointer")};
`;

const useShadowHover = [
	ProjectName.GameZone,
	ProjectName.Fives,
	ProjectName.Goalscorer,
	ProjectName.Trivia,
	ProjectName.WhoAmI,
	ProjectName.Brackets,
].includes(PROJECT_NAME as ProjectName);

export const hexagonClipPath = css`
	clip-path: polygon(
		var(--cornerWidth) 0,
		calc(100% - var(--cornerWidth)) 0,
		100% 50%,
		calc(100% - var(--cornerWidth)) 100%,
		var(--cornerWidth) 100%,
		0% 50%
	);
	--cornerWidth: 10px;
`;

const getShadowHoverStyles = () => {
	return useShadowHover
		? css`
				&:hover:not([disabled]) {
					box-shadow: 0px 0.5px 1px rgba(28, 28, 30, 0.1),
						0px 2px 8px rgba(28, 28, 30, 0.2);
				}
		  `
		: "";
};

export const PrimaryButton = styled(PureButton)`
	position: relative;
	height: 40px;
	padding: 0 16px;
	font-weight: 500;
	font-size: 14px;
	color: ${({theme}) => theme.button.primary.color};
	background: ${({theme}) => theme.button.primary.background};
	border-color: ${({theme}) => theme.button.primary.borderColor};
	display: inline-flex;
	align-items: center;
	justify-content: center;
	transition: all 0.2s ease-in-out;
	border-radius: 45px;
	${getShadowHoverStyles}

	&:hover:not([disabled]) {
		color: ${({theme}) => theme.button.primary.hoverColor};
		background: ${({theme}) => theme.button.primary.hoverBackground};
		border-color: ${({theme}) => theme.button.primary.hoverBorderColor};
	}

	&:disabled {
		cursor: default;
		background: ${({theme}) => theme.colors.light};
		color: #fff;
		box-shadow: none;
	}

	&.fullwidth {
		width: 100%;
	}

	&.large {
		height: 50px;
		font-size: 16px;
	}

	&.small {
		height: 32px;
		font-size: 12px;
	}

	@media (max-width: ${media.mobile}) {
		&.fullwidth-mobile {
			width: 100%;
		}
	}
`;

export const PrimaryButtonReversed = styled(PrimaryButton)`
	color: ${({theme}) => theme.button.primary.background};
	background: ${({theme}) => theme.button.primary.color};
	${getShadowHoverStyles}

	&:hover:not([disabled]) {
		color: ${({theme}) => theme.button.primary.color};
		background: ${({theme}) => theme.button.primary.background};
	}
`;

export const SecondaryButton = styled(PureButton)`
	position: relative;
	height: 40px;
	padding: 0 16px;
	font-weight: 500;
	font-size: 14px;
	color: ${({theme}) => theme.button.secondary.color};
	background: ${({theme}) => theme.button.secondary.background};
	display: inline-flex;
	align-items: center;
	justify-content: center;
	transition: all 0.2s ease-in-out;
	border: 2px solid ${({theme}) => theme.button.secondary.borderColor};
	border-radius: 45px;
	${getShadowHoverStyles}

	&:hover:not([disabled]) {
		color: ${({theme}) => theme.button.secondary.hoverColor};
		background: ${({theme}) => theme.button.secondary.hoverBackground};
		border-color: ${({theme}) => theme.button.secondary.hoverBorderColor};
	}

	&:disabled {
		cursor: default;
		color: ${({theme}) => theme.colors.light};
		border-color: ${({theme}) => theme.colors.light};
		background: #f2f2f2;
		box-shadow: none;
	}

	&.fullwidth {
		width: 100%;
	}

	&.large {
		height: 50px;
		font-size: 16px;
	}

	&.small {
		height: 32px;
		font-size: 12px;
	}

	@media (max-width: ${media.mobile}) {
		&.fullwidth-mobile {
			width: 100%;
		}
	}
`;

export const WarningButton = styled(PrimaryButton)`
	color: ${({theme}) => theme.button.warning.color};
	background: ${({theme}) => theme.button.warning.background};
	${getShadowHoverStyles}

	&:hover:not([disabled]) {
		color: ${({theme}) => theme.button.warning.hoverColor};
		background: ${({theme}) => theme.button.warning.hoverBackground};
	}
`;

export const BurgerIcon = styled.div`
	width: 14px;
	height: 10px;
	position: relative;
	transform: rotate(0deg);
	transition: 0.5s ease-in-out;

	&.open span:nth-child(1) {
		transform: rotate(45deg);
		top: -1px;
		left: 2px;
	}

	&.open span:nth-child(2) {
		width: 0%;
		opacity: 0;
	}

	&.open span:nth-child(3) {
		transform: rotate(-45deg);
		top: 9px;
		left: 2px;
	}

	span {
		display: block;
		position: absolute;
		height: 2px;
		width: 100%;
		background: #ffffff;
		border-radius: 9px;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: 0.25s ease-in-out;

		&:nth-child(1) {
			top: 0px;
			transform-origin: left center;
		}

		&:nth-child(2) {
			top: 4px;
			transform-origin: left center;
		}

		&:nth-child(3) {
			top: 8px;
			transform-origin: left center;
		}
	}
`;

const HamburgerButtonWrapper = styled(PureButton)`
	padding: 8px;
`;

const DropdownButtonWrapper = styled(PureButton)`
	background: hsla(0, 0%, 100%, 0.15);
	display: flex;
	justify-content: center;
	place-items: center;
	height: 28px;
	min-height: 28px;
	width: 28px;
	min-width: 28px;
	border-radius: 50%;
	margin-inline-end: 20px;
`;

interface IBurgerButton {
	onClick: () => void;
	className: string;
}

export const BurgerButton: React.FC<IBurgerButton> = ({onClick, className}) => (
	<HamburgerButtonWrapper onClick={onClick}>
		<BurgerIcon className={className}>
			<span></span>
			<span></span>
			<span></span>
		</BurgerIcon>
	</HamburgerButtonWrapper>
);

export const DropdownBurgerButton: React.FC<IBurgerButton> = ({onClick, className}) => (
	<DropdownButtonWrapper onClick={onClick}>
		<BurgerIcon className={className}>
			<span></span>
			<span></span>
			<span></span>
		</BurgerIcon>
	</DropdownButtonWrapper>
);
