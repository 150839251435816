import React, {useEffect} from "react";
import styled from "styled-components";
import {NavLink, Outlet, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {HOCLayout, PlayZoneWidget, Preloader} from "components";
import {Container} from "components/Container";
import {media} from "assets/css/media";
import {useDispatch, useSelector} from "react-redux";
import {getJsonState} from "modules/selectors";
import {RequestState, trackAdobeAnalyticsViewChange} from "modules";
import {fetchHelpPageJsons} from "modules/actions";
import {chain} from "lodash";
import {Grid} from "@mui/material";

const StyledContainer = styled(Container)`
	@media screen and (max-width: ${media.mobile}) {
		padding-left: 0;
		padding-right: 0;
	}
`;

const Wrapper = styled.div`
	background: ${({theme}) => theme.help.help_background || "#00287A"};
	padding-top: 40px;
	padding-bottom: 80px;
	position: relative;
	min-height: calc(100vh - 237px);

	@media screen and (max-width: ${media.mobile}) {
		background: ${({theme}) => theme.help.help_background_mobile || null};
	}
`;

const NavigationBlock = styled.div`
	background-color: ${({theme}) => theme.help.help_nav_background};
	border-top-left-radius: ${({theme}) => theme.help.help_border_radius};
	border-top-right-radius: ${({theme}) => theme.help.help_border_radius};
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	column-gap: 24px;
	row-gap: 8px;
	padding: 0 16px;

	a {
		color: ${({theme}) => theme.help.help_sub_nav_color};
		display: block;
		height: 48px;
		line-height: 48px;
		padding: 0 16px;
		font-size: 14px;
		font-weight: 500;
	}

	a.active,
	a:hover {
		color: ${({theme}) => theme.help.help_sub_nav_active_color};
		border-bottom: 2px solid ${({theme}) => theme.help.help_sub_nav_active_color};
	}

	@media (max-width: ${media.mobile}) {
		overflow: auto;
		justify-content: flex-start;
		flex-wrap: nowrap;
		border-top-left-radius: 0;
		border-top-right-radius: 0;

		a {
			white-space: nowrap;
			padding: 0 8px;
			text-align: center;
			font-size: 11px;
		}
	}
`;

const Title = styled.h1`
	font-weight: 600;
	font-size: 24px;
	line-height: 120%;
	color: ${({theme}) => theme.help.help_title_color};
	margin-bottom: 16px;

	@media screen and (max-width: ${media.mobile}) {
		font-size: 20px;
	}
`;

interface IHelpLayout {
	links: Array<{url: string; key: string}>;
	children?: React.ReactNode;
}

export const HelpLayout: React.FC<IHelpLayout> = ({links, children}) => {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const {requestState, faqRequestState} = useSelector(getJsonState);
	const isLoading =
		requestState === RequestState.Requested || faqRequestState === RequestState.Requested;
	const {pathname} = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	});

	useEffect(() => {
		dispatch(fetchHelpPageJsons());
	}, [dispatch]);

	useEffect(() => {
		const path = chain(pathname).replace("/help", "").split("/").pop().value();

		if (path) {
			trackAdobeAnalyticsViewChange(path);
		}
	}, [pathname]);

	return (
		<HOCLayout>
			<Wrapper>
				<Container>
					<Grid container spacing={2}>
						<Grid item xs={12} md={8}>
							<Title>{t("help.fifa.title")}</Title>
						</Grid>
					</Grid>
				</Container>
				<StyledContainer>
					<Grid container spacing={2}>
						<Grid item xs={12} md={8}>
							<NavigationBlock>
								{links.map(({url, key}) => (
									<NavLink key={url} to={url}>
										{t(key)}
									</NavLink>
								))}
							</NavigationBlock>
							{isLoading ? <Preloader /> : <Outlet />}
						</Grid>
						<Grid item xs={12} md={4}>
							<PlayZoneWidget />
						</Grid>
					</Grid>
				</StyledContainer>
			</Wrapper>
			<React.Fragment>{children}</React.Fragment>
		</HOCLayout>
	);
};
