import React, {useEffect, useRef} from "react";
import styled from "styled-components";
import i18next from "i18next";

const Wrapper = styled.div`
	width: 292px;
	margin: auto;
`;

export const PlayZoneWidget: React.FC = () => {
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const currentRef = ref.current;
		const script = document.createElement("script");

		script.src = "https://play.fifa.com/widgets/scripts/playzone.js";
		script.type = "application/javascript";
		script.dataset.language = i18next.language;
		script.async = true;

		currentRef?.appendChild(script);

		return () => {
			currentRef?.removeChild(script);
		};
	}, []);

	return <Wrapper ref={ref} />;
};
