import React, {Fragment} from "react";
import {Exist, Footer, Header, TopBanner} from "components";
import styled from "styled-components";
import {IS_APP} from "modules";

const Main = styled.main`
	flex: 1 1 0;
	font-family: ${({theme}) => theme.baseFont};

	&.levels {
		flex: 0 1 0;
	}
`;

interface IProps {
	customClass?: string;
	children: React.ReactNode;
}

export const HOCLayout: React.FC<IProps> = ({children, customClass = ""}) => (
	<Fragment>
		<Header />
		<TopBanner />
		<Main className={customClass}>{children}</Main>
		<Exist when={!IS_APP}>
			<Footer customClass={customClass} />
		</Exist>
	</Fragment>
);
