import {GameType} from "apps/GAME_ZONE/modules/types";
import matchIcon from "apps/GAME_ZONE/assets/images/quick-links/match.png";
import bracketIcon from "apps/GAME_ZONE/assets/images/quick-links/bracket.png";
import dailyIcon from "apps/GAME_ZONE/assets/images/quick-links/daily.png";
import fancestryIcon from "apps/GAME_ZONE/assets/images/quick-links/fancestry.png";
import gottIcon from "apps/GAME_ZONE/assets/images/quick-links/gott.png";
import paniniIcon from "apps/GAME_ZONE/assets/images/quick-links/panini.png";
import potmIcon from "apps/GAME_ZONE/assets/images/quick-links/potm.png";
import triviaIcon from "apps/GAME_ZONE/assets/images/quick-links/trivia.png";
import whoamiIcon from "apps/GAME_ZONE/assets/images/quick-links/whoami.png";
import fivesIcon from "apps/GAME_ZONE/assets/images/quick-links/fives.png";
import h2hIcon from "apps/GAME_ZONE/assets/images/quick-links/h2h.png";
import goalscorerIcon from "apps/GAME_ZONE/assets/images/quick-links/goalscorer.png";

export const IS_DISABLED_DAILY_CHALLENGE = JSON.parse(
	process.env.REACT_APP_IS_DISABLED_DAILY_CHALLENGE || "false"
) as boolean;

export const IS_HIDE_TROPHIES = JSON.parse(
	process.env.REACT_APP_IS_HIDE_TROPHIES || "false"
) as boolean;

export const IS_BADGES_DISABLED = JSON.parse(
	process.env.REACT_APP_IS_BADGES_DISABLED || "false"
) as boolean;

export const gameNavigation = [
	{
		key: "landing.sub_bar.my_zone",
		url: "/",
		isSponsorEnabled: false,
		loggedOut: true,
	},
	{
		key: "landing.sub_bar.badges",
		url: "/badges",
		isSponsorEnabled: true,
		isHide: IS_BADGES_DISABLED,
	},
	{
		key: "landing.sub_bar.trophies",
		url: "/trophies",
		isSponsorEnabled: true,
		isHide: IS_HIDE_TROPHIES,
	},
	{
		key: "landing.sub_bar.levels",
		url: "/levels",
		isSponsorEnabled: true,
	},
	{
		key: "landing.sub_bar.leaderboard",
		url: "/leaderboards",
		isSponsorEnabled: true,
	},
	{
		key: "landing.sub_bar.daily_challenge",
		url: "/daily-challenge",
		isSponsorEnabled: true,
		isHide: IS_DISABLED_DAILY_CHALLENGE,
	},
	{
		key: "landing.sub_bar.other_games",
		url: "/other-games",
		isSponsorEnabled: true,
		loggedOut: true,
	},
];

export const colaHelpLinks = [
	{
		url: "/coca-cola/help/privacy",
		key: "help.fifa.privacy",
	},
];

export const helpLinks = [
	{
		url: "/help/guidelines",
		key: "help.fifa.how_to",
	},
	{
		url: "/help/faqs",
		key: "help.fifa.faqs",
	},
	{
		url: "/help/contact",
		key: "help.fifa.contact_us",
	},
];

export const tilesLinks = {
	[GameType.CONTENT_BRACKETS]: "/content-bracket/",
	[GameType.TRIVIA]: "/trivia/",
	[GameType.DRAW_PREDICTOR]: "",
	[GameType.FIVES]: "/fives/",
	[GameType.SUSTAINABILITY_QUIZ]: "https://play.fifa.com/hyundai-quiz/",
	[GameType.FANTASY_CLASSIC]: {
		played: "/fantasy-classic/team",
		notPlayed: "/fantasy-classic/",
	},
	[GameType.FANTASY_DAILY]: {
		played: "/fantasy-daily/team",
		notPlayed: "/fantasy-daily/",
	},
	[GameType.BRACKET_PREDICTOR]: {
		played: "/bracket-predictor/brackets",
		notPlayed: "/bracket-predictor/",
	},
	[GameType.PLAYER_OF_THE_MATCH_VOTE]: "/potm/",
	[GameType.MATCH_PREDICTOR]: {
		played: "/match-predictor/match",
		notPlayed: "/match-predictor/",
	},
	[GameType.WHOAMI]: {
		played: "/whoami/game",
		notPlayed: "/whoami/",
	},
	[GameType.GAMEZONE]: "",
	[GameType.PANINI]: "https://paninicollection.fifa.com/",
	[GameType.GOTT]: "/gott",
	[GameType.BEST_AWARDS]: "https://www.fifa.com/fifaplus/en/the-best-fifa-football-awards",
	[GameType.GOALSCORER]: "/goalscorer/",
	[GameType.FANCESTRY_QUIZ]: "/fancestry-quiz/",
};

export const tilesTitles: Record<GameType, string> = {
	[GameType.CONTENT_BRACKETS]: "landing.tile_name.content_bracket",
	[GameType.TRIVIA]: "landing.tile_name.trivia",
	[GameType.DRAW_PREDICTOR]: "",
	[GameType.FIVES]: "fives_tile.tile.header",
	[GameType.SUSTAINABILITY_QUIZ]: "landing.tile_name.hyundai_pledge",
	[GameType.FANTASY_CLASSIC]: "landing.tile_name.fantasy",
	[GameType.FANTASY_DAILY]: "landing.tile_name.daily_fantasy",
	[GameType.BRACKET_PREDICTOR]: "landing.tile_name.bracket_challenge",
	[GameType.PLAYER_OF_THE_MATCH_VOTE]: "tile.fifa.budweiserpotm_predictor_title",
	[GameType.MATCH_PREDICTOR]: "landing.tile_name.match_predictor",
	[GameType.WHOAMI]: "landing.tile_name.whoami",
	[GameType.GAMEZONE]: "",
	[GameType.PANINI]: "panini_tile.copy.header",
	[GameType.GOTT]: "gott_tile.tile.header",
	[GameType.BEST_AWARDS]: "best_tile.tile.header",
	[GameType.GOALSCORER]: "ggs_tile.tile.header",
	[GameType.FANCESTRY_QUIZ]: "fancestry_tile.tile.header",
};

export const descriptionsList = {
	[GameType.CONTENT_BRACKETS]: {
		PreVotingR16: "cb_tile.text.r16_open",
		PreVotingQF: "cb_tile.text.quarter_open",
		PreVotingSF: "cb_tile.text.semi_open",
		PreVotingF: "cb_tile.text.final_open",
		PostVotingR16: "cb_tile.text.r16_submitted",
		PostVotingQF: "cb_tile.text.quarter_submitted",
		PostVotingSF: "cb_tile.text.semi_submitted",
		PostVotingF: "cb_tile.text.final_submitted",
		WinnerPublished: "cb_tile.text.bracket_final",
		Timer: "cb_tile.timer.text",
		TimerComplete: "h2h_tile.tile_text.complete",
	},
	[GameType.TRIVIA]: {
		Body: "trivia.text.new_quiz",
		Timer: "trivia.timer.text",
	},
	[GameType.DRAW_PREDICTOR]: {
		PreLockoutNotPlayed: "",
		PreLockoutPlayed: "",
	},
	[GameType.FIVES]: {
		Default: "fives_tile.tile.desc",
		PreLockoutNotPlayed: "fives_tile.tile_text.pre_lockout_no_picks",
		PreLockoutPlayed: "fives_tile.tile_text.pre_lockout_full_picks",
		LivePlaying: "fives_tile.tile_text.live",
		TimerPreLockout: "fives_tile.tile.status_lockout",
		TimerLive: "fives_tile.tile.status_in_progress",
	},
	[GameType.SUSTAINABILITY_QUIZ]: {
		Default: "hyundai_tile.text.static",
	},
	[GameType.FANTASY_CLASSIC]: {
		PreLockoutNotPlayed: "fanc_tile.text.prelock_not_played",
		PreLockoutPlayed: "fanc_tile.text.prelock_played",
		LiveNotPlayed: "fanc_tile.text.ingame_not_played",
		LivePlayer: "fanc_tile.text.ingame_played",
		PostGamedayPlayer: "fanc_tile.text.postgame_played",
		PostGamedayInjuredPlayer: "fanc_tile.text.postgame_injured",
		PostMatchDayPlayer: "fanc_tile.text.postmatchday_played",
		PostTournamentPlayed: "fanc_tile.text.post_tournament",
	},
	[GameType.FANTASY_DAILY]: {
		ComingSoon: "daily_tile.text.coming_soon",
		PreLockoutNotPlayed: "daily_tile.text.prelock_not_played",
		PreLockoutPlayed: "daily_tile.text.prelock_played",
		LiveNotPlayed: "daily_tile.text.ingame_not_played",
		LivePlayed: "daily_tile.text.ingame_played",
		PostGameday: "daily_tile.text.postgame_played",
		PostTournamentPlayed: "daily_tile.text.post_tournament",
	},
	[GameType.BRACKET_PREDICTOR]: {
		PreLockoutNotPlayed: "bracket_tile.text.prelock_not_played",
		PreLockoutPlayed: "bracket_tile.text.prelock_played",
		LiveNotPlayed: "bracket_tile.text.ingame_played",
		GroupStagePlayed: "bracket_tile.text.ingame_during_groups",
		PostGroupStagePlayed: "bracket_tile.text.ingame_post_groups",
		PostTournamentPlayed: "bracket_tile.text.post_tournament",
	},
	[GameType.PLAYER_OF_THE_MATCH_VOTE]: {
		PreLockoutNotPlayed: "",
		PreLockoutPlayed: "",
	},
	[GameType.MATCH_PREDICTOR]: {
		ComingSoon: "mp_tile.text.coming_soon",
		PreLockoutNotPlayed: "mp_tile.text.prelock_not_played",
		PreLockoutPlayed: "mp_tile.text.prelock_played",
		PreLockoutOrLivePlayer: "mp_tile.text.missing_predictions",
		LiveNotPlayed: "mp_tile.text.ingame_not_played",
		LivePlayed: "mp_tile.text.ingame_played",
		PostMatchdayPlayed: "mp_tile.text.post_matchday",
		PostTournamentPlayed: "mp_tile.text.post_tournament",
	},
	[GameType.WHOAMI]: {
		ComingSoon: "whoami_tile.text.coming_soon",
		NeverPlayed: "whoami_tile.text.never_played",
		NotPlayedToday: "whoami_tile.text.not_played_today",
		PlayedToday: "whoami_tile.text.played_today_one",
		PlayedTodayTwo: "whoami_tile.text.played_today_two",
	},
	[GameType.GAMEZONE]: {
		PreLockoutNotPlayed: "",
		PreLockoutPlayed: "",
	},
	[GameType.PANINI]: {
		LoggedOut: "panini_tile.copy_logged_out.body",
		NotClaimed: "panini_tile.copy_logged_in.body_not_claimed",
		Claimed: "panini_tile.copy_logged_in.body_claimed",
	},
	[GameType.GOTT]: {
		ComingSoon: "gott_tile.text.pre_open",
		PreLockoutNotPlayed: "gott_tile.text.not_voted",
		PreLockoutPlayed: "gott_tile.text.voted",
		PostTournament: "gott_tile.text.complete",
		Timer: "gott_tile.tile.timer",
	},
	[GameType.BEST_AWARDS]: {
		Default: "best_tile.tile.desc",
	},
	[GameType.GOALSCORER]: {
		PreLockoutNotPlayed: "ggs_tile.tile.desc_pre_season",
		PreLockoutPlayed: "ggs_tile.tile.desc_in_season_not_live",
		LivePlaying: "ggs_tile.tile.desc_in_season",
		LiveNotPlaying: "ggs_tile.tile.desc_in_season_not_live",
		LiveNotPlayed: "ggs_tile.tile.desc_no_team",
		TimerPreLockout: "ggs_tile.tile.status_pre_season",
		TimerLive: "ggs_tile.tile.status_in_season",
		PostSeason: "ggs_tile.tile.desc_post_season",
		TimerPostSeason: "ggs_tile.tile.status_post_season",
	},
	[GameType.FANCESTRY_QUIZ]: {
		Default: "fancestry_tile.tile.desc",
	},
};

export const IS_SPONSOR_ENABLED = JSON.parse(
	process.env.REACT_APP_IS_SPONSOR_ENABLED || "false"
) as boolean;

export const quickLinkIcons: Record<GameType, string> = {
	[GameType.CONTENT_BRACKETS]: h2hIcon,
	[GameType.TRIVIA]: triviaIcon,
	[GameType.DRAW_PREDICTOR]: "",
	[GameType.FIVES]: fivesIcon,
	[GameType.SUSTAINABILITY_QUIZ]: "",
	[GameType.FANTASY_CLASSIC]: "",
	[GameType.FANTASY_DAILY]: dailyIcon,
	[GameType.BRACKET_PREDICTOR]: bracketIcon,
	[GameType.PLAYER_OF_THE_MATCH_VOTE]: potmIcon,
	[GameType.MATCH_PREDICTOR]: matchIcon,
	[GameType.WHOAMI]: whoamiIcon,
	[GameType.GAMEZONE]: "",
	[GameType.PANINI]: paniniIcon,
	[GameType.GOTT]: gottIcon,
	[GameType.BEST_AWARDS]: "",
	[GameType.GOALSCORER]: goalscorerIcon,
	[GameType.FANCESTRY_QUIZ]: fancestryIcon,
};

export const quickLinkTitles: Record<GameType, string> = {
	[GameType.CONTENT_BRACKETS]: "quick_links.icon.h2h",
	[GameType.TRIVIA]: "quick_links.icon.trivia",
	[GameType.DRAW_PREDICTOR]: "",
	[GameType.FIVES]: "quick_links.icon.fives",
	[GameType.SUSTAINABILITY_QUIZ]: "",
	[GameType.FANTASY_CLASSIC]: "",
	[GameType.FANTASY_DAILY]: "quick_links.icon.daily",
	[GameType.BRACKET_PREDICTOR]: "quick_links.icon.bracket",
	[GameType.PLAYER_OF_THE_MATCH_VOTE]: "quick_links.icon.potm",
	[GameType.MATCH_PREDICTOR]: "quick_links.icon.match",
	[GameType.WHOAMI]: "quick_links.icon.whoami",
	[GameType.GAMEZONE]: "",
	[GameType.PANINI]: "quick_links.icon.panini",
	[GameType.GOTT]: "quick_links.icon.gott",
	[GameType.BEST_AWARDS]: "",
	[GameType.GOALSCORER]: "quick_links.icon.goalscorer",
	[GameType.FANCESTRY_QUIZ]: "quick_links.icon.fancestry",
};

export const FIFA_COLLECT_TUTORIAL_KEY = "fifa_collect_tutorial";
export const GAME_ZONE_TROPHY_WELCOME_KEY = "gz_trophy_welcome";
