import styled from "styled-components";
import {PureButton} from "components";
import {RoundStatus, Stage, StatusType} from "modules";
import {IconButton} from "@mui/material";
import buttonBg from "apps/MATCH_PREDICTOR/assets/images/icons/common_button_bg.svg";

export const roundSelectLabels = {
	short: {
		days: "day",
		hrs: "hrs",
		min: "min",
		secs: "sec",
	},
	medium: {
		days: "day",
		hrs: "hrs",
		min: "min",
		secs: "sec",
	},
};

export const roundSelectDays: Record<number, string> = {
	1: "round_carousel.header.matchday_1",
	2: "round_carousel.header.matchday_2",
	3: "round_carousel.header.matchday_3",
	4: "round_carousel.header.matchday_4",
	5: "round_carousel.header.matchday_5",
	6: "round_carousel.header.matchday_6",
	7: "round_carousel.header.matchday_7",
};

export const StageName = styled.div`
	width: 100%;
	font-family: "FWWC2023", sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 160%;
	display: flex;
	align-items: center;
	color: #2b5b6c;
	white-space: nowrap;
`;

export const SelectorWrapper = styled.div`
	width: 100%;
	position: relative;
`;

export const SelectorContainer = styled.div`
	width: 100%;
	min-height: 68px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 12px 10px;
	box-sizing: border-box;
	position: relative;
`;

export const ViewSwitcher = styled(PureButton)`
	position: relative;
	height: 44px;
	width: 50px;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	background: url(${buttonBg}) no-repeat;

	svg {
		width: 18px;
		height: 14px;
	}
`;

export const ArrowButton = styled(ViewSwitcher)`
	transition: all ease 0.4s;

	svg {
		transform: rotate(-90deg);
		transition: all ease 0.4s;

		width: 20px;
		height: 16px;
	}

	&.open {
		transition: all ease 0.4s;

		svg {
			transform: rotate(90deg);
		}
	}
`;

export const StartDate = styled.div`
	width: 100%;
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 160%;
	display: flex;
	align-items: center;
	color: #2b5b6c;
	opacity: 0.5;
	transition: all ease 0.3s;
	white-space: nowrap;

	> span {
		margin-right: 3px;

		&.complete {
			//color: #30d158;
		}
	}

	&.active,
	&.playing {
		color: ${({theme}) => theme.roundNavigation.subText};
		opacity: 1;
	}

	&.scheduled {
		color: ${({theme}) => theme.roundNavigation.lockedText};
		opacity: 1;
	}
`;

export const NavItem = styled.div`
	height: 100%;
	padding: 8px 15px;
	min-height: 52px;
	box-sizing: border-box;
	cursor: pointer;
	background: transparent;
	transition: all ease 0.3s;
	scroll-snap-align: start;

	@media (max-width: 1200px) {
		padding: 8px 12px;
	}

	@media (max-width: 1024px) {
		padding: 8px 8px;
	}

	&.active {
		.sub_text {
			color: ${({theme}) => theme.roundNavigation.subText};
			opacity: 1;
		}
	}

	&.selected {
		background: ${({theme}) => theme.roundNavigation.active};
	}

	&.disabled {
		cursor: auto;
	}

	&.round-transfer {
		${StartDate} {
			color: #ff004c;
		}
	}
`;

export const MainSelector = styled.div`
	flex: 1;
	cursor: pointer;

	&.round-transfer {
		${StartDate} {
			color: #ff004c;
		}
	}
`;

export const SelectorList = styled.div`
	width: 100%;
	position: absolute;
	left: 0;
	right: 0;
	top: 56px;
	height: 0;
	transition: all ease 0.5s;
	background: ${({theme}) => theme.header.header_sub_nav_background};
	//background: #ECB3CB;
	z-index: 10;

	&.open {
		transition: all ease 0.5s;
		height: auto;
	}
`;

export const ListItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 8px 20px;
	box-sizing: border-box;
	border-bottom: 1px solid #ececec;
	background: transparent;
	cursor: pointer;
	transition: all ease 0.3s;
	background: ${({theme}) => theme.header.header_sub_nav_background};

	&:first-child {
		border-top: 1px solid #ededed;
	}

	&.active {
		.sub_text {
			color: ${({theme}) => theme.roundNavigation.subText};
			opacity: 1;
		}
	}

	&.selected {
		background: ${({theme}) => theme.roundNavigation.active};
	}

	&.disabled {
		cursor: auto;
		//background: #e4e4e4;
	}

	&.round-transfer {
		${StartDate} {
			color: #ff004c;
		}
	}
`;

export const getIsDisabledRound = (
	id: number,
	status: RoundStatus | StatusType,
	actualRoundId?: number,
	stage?: Stage,
	isAllowed?: boolean
) => {
	if (actualRoundId === id || stage === Stage.Group) {
		return false;
	}

	if (isAllowed) {
		return false;
	}

	return status === "scheduled";
};

export const isDisabledMatchDay = (
	id: number,
	status: StatusType,
	isAllowed: boolean,
	actualRoundId?: number
) => {
	if (actualRoundId === id) {
		return false;
	}

	if (isAllowed) {
		return false;
	}

	return status === "scheduled";
};

export const getRoundClassName = (
	isDisabled: boolean,
	isActive: boolean,
	isSelected?: boolean,
	isComplete?: boolean
) => {
	let className = "";

	if (isDisabled) {
		className += " disabled";
	}

	if (isActive) {
		className += " active";
	}

	if (isSelected) {
		className += " selected";
	}

	if (isComplete) {
		className += " complete";
	}

	return className;
};

export const Button = styled(IconButton)`
	&.open {
		svg {
			transform: rotate(180deg);
		}
	}

	&.calendar-close {
		path {
			fill: ${({theme}) => theme.colors.secondary};
		}

		.calendar-bg {
			fill: ${({theme}) => theme.colors.secondary};
			opacity: 0.15;
		}
	}
`;

export const FixtureListInner = styled.div<{isOpen: boolean}>`
	background: rgba(106, 105, 103, 0.68);
	position: absolute;
	left: 0;
	right: 0;
	top: 80px;
	bottom: 0;
	//z-index: 510; // over bottom actions
	z-index: 49;
	height: ${({isOpen}) => (isOpen ? "100vh" : 0)};
	overflow: hidden;
`;

export const FixtureListWrapper = styled.div`
	width: 100%;
	max-height: calc(80vh - 200px);
	min-height: 100px;
	overflow: auto;
`;

export const FixtureContent = styled.div`
	padding: 12px 20px;
	background-color: #fff;
	border-bottom: 1px solid #ececec;

	&:last-child {
		border-bottom: none;
	}
`;

export const FixtureDateStart = styled.div`
	font-family: "Poppins", sans-serif;
	font-weight: 500;
	font-size: 10px;
	line-height: 160%;
	text-align: center;
	color: #2b5b6c;
	margin-bottom: 8px;
`;

export const FixtureWrapper = styled.div`
	display: flex;
	flex-flow: column;
	flex: auto;
	align-items: center;
	justify-content: center;
	color: #03122b;
	width: 100%;
`;

export const FixtureTeamContent = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 16px;
	width: 100%;

	img {
		max-width: 28px;
	}
`;

export const FixtureTeamInfo = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	flex: 1;

	&.home {
		justify-content: flex-end;
	}

	&.away {
		justify-content: flex-start;
	}
`;

export const FixtureTeamName = styled.div`
	font-weight: 500;
	font-size: 18px;
	line-height: 160%;
	text-transform: uppercase;

	@media (max-width: 768px) {
		font-size: 14px;
	}
`;

export const FixtureTeamFlag = styled.img``;

export const FixtureMatchTime = styled.div`
	font-family: "FWWC2023", sans-serif;
	font-weight: 600;
	font-size: 16px;
	line-height: 160%;
	color: #000;
	padding: 7px 11px;
	border: 1px solid #00968d;

	@media (max-width: 768px) {
		font-size: 14px;
	}
`;

export const FixtureScores = styled.div`
	height: 40px;
	display: flex;
	gap: 4px;
	div {
		height: 100%;
		width: 34px;
		background: #2b5b6c;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: "FWWC2023", sans-serif;
		font-weight: 600;
		font-size: 16px;
		color: #fff;
	}

	&.playing div {
		background: #36f597;
		color: #002c5f;
	}
`;
