import {BASE_URL, DEVICE, REDIRECT_SSO_URL} from "modules/constants";
import {endsWith} from "lodash";

export class SSO {
	private static AUTHORIZATION_SERVER = process.env.REACT_APP_AUTHORIZATION_SERVER || "";
	private static CLIENT_ID = process.env.REACT_APP_CLIENT_ID || "";
	private static CAMPAIGN_WEB = process.env.REACT_APP_CAMPAIGN_WEB || "";
	private static CAMPAIGN_ANDROID = process.env.REACT_APP_CAMPAIGN_ANDROID || "";
	private static CAMPAIGN_IOS = process.env.REACT_APP_CAMPAIGN_IOS || "";
	private static ACR_VALUES = process.env.REACT_APP_ACR_VALUES || "";

	public static get CAMPAIGN() {
		if (DEVICE === "Android") {
			return this.CAMPAIGN_ANDROID;
		}

		if (DEVICE === "iOS") {
			return this.CAMPAIGN_IOS;
		}

		return this.CAMPAIGN_WEB;
	}

	public static get REDIRECT_URL() {
		if (endsWith(BASE_URL, "/")) {
			return window.location.origin + REDIRECT_SSO_URL;
		}
		return window.location.origin + REDIRECT_SSO_URL + "/";
	}

	private static AUTH_URL(parameters?: URLSearchParams) {
		const url = new URL(SSO.AUTHORIZATION_SERVER + "as/authorize");
		url.searchParams.set("response_type", "code");
		url.searchParams.set("client_id", SSO.CLIENT_ID);
		url.searchParams.set("redirect_uri", SSO.REDIRECT_URL);
		url.searchParams.set("scope", "openid profile email");
		url.searchParams.set("prompt", "login");

		if (SSO.ACR_VALUES) {
			url.searchParams.set("acr_values", SSO.ACR_VALUES);
		}

		if (SSO.CAMPAIGN) {
			url.searchParams.set("campaign", SSO.CAMPAIGN);
		}

		if (parameters) {
			for (const [key, value] of parameters) {
				url.searchParams.set(key, value);
			}
		}

		return url;
	}

	private static APP_MESSAGE(parameters: URLSearchParams) {
		return {
			action: "promptLoginToUser",
			properties: Object.fromEntries(parameters),
		};
	}

	public static handleLogin(parameters?: URLSearchParams) {
		const url = this.AUTH_URL(parameters);
		const appMessage = this.APP_MESSAGE(url.searchParams);

		if (DEVICE === "Android") {
			window.android?.postMessage(JSON.stringify(appMessage));
			return;
		}

		if (DEVICE === "iOS") {
			window.webkit?.messageHandlers.ios.postMessage(appMessage);
			return;
		}

		window.location.href = url.toString();
	}

	public static handleRegister(parameters?: URLSearchParams) {
		const url = this.AUTH_URL(parameters);
		url.searchParams.set("flow", "register");
		const appMessage = this.APP_MESSAGE(url.searchParams);

		if (DEVICE === "Android") {
			window.android?.postMessage(JSON.stringify(appMessage));
			return;
		}

		if (DEVICE === "iOS") {
			window.webkit?.messageHandlers.ios.postMessage(appMessage);
			return;
		}

		window.location.href = url.toString();
	}
}
