import React from "react";
import {getLeagueById, getUser} from "modules/selectors";
import {useSelector} from "react-redux";
import styled from "styled-components";
import {Exist} from "components/Exist";
import {media} from "assets/css/media";
import {useTranslation} from "react-i18next";
import {NavLink, useParams} from "react-router-dom";
import {chain} from "lodash";

const Wrapper = styled.div`
	background-color: ${({theme}) => theme.leagues.leagues_nav_background};
	border-top-left-radius: ${({theme}) => theme.leagues.leagues_border_radius};
	border-top-right-radius: ${({theme}) => theme.leagues.leagues_border_radius};
	display: flex;
	justify-content: center;
	gap: 32px;

	a {
		color: ${({theme}) => theme.leagues.leagues_nav_text};
		display: block;
		height: 48px;
		line-height: 48px;
		padding: 0 16px;
		font-size: 14px;
		font-weight: 500;
	}

	a.active,
	a:hover {
		color: ${({theme}) => theme.leagues.leagues_nav_active_text};
		border-bottom: 2px solid ${({theme}) => theme.leagues.leagues_nav_active_text};
	}

	&.comms_bank {
		display: none;
	}

	@media (max-width: ${media.mobile}) {
		gap: 0;
		justify-content: space-around;
		border-top-left-radius: 0;
		border-top-right-radius: 0;

		a {
			padding: 0 8px;
		}
	}
`;

export const LeagueNavigation: React.FC = () => {
	const {t} = useTranslation();
	const params = useParams();
	const leagueId = chain(params).get("id", 0).toNumber().value();
	const user = useSelector(getUser);
	const league = useSelector(getLeagueById)(leagueId);
	const isOwner = user?.id === league?.leagueManager?.userId;

	return (
		<Wrapper className={league?.class}>
			<Exist when={isOwner}>
				<NavLink to={`/leagues/${leagueId}/table`}>{t("leagues.nav.table")}</NavLink>
				<NavLink to={`/leagues/${leagueId}/invites`}>{t("leagues.nav.invites")}</NavLink>
				<NavLink to={`/leagues/${leagueId}/settings`}>{t("leagues.nav.settings")}</NavLink>
			</Exist>
			<Exist when={!isOwner}>
				<NavLink to={`/leagues/${leagueId}/table`}>{t("leagues.nav.table")}</NavLink>
				<NavLink to={`/leagues/${leagueId}/invites`}>{t("leagues.nav.invites")}</NavLink>
				<NavLink to={`/leagues/${leagueId}/about`}>{t("leagues.nav.about")}</NavLink>
			</Exist>
		</Wrapper>
	);
};
