import React from "react";
import styled from "styled-components";

const ADWrapper = styled.div<IProps>`
	display: ${({hide}) => (hide ? "none" : "flex")};
	align-items: center;
	justify-content: center;
`;

const ADPlaceholder = styled.div`
	width: 320px;
	height: 50px;
	margin: 0 auto 33px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	background: rgba(255, 255, 255, 0.3);

	@media (min-width: 1000px) {
		width: 728px;
		height: 90px;
	}
`;

interface IProps {
	hide?: boolean;
}

export const Advert: React.FC<IProps> = ({hide}) => {
	if (hide) {
		return null;
	}

	return (
		<ADWrapper>
			<ADPlaceholder>ad placeholder 728 x 90</ADPlaceholder>
		</ADWrapper>
	);
};
