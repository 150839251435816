import React, {useCallback} from "react";
import {INavigateRound} from "modules/reducers";
import {useSelector} from "react-redux";
import {getFirstScheduledRound, getSelectedRoundId} from "modules/selectors";
import {eq, some} from "lodash";
import {RoundStatus} from "modules";
import {Exist, getRoundClassName, NavItem, roundSelectDays, StageName, StartDate} from "components";
import {DateTime} from "luxon";
import {useTranslation} from "react-i18next";
import {ReactComponent as LockIcon} from "assets/images/icons/lockIcon.svg";
import styled from "styled-components";
import {RoundTimer} from "components/Timer/RoundTimer";

const Container = styled.div`
	display: flex;
	margin-left: 6px;
	margin-bottom: 4px;
`;

interface IProps {
	round: INavigateRound;
	ns?: string;
	onSelect: (id: number) => void;
	onComplete: () => void;
}

export const DesktopNavItem: React.FC<IProps> = ({round, ns, onSelect, onComplete}) => {
	const {t} = useTranslation(ns);
	const {id, status, startDate, title, className = "", isShowTimer} = round;
	const selectedRoundId = useSelector(getSelectedRoundId);
	const firstScheduledRound = useSelector(getFirstScheduledRound);
	const isActualSelected = eq(selectedRoundId, id);
	const isDisabled = Boolean(round?.isDisabled);
	const isActiveRound = eq(status, RoundStatus.Active);
	const cn = getRoundClassName(isDisabled, isActiveRound, isActualSelected);
	const isScheduledRound = eq(status, RoundStatus.Scheduled);
	const isCompleteRound = eq(status, RoundStatus.Complete);
	const isStatusBarVisible = some([isScheduledRound, isActiveRound]);
	const startTime = DateTime.fromISO(startDate).toFormat("x");
	const matchDayName = roundSelectDays[id];

	const setSelectedRound = useCallback(() => {
		if (isDisabled) return;

		if (!isActualSelected) {
			onSelect(id);
		}
	}, [onSelect, isActualSelected, id, isDisabled]);

	return (
		<NavItem onClick={setSelectedRound} className={`${cn} ${className}`}>
			<StageName>
				<div>{t(matchDayName)}</div>

				<Exist when={isDisabled}>
					<Container>
						<LockIcon />
					</Container>
				</Exist>
			</StageName>

			<Exist when={isStatusBarVisible}>
				<StartDate className={status}>
					<Exist when={isActiveRound}>{t("round_carousel.header.in_progress")}</Exist>

					<Exist when={isScheduledRound}>
						<span>{t(title || "round_carousel.header.open_countdown")}</span>

						<Exist when={isShowTimer}>
							<Exist when={firstScheduledRound?.id === round.id}>
								<RoundTimer date={Number(startTime)} onComplete={onComplete} />
							</Exist>
							<Exist when={firstScheduledRound?.id !== round.id}>
								<span>
									{DateTime.fromMillis(Number(startTime)).toFormat(
										"EEE',' MMM d"
									)}
								</span>
							</Exist>
						</Exist>
					</Exist>
				</StartDate>
			</Exist>

			<Exist when={isCompleteRound}>
				<StartDate className={status}>
					{t("round_carousel.header.points", {X: round.points})}
				</StartDate>
			</Exist>
		</NavItem>
	);
};
