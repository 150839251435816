import React, {useEffect} from "react";
import styled from "styled-components";
import {MenuItem} from "@mui/material";
import {media} from "assets/css/media";
import {Exist, FormInput} from "components";
import {fetchCountries, setRankingFilters} from "modules/actions";
import {getCompleteActiveContests, getRankingFilters} from "modules/selectors";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useMatch} from "react-router-dom";
import {ReactComponent as ArrowDown} from "assets/images/icons/arrow_down.svg";
import {size} from "lodash";
import {ProjectName, PROJECT_NAME} from "modules";

const FilterWrapper = styled.div`
	display: flex;
	align-items: flex-end;
	gap: 24px;
	width: 40%;

	&.hide {
		display: none;
	}

	.MuiFormControl-root {
		flex: 1;
	}

	.MuiInput-root,
	.MuiSvgIcon-root {
		color: ${({theme}) => theme.leagues.leagues_text_secondary};

		&:before,
		&:hover:not(.Mui-disabled):before,
		&:after {
			border-color: ${({theme}) => theme.leagues.leagues_filter_border};
		}
	}

	.MuiInputLabel-root,
	.MuiInput-root,
	.MuiSvgIcon-root {
		color: ${({theme}) => theme.leagues.leagues_label_text};
	}

	.MuiSelect-icon {
		fill: ${({theme}) => theme.leagues.leagues_label_text};
	}

	&.comms_bank {
		.MuiInput-root,
		.MuiSvgIcon-root {
			color: #000;

			&:before,
			&:hover:not(.Mui-disabled):before,
			&:after {
				border-color: #fee501;
			}
		}

		.MuiInputLabel-root,
		.MuiInput-root,
		.MuiSvgIcon-root {
			color: #000;
		}

		.MuiSelect-icon {
			fill: #000;
		}
	}

	@media screen and (max-width: ${media.mobile}) {
		width: 100%;
	}
`;

interface IProps {
	className?: string;
}

export const LeagueFilters: React.FC<IProps> = ({className}) => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const contests = useSelector(getCompleteActiveContests);
	const filters = useSelector(getRankingFilters);
	const showContestSelector = size(contests) > 0;
	const isTable = useMatch("/leagues/:id/table");
	const isBracketPredictor = PROJECT_NAME === ProjectName.BracketsPredictor;
	const isDailyFantasy = PROJECT_NAME === ProjectName.FantasyDaily;

	useEffect(() => {
		dispatch(fetchCountries());
	}, [dispatch]);

	// Default to latest complete match day for Fantasy Daily
	useEffect(() => {
		const latestContest = contests.at(-1);
		if (isDailyFantasy && latestContest) {
			dispatch(
				setRankingFilters({
					startId: latestContest.id,
				})
			);
		}
	}, [dispatch, isDailyFantasy, contests]);

	const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value, name} = event.target;
		dispatch(
			setRankingFilters({
				[name]: value,
			})
		);
	};

	if (!isTable) {
		return null;
	}

	return (
		<FilterWrapper className={className}>
			{isBracketPredictor ? (
				<FormInput
					select
					variant="standard"
					name="stage"
					label={t("leaderboard.filter_one.header")}
					value={filters.stage}
					onChange={handleFilterChange}>
					<MenuItem value="overall">{t("leaderboard.filter_one.overall")}</MenuItem>
					<MenuItem value="group">{t("leaderboard.filter_one.groups_stage")}</MenuItem>
					<MenuItem value="knockout">
						{t("leaderboard.filter_one.knockout_stage")}
					</MenuItem>
				</FormInput>
			) : (
				<Exist when={showContestSelector}>
					<FormInput
						select
						variant="standard"
						name="startId"
						label={t("league.filters.week")}
						fullWidth={true}
						value={filters.startId}
						className="custom-arrow"
						SelectProps={{IconComponent: ArrowDown}}
						onChange={handleFilterChange}>
						<MenuItem value={0}>{t("league.filters.all")}</MenuItem>
						{contests.map(({id, roundNumber, translationKey}) => (
							<MenuItem key={id} value={id}>
								{t(translationKey, {X: roundNumber})}
							</MenuItem>
						))}
					</FormInput>
				</Exist>
			)}
		</FilterWrapper>
	);
};
