import {IStore} from "modules/types/store";
import {createSelector} from "reselect";
import {getIsNZAUUser} from "modules/selectors/json";

const getUserState = ({user}: IStore) => user;

export const getUser = createSelector(getUserState, (state) => state.user);

export const getIsAuth = createSelector(getUserState, (state) => Boolean(state.user));

export const getIsUserChecked = createSelector(getUserState, (state) => state.isUserChecked);

export const getUserId = createSelector(getUserState, (state) => state.user?.id);

export const getHasUserAgreedToTerms2 = createSelector(
	getUserState,
	(state) => state.user?.termsOptin2
);

export const getIsUserLogged = createSelector(getUserState, (state) => state.isUserLogged);

export const getUserRequestState = createSelector(getUserState, (state) => state.requestState);

export const getUserSsoCode = createSelector(getUserState, (state) => state.ssoCode);

export const getUserSsoApp = createSelector(getUserState, (state) => state.ssoApp);

export const getIsAdditionalDataRequired = createSelector(
	getUserState,
	(state) => state.isAdditionalDataRequired
);

export const getUserSSOParams = createSelector(getUserState, (state) => state.ssoParams);

export const getIsMatchPredictorTutorialViewed = createSelector(
	getUser,
	(user) => user?.tutorialMatchPredictor
);

export const getUserFantasyClassicTerms = createSelector(
	getUser,
	(user) => user?.termsFantasyClassic
);

export const getUserFantasyDailyTerms = createSelector(getUser, (user) => user?.termsFantasyDaily);

export const getUserGamezoneTerms = createSelector(getUser, (user) => user?.termsGamezone);

export const getUserCommsBankTerms = createSelector(getUser, (user) =>
	Boolean(user?.isOptinCommsBankTermsAndConditions)
);

export const getShowCommsBankBranding = createSelector(
	getUserFantasyDailyTerms,
	getUserCommsBankTerms,
	getIsNZAUUser,
	(fantasyDailyTerms, commsBankTerms, isNZAUUser) =>
		Boolean(commsBankTerms || (!fantasyDailyTerms && isNZAUUser))
);
