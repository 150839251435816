import React, {useCallback, useContext} from "react";
import styled, {ThemeContext} from "styled-components";
import {Modal} from "components";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {getShareModal} from "modules/selectors";
import {closeShareModal, saveShare} from "modules/actions";
import {media} from "assets/css/media";
import {PureButton} from "components/Button";
import {
	ADOBE_GAME_NAME,
	LANGUAGE_PATH,
	share,
	ShareNet,
	ShareType,
	trackAdobeAnalytics,
} from "modules";

const ModalContent = styled.div`
	text-align: center;
	padding: 60px 80px 35px;

	@media (max-width: ${media.mobile}) {
		padding: 60px 16px 28px;
	}
`;

const ShareIcon = styled.img`
	margin-bottom: 20px;
`;

const Title = styled.h2`
	font-weight: 500;
	font-size: 20px;
	line-height: 160%;
`;

const Description = styled.p`
	font-size: 16px;
	line-height: 160%;
	margin-bottom: 20px;
`;

const ButtonsWrapper = styled.div`
	display: flex;
	justify-content: center;
	gap: 12px;
`;

const ShareButton = styled(PureButton)`
	height: 40px;
	width: 40px;
	border-radius: 50%;
	background-color: ${({theme}) => theme.share.buttonBackground};
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const ShareModal: React.FC = () => {
	const dispatch = useDispatch();
	const {isOpen, title, description, shareParameters, saveShareParameters} =
		useSelector(getShareModal);
	const {t} = useTranslation(LANGUAGE_PATH);
	const theme = useContext(ThemeContext);

	const handleShare = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const {sc, type} = e.currentTarget.dataset;

		if (saveShareParameters) {
			dispatch(
				saveShare({
					...saveShareParameters,
					type: type as ShareType,
				})
			);
		}

		if (shareParameters) {
			trackAdobeAnalytics({
				event: "socialShare",
				socialShareSource: String(type),
				socialShareType: ADOBE_GAME_NAME,
			});

			share({
				...shareParameters,
				sc: sc as ShareNet,
			});
		}
	};

	const onClose = useCallback(() => {
		dispatch(closeShareModal());
	}, [dispatch]);

	if (!isOpen) {
		return null;
	}

	return (
		<Modal onClose={onClose}>
			<ModalContent>
				{theme?.share.icon && (
					<ShareIcon src={theme?.share.icon} alt={t("social_share.model.header")} />
				)}
				<Title>{title || t("social_share.model.header")}</Title>
				<Description>{description || t("social_share.model.body")}</Description>
				<ButtonsWrapper>
					<ShareButton
						onClick={handleShare}
						data-sc={ShareNet.Facebook}
						data-type={ShareType.Facebook}>
						<img src={theme?.share.facebookIcon} alt="Facebook" />
					</ShareButton>
					<ShareButton
						onClick={handleShare}
						data-sc={ShareNet.Twitter}
						data-type={ShareType.Twitter}>
						<img src={theme?.share.twitterIcon} alt="Twitter" />
					</ShareButton>
				</ButtonsWrapper>
			</ModalContent>
		</Modal>
	);
};
