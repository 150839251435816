import React from "react";
import styled from "styled-components";
import {SocialLinks} from "./SocialLinks";
import {useTranslation} from "react-i18next";
import logo from "assets/images/logos/fifa_logo.svg";
import appStore from "assets/images/logos/appleAppStore.png";
import playStore from "assets/images/logos/googlePlay.png";
import {Exist, PureButton} from "components";
import {LOGOSTRIP_COMPETITION, SHOW_LOGOSTRIP} from "modules";
import {media} from "assets/css/media";

const Wrapper = styled.footer`
	background-color: #2c4b86;

	&.levels {
		flex: auto;
	}
`;

const FooterSection = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
	margin: auto;
	max-width: calc(100% - 2rem);
	min-height: 56px;

	@media (min-width: ${media.mobile}) {
		max-width: calc(100% - 7.5rem);
	}

	@media (min-width: ${media.large_desktop}) {
		max-width: calc(100% - 10rem);
	}
`;

const FooterBottom = styled(FooterSection)`
	padding-top: 24px;
	padding-bottom: 24px;

	@media (max-width: ${media.mobile}) {
		align-items: flex-start;
		flex-direction: column;
	}
`;

const Divider = styled.hr`
	height: 1px;
	background-color: #a5acbb;
	border: none;
	margin: 0;
`;

const FifaLink = styled.a`
	display: flex;
	align-items: center;
`;

const FifaLogo = styled.img`
	max-height: 24px;
`;

const AppLinksWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;

	@media (max-width: ${media.mobile}) {
		gap: 8px;
	}
`;

const AppText = styled.p`
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	margin-inline-end: 50px;

	@media (max-width: 1440px) {
		display: none;
	}
`;

const AppImage = styled.img`
	max-height: 100%;

	@media (max-width: ${media.mobile}) {
		max-height: 27px;
	}
`;

const FooterLinks = styled.nav`
	display: flex;
	gap: 24px;

	@media (max-width: ${media.tablet}) {
		flex-direction: column;
		gap: 16px;
		margin-bottom: 16px;
	}
`;

const FooterLink = styled.a`
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	transition: color 0.2s ease-in-out;

	&:hover {
		color: #ffffff;
	}

	@media (max-width: ${media.mobile}) {
		font-size: 12px;
	}
`;

const FooterButton = styled(PureButton)`
	&#ot-sdk-btn.ot-sdk-show-settings {
		color: #fff;
		border: none;
		padding: 0;
		font-size: 14px;
		line-height: 14px;
		font-weight: 500;
		transition: color 0.2s ease-in-out;

		&:hover {
			color: #ffffff;
			background-color: transparent;
		}

		@media (max-width: ${media.mobile}) {
			font-size: 12px;
			line-height: 12px;
		}
	}
`;

const Copyright = styled.span`
	color: #fff;
	font-size: 10px;
`;

const LogoStripWrapper = styled.div`
	padding: 24px 0;
`;

interface IProps {
	customClass?: string;
}

export const Footer: React.FC<IProps> = ({customClass = ""}) => {
	const {t} = useTranslation();

	return (
		<Wrapper className={customClass}>
			<FooterSection>
				<FifaLink href="https://www.fifa.com/fifaplus">
					<FifaLogo src={logo} alt="Fifa Plus" />
				</FifaLink>
				<SocialLinks />
				<AppLinksWrapper>
					<AppText>{t("footer.fifa.download")}</AppText>
					<a
						href="https://play.google.com/store/apps/details?id=com.fifa.fifaapp.android&hl=en"
						target="_blank"
						rel="noreferrer">
						<AppImage src={playStore} alt="Google Play" />
					</a>
					<a
						href="https://apps.apple.com/us/app/fifa-official-app/id756904853"
						target="_blank"
						rel="noreferrer">
						<AppImage src={appStore} alt="App Store" />
					</a>
				</AppLinksWrapper>
			</FooterSection>
			<Divider />

			<Exist when={SHOW_LOGOSTRIP}>
				<LogoStripWrapper>
					{LOGOSTRIP_COMPETITION ? (
						<logo-strip
							data-competition={LOGOSTRIP_COMPETITION}
							data-theme="Dark_background"
						/>
					) : (
						<logo-strip
							data-logo-type="FIFA_Corporate_logostrip"
							data-theme="Dark_background"
						/>
					)}
				</LogoStripWrapper>
				<Divider />
			</Exist>

			<FooterBottom>
				<FooterLinks>
					<FooterLink
						href="https://www.fifa.com/data-protection-portal/data-protection-policy"
						target="_blank"
						rel="noreferrer">
						{t("footer.fifa.data")}
					</FooterLink>
					<FooterLink
						href="https://www.fifa.com/terms-of-service"
						target="_blank"
						rel="noreferrer">
						{t("footer.fifa.terms")}
					</FooterLink>
					<FooterLink
						href="https://www.fifa.com/fifaplus/en/articles/advertise-with-us"
						target="_blank"
						rel="noreferrer">
						{t("footer.fifa.advertise")}
					</FooterLink>
					<FooterButton id="ot-sdk-btn" className="ot-sdk-show-settings">
						{t("footer.fifa.preference")}
					</FooterButton>
				</FooterLinks>
				<Copyright>{t("footer.fifa.copryright")}</Copyright>
			</FooterBottom>
		</Wrapper>
	);
};
