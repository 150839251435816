import React, {useCallback} from "react";
import styled from "styled-components";
import {Modal, SecondaryButton} from "components";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {getErrorsState} from "modules/selectors";
import {clearGlobalError} from "modules/actions";
import error from "assets/images/icons/error.svg";
import {media} from "assets/css/media";

export const ErrorModalContent = styled.div`
	text-align: center;
	color: #03122b;
	padding: 60px 80px 35px;

	@media (max-width: ${media.mobile}) {
		padding: 60px 16px 28px;
	}
`;

export const ErrorIcon = styled.img`
	margin-bottom: 40px;
`;

export const ErrorModalTitle = styled.h2`
	font-weight: 600;
	font-size: 30px;
	margin-bottom: 24px;

	@media (max-width: ${media.mobile}) {
		font-size: 24px;
	}
`;

export const ErrorModalDescription = styled.p`
	font-weight: 500;
	margin-bottom: 24px;
	line-height: 160%;

	@media (max-width: ${media.mobile}) {
		font-size: 14px;
	}
`;

export const ErrorModal: React.FC = () => {
	const dispatch = useDispatch();
	const {message} = useSelector(getErrorsState);
	const {t} = useTranslation();

	const onClose = useCallback(() => {
		dispatch(clearGlobalError());
	}, [dispatch]);

	if (!message) {
		return null;
	}

	return (
		<Modal onClose={onClose}>
			<ErrorModalContent>
				<ErrorIcon src={error} alt="Tick" width="56" />
				<ErrorModalTitle>{t("model.error.header")}</ErrorModalTitle>
				<ErrorModalDescription>{message}</ErrorModalDescription>
				<SecondaryButton onClick={onClose}>{t("model.error.button")}</SecondaryButton>
			</ErrorModalContent>
		</Modal>
	);
};
