import React from "react";
import styled from "styled-components";
import {ReactComponent as CloseIcon} from "assets/images/icons/close.svg";
import {media} from "assets/css/media";
import {PureButton} from "components";
import {SponsorBanner} from "./common";

const ModalWrapper = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.5);
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const ModalContent = styled.div`
	text-align: center;
	padding: 50px 24px 62px;
	position: relative;

	h2 {
		font-size: 30px;
		font-weight: 600;
		margin-bottom: 16px;
	}

	p {
		font-size: 20px;
		line-height: 32px;
		font-weight: 500;
		margin-bottom: 34px;
	}

	h3 {
		font-weight: 600;
		font-size: 17px;
	}

	@media screen and (max-width: ${media.mobile}) {
		padding: 50px 24px 50px;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;

		h2 {
			font-size: 20px;
			margin-bottom: 8px;
		}

		p {
			font-size: 16px;
			line-height: 24px;
		}
	}
`;

interface IModalProps {
	width?: string;
	backgroundColor?: string;
}

const ModalInner = styled.div<IModalProps>`
	background: ${({backgroundColor}) => backgroundColor || "#fff"};
	position: relative;
	box-sizing: border-box;
	max-height: 90%;
	max-width: ${({width}) => width || "600px"};
	width: 100%;
	overflow: auto;
	border-radius: ${({theme}) => theme.modal.modal_border_radius};

	@media screen and (max-width: ${media.mobile}) {
		max-width: 100%;
		z-index: 1000;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: calc(100vw - 40px);
	}
`;

const CloseButton = styled(PureButton)`
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;
	width: 40px;
	height: 40px;
	z-index: 10;

	svg {
		vertical-align: middle;
	}
`;

interface IProps {
	children?: React.ReactNode;
	onClose?: (args: React.SyntheticEvent<HTMLButtonElement>) => null | void;
	width?: string;
	backgroundColor?: string;
}

export const Modal: React.FC<IProps> = ({children = null, onClose, ...rest}: IProps) => (
	<ModalWrapper className="backdrop">
		<ModalInner {...rest} className="modal-inner">
			{onClose ? (
				<CloseButton onClick={onClose} className="modal-close" aria-label="close">
					<CloseIcon />
				</CloseButton>
			) : null}
			{children}
			<SponsorBanner />
		</ModalInner>
	</ModalWrapper>
);

export * from "./ErrorModal";
export * from "./LoginModal";
export * from "./ShareModal";
export * from "./BottomModal";
export * from "./ErrorCreateLeagueModal";
export * from "./GotcLeagueConfirmModal";
export * from "./UsernameModal";
export * from "./TermsModal";
