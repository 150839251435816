import React, {Fragment, useEffect, useState} from "react";
import {SSO} from "modules";
import {useDispatch, useSelector} from "react-redux";
import {userFetch, userLogin} from "modules/actions";
import {getIsUserChecked} from "modules/selectors";
import {PagePreloader} from "components";
import {useSearchParams} from "react-router-dom";

interface IProps {
	children: React.ReactNode;
}

export const SessionWeb: React.FC<IProps> = ({children}) => {
	const dispatch = useDispatch();
	const isUserChecked = useSelector(getIsUserChecked);
	const [isCodeChecked, setIsCodeChecked] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		if (isCodeChecked) {
			return;
		}

		setIsCodeChecked(true);

		const code = searchParams.get("code");

		if (code) {
			searchParams.delete("code");
			setSearchParams(searchParams);
			dispatch(
				userLogin({
					token: code,
					redirectUrl: SSO.REDIRECT_URL,
				})
			);
		} else {
			dispatch(userFetch());
		}
	}, [dispatch, isCodeChecked, searchParams, setSearchParams]);

	return isUserChecked ? <Fragment>{children}</Fragment> : <PagePreloader />;
};
