import React, {useState} from "react";
import styled, {css} from "styled-components";
import {ReactComponent as UserIcon} from "assets/images/icons/user.svg";
import {useDispatch, useSelector} from "react-redux";
import {getIsUserLogged, getUserSSOParams} from "modules/selectors";
import {LanguageSelector} from "./LanguageSelector";
import {media} from "assets/css/media";
import {IS_APP, SSO} from "modules";
import {Exist} from "components/Exist";
import {PureButton} from "components/Button";
import {useTranslation} from "react-i18next";
import {userLogout} from "modules/actions";

const Wrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex: 0 1 auto;

	&:before {
		content: "";
		position: absolute;
		right: calc(100% - 2px);
		top: 0;
		width: 60px;
		height: 100%;
		background: linear-gradient(to left, rgb(4, 86, 148), rgba(4, 86, 148, 0.01));
	}

	&.rtl {
		&:before {
			right: auto;
			left: calc(100% - 2px);
			background: linear-gradient(to right, rgb(4, 86, 148), rgba(4, 86, 148, 0.01));
		}
	}

	@media (max-width: ${media.tablet}) {
		&:before {
			display: none;
		}
	}

	@media (max-width: ${media.mobile}) {
		flex: 1;
	}
`;

interface IIconProps {
	$hideMobile?: boolean;
	$hideTablet?: boolean;
}

const getMobileStyles = ({$hideMobile, $hideTablet}: IIconProps) => {
	if ($hideTablet) {
		return css`
			@media (max-width: ${media.large_desktop}) {
				display: none;
			}
		`;
	}
	if ($hideMobile) {
		return css`
			@media (max-width: ${media.mobile}) {
				display: none;
			}
		`;
	}
	return "";
};

const Item = styled.a<IIconProps>`
	margin-inline-end: 16px;
	margin-inline-start: 16px;
	${getMobileStyles};
	@media (max-width: ${media.large_desktop}) {
		margin-inline-end: 8px;
		margin-inline-start: 8px;
	}
`;

const AccountItem = styled.div<IIconProps>`
	position: relative;
	margin-inline-end: 8px;
	margin-inline-start: 8px;
	${getMobileStyles};
	@media (max-width: ${media.large_desktop}) {
		margin-inline-end: 0px;
		margin-inline-start: 0px;
	}
`;

const AccountInner = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
`;

const AccountBlock = styled.div`
	width: 320px;
	position: absolute;
	top: 42px;
	right: 0;
	background-color: #fff;
	border-radius: 5px;
	padding: 16px;
	z-index: 2;

	&.rtl {
		right: -280px;
	}
`;

const AccountIconButton = styled(PureButton)`
	padding: 8px;
	border-radius: 50%;

	svg {
		vertical-align: middle;
	}

	&:hover svg,
	&.active svg {
		fill: #00b8ff;
	}
`;

const AccountDescription = styled.p`
	font-size: 14px;
	color: ${({theme}) => theme.header.header_background};
	line-height: 22px;
	margin-bottom: 32px;
`;

const AccountButton = styled(PureButton)`
	display: inline-block;
	width: 100%;
	background: linear-gradient(111.98deg, #00b8ff 18.07%, #1574c4 90.31%);
	border-radius: 30px;
	font-size: 14px;
	line-height: 22px;
	color: #fff;
	padding: 8px 16px;
	text-align: center;
`;

export const MenuIcons: React.FC = () => {
	const {t, i18n} = useTranslation();
	const dispatch = useDispatch();
	const isLoggedIn = useSelector(getIsUserLogged);
	const parameters = useSelector(getUserSSOParams);
	const [showBlock, setShowBlock] = useState(false);
	const showLogout = isLoggedIn && !IS_APP;

	const toggleShowBlock = () => {
		setShowBlock(!showBlock);
	};

	const handleLogout = () => {
		dispatch(userLogout());
		setShowBlock(false);
	};

	const handleLogin = () => {
		SSO.handleLogin(parameters);
	};

	return (
		<Wrapper className={i18n.dir()}>
			<Item>
				<LanguageSelector />
			</Item>

			<AccountItem>
				<AccountIconButton onClick={toggleShowBlock} className={showBlock ? "active" : ""}>
					<UserIcon />
				</AccountIconButton>

				<Exist when={showBlock}>
					<AccountInner onClick={toggleShowBlock} />
					<AccountBlock className={i18n.dir()}>
						<AccountDescription>{t("header.fifa.account_desc")}</AccountDescription>
						<Exist when={showLogout}>
							<AccountButton onClick={handleLogout}>
								{t("header.fifa.account_button_sign_out")}
							</AccountButton>
						</Exist>
						<Exist when={!isLoggedIn}>
							<AccountButton onClick={handleLogin}>
								{t("header.fifa.account_button_sign_in")}
							</AccountButton>
						</Exist>
					</AccountBlock>
				</Exist>
			</AccountItem>
		</Wrapper>
	);
};
