import React, {useRef, useState} from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {PureButton} from "components/Button";
import {useDispatch, useSelector} from "react-redux";
import {getActiveSubMenu} from "modules/selectors";
import {toggleSubMenu} from "modules/actions";
import {IDropDownLink, MENU_LINKS} from "modules";

const Wrapper = styled.nav`
	position: relative;
	display: flex;
	align-items: center;
	margin-inline-start: 16px;
	overflow-x: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
`;

const SubMenuWrapper = styled.div<{bgc?: string}>`
	background-color: ${({bgc}) => bgc || "#045694"};
	height: 56px;
	padding: 0 40px;
	position: absolute;
	left: 0;
	top: 100%;
	width: 100%;
	display: none;
	align-items: center;
	z-index: 1;
	scroll-snap-type: mandatory;
	overflow: auto;

	&::-webkit-scrollbar {
		display: none;
	}

	&.open {
		display: flex;
	}
`;

const LinkWrapper = styled.a<{colour?: string; hover?: string}>`
	margin-inline-end: 16px;
	margin-inline-start: 16px;
	color: ${({colour}) => colour || "#E4E8F0"};
	font-weight: 500;
	font-size: 14px;
	white-space: nowrap;
	transition: 0.2s color ease-in-out;

	&:hover {
		color: ${({hover}) => hover || "#FFF"};
	}
`;

const Logo = styled.img`
	height: 100%;
	width: 104px;
	object-fit: contain;
	margin-inline-end: 16px;
`;

const MenuLink: React.FC<IDropDownLink> = (link) => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const activeSubMenu = useSelector(getActiveSubMenu);

	const toggleMenu = () => {
		if (link.title === activeSubMenu) {
			dispatch(toggleSubMenu(null));
			return;
		}

		dispatch(toggleSubMenu(link.title));
	};

	if (link.subLinks) {
		return (
			<LinkWrapper
				as={PureButton}
				onClick={toggleMenu}
				colour={link.colour}
				hover={link.hover}>
				{t(link.title)}
			</LinkWrapper>
		);
	}

	return (
		<LinkWrapper href={link.href} colour={link.colour} hover={link.hover}>
			{t(link.title)}
		</LinkWrapper>
	);
};

export const MenuLinks: React.FC = () => (
	<Wrapper>
		{MENU_LINKS.map((link) => (
			<MenuLink key={link.title} {...link} />
		))}
	</Wrapper>
);

export const SubMenuLinks: React.FC = () => {
	const {t} = useTranslation();
	const activeSubMenu = useSelector(getActiveSubMenu);
	const className = activeSubMenu ? "open" : "";
	const parentLink = MENU_LINKS.find((link) => link.title === activeSubMenu);
	const subLinks = parentLink?.subLinks;
	const ref = useRef<HTMLDivElement>(null);
	const [mouseDown, setMouseDown] = useState(false);
	const [startX, setStartX] = useState(0);
	const [scrollLeft, setScrollLeft] = useState(0);

	const onMouseDown = (e: React.MouseEvent) => {
		if (!ref.current) {
			return;
		}
		setMouseDown(true);
		setStartX(e.pageX - ref.current.offsetLeft);
		setScrollLeft(ref.current.scrollLeft);
	};

	const onMouseMove = (e: React.MouseEvent) => {
		if (!mouseDown || !ref.current) {
			return;
		}
		e.preventDefault();
		const x = e.pageX - ref.current?.offsetLeft;
		const scroll = x - startX;
		ref.current.scrollLeft = scrollLeft - scroll;
	};

	const onMouseUp = () => {
		setMouseDown(false);
	};

	if (!subLinks) {
		return null;
	}

	return (
		<SubMenuWrapper
			ref={ref}
			onMouseMove={onMouseMove}
			onMouseUp={onMouseUp}
			onMouseDown={onMouseDown}
			onMouseLeave={onMouseUp}
			className={className}
			bgc={subLinks.bgc}>
			{subLinks.logo && <Logo src={subLinks.logo} alt={t(parentLink.title)} />}
			{subLinks.links.map((link) => (
				<MenuLink
					key={link.title}
					{...link}
					colour={subLinks.colour}
					hover={subLinks.hover}
				/>
			))}
		</SubMenuWrapper>
	);
};
