import {createAction} from "redux-act";
import {ILeague, ILeaguesContests, IRankingFilters, TPrivacy} from "modules/types";
import {ILeagueRankingsResponse, ILeaguesResponse, ILeagueUsersResponse} from "modules/reducers";

export interface IFetchLeaguesPayload {
	offset?: number;
	limit?: number;
	privacy?: TPrivacy;
}

export const fetchLeagues = createAction<IFetchLeaguesPayload>();
export const fetchLeaguesSuccess = createAction<ILeague[]>();
export const fetchLeaguesFailed = createAction<Error["message"]>();

interface ICreateLeagueParameters {
	name: string;
	privacy: TPrivacy;
}

interface IFivesCreateLeagueParameters extends ICreateLeagueParameters {
	startId?: number;
}

export type ICreateLeaguePayload = IFivesCreateLeagueParameters;

export const createLeague = createAction<ICreateLeaguePayload>();
export const createLeagueSuccess = createAction<ILeague>();
export const createLeagueFailed = createAction<Error["message"]>();

export interface IUpdateLeaguePayload {
	leagueId: number;
	name: string;
	privacy?: TPrivacy;
	startId?: number;
}

export const updateLeague = createAction<IUpdateLeaguePayload>();
export const updateLeagueSuccess = createAction<ILeague>();
export const updateLeagueFailed = createAction<Error["message"]>();

export interface IInvitePayload {
	leagueId: number;
	invites: {
		email: string;
	}[];
}

export const inviteToLeague = createAction<IInvitePayload>();
export const inviteToLeagueSuccess = createAction();
export const inviteToLeagueFailed = createAction<Error["message"]>();

export const setLeagueCreated = createAction();
export const clearLeagueCreated = createAction();

export interface IFetchLeaguesForJoinPayload {
	search?: string;
	page?: number;
	limit?: number;
}

export const fetchLeaguesForJoin = createAction<IFetchLeaguesForJoinPayload>();
export const fetchLeaguesForJoinSuccess = createAction<ILeaguesResponse>();
export const fetchLeaguesForJoinFailed = createAction<Error["message"]>();
export const clearLeaguesForJoin = createAction();
export const fetchMoreLeaguesForJoin = createAction();

export interface IJoinLeaguePayload {
	code: string;
}

export const joinLeague = createAction<IJoinLeaguePayload>();
export const joinLeagueSuccess = createAction<ILeague>();
export const joinLeagueFailed = createAction<Error["message"]>();

export const setLeagueJoined = createAction<number>();
export const clearLeagueJoined = createAction();

export interface ILeaveLeaguePayload {
	leagueId: number;
}

export const leaveLeague = createAction<ILeaveLeaguePayload>();
export const leaveLeagueSuccess = createAction<number>();
export const leaveLeagueFailed = createAction<Error["message"]>();

export interface ILeagueRankingsPayload {
	leagueId: number;
	startId?: number;
	page?: number;
	limit?: number;
	country?: string;
	stage?: string;
}

export const fetchLeagueRankings = createAction<ILeagueRankingsPayload>();
export const fetchLeagueRankingsSuccess = createAction<ILeagueRankingsResponse>();
export const fetchLeagueRankingsFailed = createAction<Error["message"]>();
export const fetchMoreLeagueRankings = createAction();
export const clearLeagueRankings = createAction();

export interface ILeagueUsersPayload {
	leagueId: number;
	page?: number;
	limit?: number;
}

export const fetchLeagueUsers = createAction<ILeagueUsersPayload>();
export const fetchLeagueUsersSuccess = createAction<ILeagueUsersResponse>();
export const fetchLeagueUsersFailed = createAction<Error["message"]>();
export const fetchMoreLeagueUsers = createAction();
export const clearLeagueUsers = createAction();

export interface IRemoveUserPayload {
	leagueId: number;
	userId: number;
}

export const removeUser = createAction<IRemoveUserPayload>();
export const removeUserSuccess = createAction<number>();
export const removeUserFailed = createAction<Error["message"]>();

export const openInvitesSentModal = createAction();
export const closeInvitesSentModal = createAction();

export interface IRemoveUserModalPayload {
	leagueId: number;
	userId: number;
	userName: string;
}

export const openRemoveUserModal = createAction<IRemoveUserModalPayload>();
export const closeRemoveUserModal = createAction();

export interface ILeaveLeagueModalPayload {
	leagueId: number;
}

export const openLeaveLeagueModal = createAction<ILeaveLeagueModalPayload>();
export const closeLeaveLeagueModal = createAction();

export const setContestsForLeagues = createAction<ILeaguesContests[]>();

export interface IFetchLeagueForJoinPayload {
	leagueCode: string;
}

export const fetchLeagueForJoin = createAction<IFetchLeagueForJoinPayload>();
export const fetchLeagueForJoinSuccess = createAction<ILeague>();
export const fetchLeagueForJoinFailed = createAction<Error["message"]>();
export const clearLeagueForJoin = createAction();

export const setRankingFilters = createAction<IRankingFilters>();

export const fetchGotcLeague = createAction();
export const fetchGotcLeagueSuccess = createAction<ILeague[]>();
export const fetchGotcLeagueError = createAction<Error["message"]>();
