import React, {Fragment} from "react";

interface IProps {
	when?: boolean;
	children: React.ReactNode;
}

export const Exist: React.FC<IProps> = ({children, when}) => (
	<Fragment>{when ? children : null}</Fragment>
);
