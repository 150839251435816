import React from "react";
import {Table, TableBody, TableCell, TableDivider, TableRow} from "components";
import {IRanking, IRankingFilters} from "modules/types";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {ProjectName, PROJECT_NAME} from "modules";
import {isNumber} from "lodash";

const Wrapper = styled.div`
	position: sticky;
	bottom: 0;
`;

const AvatarWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	margin-right: 10px;
`;

const Avatar = styled.img`
	width: 100%;
`;

const TableCellUserName = styled.td`
	font-size: 14px;
	font-weight: 500;
	line-height: 24px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 50px;
	vertical-align: middle;
	padding: 14px 14px 10px;
`;

interface IProps {
	user: IRanking;
	filters: IRankingFilters;
	className?: string;
}

export const UserTable: React.FC<IProps> = ({user, filters, className}) => {
	const {i18n} = useTranslation();
	const isDailyFantasy = PROJECT_NAME === ProjectName.FantasyDaily;

	const getRank = (ranking: IRanking) => {
		const userRank = filters.startId ? ranking.rank : ranking.overallRank;
		return userRank ? userRank : "-";
	};

	const getPoints = (ranking: IRanking) => {
		const userPoints = filters.startId ? ranking.points : ranking.overallPoints;
		return isNumber(userPoints) ? userPoints : "-";
	};

	return (
		<Wrapper>
			<Table>
				<colgroup>
					<col style={{width: "20%"}} />
					<col style={{width: "50%"}} />
					<col style={{width: "30%"}} />
				</colgroup>
				<TableBody>
					<TableRow highlight={true} className={className}>
						<TableCell textAlign="center">
							{getRank(user)}
							<TableDivider className={i18n.dir()} />
						</TableCell>
						<TableCellUserName>
							<AvatarWrapper>
								<Avatar src={user.gamezoneAvatar} alt={`${user.gamezoneLevel}`} />
							</AvatarWrapper>
							{isDailyFantasy ? user.userName : user.teamName}
						</TableCellUserName>
						<TableCell textAlign="right">{getPoints(user)}</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</Wrapper>
	);
};
