import React from "react";
import {HOCAccordion} from "components/HOCAccordion";
import {PureButton} from "components/Button";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {changeRouteLanguage, LANGUAGES} from "modules";

const AccordionButton = styled(PureButton)`
	padding: 14px 0;
	display: block;
	color: #ffffff;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 160%;
`;

const LangButton = styled(AccordionButton)`
	padding-inline-start: 20px;
`;

export const MobileLanguageSelector: React.FC = () => {
	const {t, i18n} = useTranslation();

	const changeLanguage = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const language = e.currentTarget.dataset.language;

		if (!language) {
			return;
		}

		const oldLanguage = i18n.language;
		void i18n.changeLanguage(language);
		changeRouteLanguage(oldLanguage, language);
	};
	return (
		<HOCAccordion
			fullWidth
			item={<AccordionButton>{t("subheader.fifa.language")}</AccordionButton>}>
			{LANGUAGES.map((language) => (
				<LangButton
					key={language.code}
					data-language={language.code}
					onClick={changeLanguage}>
					{language.name}
				</LangButton>
			))}
		</HOCAccordion>
	);
};
