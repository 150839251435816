import React from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import hyundaiLogo from "assets/images/logos/hyundai.svg";
import {LANGUAGE_PATH} from "modules";

const HyundaiWrapper = styled.div`
	background-color: #36f597;
	height: 40px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
`;

const HyundaiLabel = styled.p`
	font-weight: 500;
	font-size: 12px;
	color: #002c5f;
`;

export const HyundaiBanner: React.FC = () => {
	const {t} = useTranslation(LANGUAGE_PATH);

	return (
		<HyundaiWrapper>
			<HyundaiLabel>{t("sponsor_bar.header.brought", "Official Partner")}</HyundaiLabel>
			<img src={hyundaiLogo} alt="Hyundai" />
		</HyundaiWrapper>
	);
};
