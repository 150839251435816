import React from "react";
import styled, {css} from "styled-components";
import {PureButton} from "components/Button";
import {ReactComponent as ShareIcon} from "assets/images/icons/share.svg";
import {useDispatch} from "react-redux";
import {shareNavigator, ShareNet, ShareType} from "modules";
import {media} from "assets/css/media";
import {openShareModal, saveShare} from "modules/actions";
import {ISaveShareParameters, IShareParameters} from "modules/types";
import {useMediaQuery} from "@mui/material";

interface IWrapperProps {
	$noBackground?: boolean;
}

const getStyles = ({$noBackground}: IWrapperProps) => {
	if ($noBackground) {
		return;
	}

	return css`
		background: ${({theme}) => theme.share.iconColor || theme.colors.primary};
		svg {
			width: 13px;
			height: 14px;
			fill: #fff;
		}
	`;
};

export const Wrapper = styled(PureButton)<IWrapperProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	width: 40px;
	border-radius: 50%;
	cursor: pointer;
	${getStyles}
`;

interface IProps {
	shareParameters: IShareParameters;
	saveShareParameters: ISaveShareParameters;
	noBackground?: boolean;
}

export const ShareButton: React.FC<IProps> = ({
	shareParameters,
	saveShareParameters,
	noBackground,
}) => {
	const dispatch = useDispatch();
	const isMobile = useMediaQuery(`(max-width: ${media.mobile})`);
	const isNavigatorAccessible = "share" in navigator && isMobile;

	const handleShare = () => {
		if (isNavigatorAccessible) {
			dispatch(
				saveShare({
					...saveShareParameters,
					type: ShareType.Native,
				})
			);

			void shareNavigator({
				...shareParameters,
				sc: ShareNet.Twitter,
			});
			return;
		}

		dispatch(
			openShareModal({
				shareParameters,
				saveShareParameters,
			})
		);
	};

	return (
		<Wrapper onClick={handleShare} $noBackground={noBackground} className={"share-button"}>
			<ShareIcon />
		</Wrapper>
	);
};
