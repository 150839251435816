import {createAction} from "redux-act";
import {IPlayerPosition, TournamentStatus} from "modules";
import {PlayerStatus} from "apps/GOALSCORER/modules/enums";

export interface IGameDataPayload {
	contestId: number;
}

export interface ITournament {
	id: number;
	feedId: number | string;
	competitionId: number;
	// contestId: number;
	venueId: number;
	homeSquadId: number;
	awaySquadId: number;
	startDate: string;
	status: TournamentStatus;
}
export interface ISquad {
	id: number;
	feedId: number | string;
	countryId: number;
	competitionIds: number[];
	name: string;
	abbreviation: string;
	standing: {
		position: number;
	} | null;
}

export interface IStats {
	avgGoals: number;
	avgPoints: number;
	contestGoals: number;
	contestPoints: number;
	contestPercentageSelected: number;
	seasonGoals: number;
	totalGoals: number;
	totalPoints: number;
	tournamentsGoals: number[];
}
export interface IPlayer {
	id: number;
	feedId: number | string;
	countryId: number;
	competitionIds: number[];
	squadId: number;
	firstName: string;
	lastName: string;
	shortName: string;
	position: IPlayerPosition;
	isLocked: boolean;
	stats: IStats;
	status: PlayerStatus;
}

export interface IPlayerPools {
	pool1: number[];
	pool2: number[];
	pool3: number[];
	pool4: number[];
	pool5: number[];
}

export interface IGameDataResponse {
	tournaments: ITournament[];
	squads: ISquad[];
	players: IPlayer[];
	pools: IPlayerPools;
}

export const fetchGameData = createAction<IGameDataPayload>();
export const fetchGameDataSuccess = createAction<IGameDataResponse>();
export const fetchGameDataError = createAction<Error["message"]>();
