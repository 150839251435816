import React, {useEffect, useRef, useState} from "react";
import {
	Exist,
	Preloader,
	Table,
	TableBody,
	TableCell,
	TableDivider,
	TableHeader,
	TableHeaderCell,
	TableRow,
} from "components";
import {useTranslation} from "react-i18next";
import {IRanking, IRankingFilters} from "modules/types";
import styled from "styled-components";
import {ProjectName, PROJECT_NAME, RequestState} from "modules";
import {isEmpty, isNumber} from "lodash";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {getIsRoundComplete, getLastCompletedContest, hasScoringStarted} from "modules/selectors";
import ReactDOM from "react-dom";
import {media} from "assets/css/media";

const ButtonLink = styled(Link)<{$disabled: boolean}>`
	cursor: ${({$disabled}) => ($disabled ? "initial" : "pointer")};
	pointer-events: ${({$disabled}) => ($disabled ? "none" : "initial")};
	text-decoration: ${({$disabled}) => ($disabled ? "none" : "underline")};
`;

const StickyHeader = styled.div<{left: number; width: number}>`
	position: fixed;
	top: 90px;

	@media screen and (min-width: ${media.tablet}) {
		top: 130px;
	}

	@media screen and (min-width: ${media.large_desktop}) {
		top: 178px;
	}

	left: ${({left}) => left}px;
	right: 0;
	width: ${({width}) => width}px;
	background-color: ${({theme}) => theme.leagues.leagues_head_background || theme.colors.primary};
`;
export const TableCellUserName = styled.td`
	font-size: 14px;
	font-weight: 500;
	line-height: 24px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 50px;
	vertical-align: middle;
	padding: 14px 14px 10px;
`;

const AvatarWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	margin-right: 10px;
`;

const Avatar = styled.img`
	width: 100%;
`;

interface IProps {
	rankings: IRanking[];
	user: IRanking | null;
	requestState: RequestState;
	filters: IRankingFilters;
	className?: string;
	isGotc?: boolean;
}

interface IStickyHeaderPosition {
	width: number;
	left: number;
}
const defaultStickyHeaderPosition: IStickyHeaderPosition = {
	left: 0,
	width: 0,
};

export const RankingsTable: React.FC<IProps> = ({
	rankings,
	user,
	requestState,
	filters,
	className,
	isGotc = false,
}) => {
	const header = useRef<HTMLTableSectionElement>(null);
	const table = useRef<HTMLTableElement>(null);
	const [isShowStickyHeader, setIsShowStickyHeader] = useState(false);
	const [stickyHeaderPosition, setStickyHeaderPosition] = useState<IStickyHeaderPosition>(
		defaultStickyHeaderPosition
	);
	const {t, i18n} = useTranslation();
	const isLoading = requestState === RequestState.Requested;
	const isLoaded = requestState === RequestState.Received;
	const noRankingsAvailable = isEmpty(rankings) && isLoaded;
	const isBracketPredictor = PROJECT_NAME === ProjectName.BracketsPredictor;
	const isFantasyClassic = PROJECT_NAME === ProjectName.Fantasy;
	const isFantasyDaily = PROJECT_NAME === ProjectName.FantasyDaily;
	const isUserProject = [ProjectName.FantasyDaily, ProjectName.Goalscorer].includes(
		PROJECT_NAME as ProjectName
	);
	const isDefaultProject = ![
		ProjectName.BracketsPredictor,
		ProjectName.Fantasy,
		ProjectName.FantasyDaily,
	].includes(PROJECT_NAME as ProjectName);
	const isScoring = useSelector(hasScoringStarted);
	const lastCompletedContest = useSelector(getLastCompletedContest);
	const roundId = filters.startId || lastCompletedContest?.id || 0;
	const isRoundComplete = useSelector(getIsRoundComplete)(roundId);

	const getRank = (ranking: IRanking) => {
		const userRank = filters.startId ? ranking.rank : ranking.overallRank;
		return userRank ? userRank : "-";
	};

	const getPoints = (ranking: IRanking) => {
		const userPoints = filters.startId ? ranking.points : ranking.overallPoints;
		return isNumber(userPoints) ? userPoints : "-";
	};

	useEffect(() => {
		document.body.onscroll = (_) => {
			const rect = header?.current?.getBoundingClientRect();
			const tableRect = table?.current?.getBoundingClientRect();
			const hasScrolledToEndOfTable = tableRect && tableRect.height + tableRect.top - 200 < 0;
			const hasScrolledPastHeader = rect && rect.top < 120;

			if ((hasScrolledPastHeader && hasScrolledToEndOfTable) || !hasScrolledPastHeader) {
				setIsShowStickyHeader(false);
				setStickyHeaderPosition(defaultStickyHeaderPosition);
			} else if (hasScrolledPastHeader) {
				setIsShowStickyHeader(true);
				setStickyHeaderPosition({width: rect.width, left: rect.x});
			}
		};
	}, []);

	const renderHeader = (ref?: React.Ref<HTMLTableSectionElement>) => (
		<React.Fragment>
			<colgroup>
				<col style={{width: "20%"}} />
				<col style={{width: "50%"}} />
				<col style={{width: "30%"}} />
			</colgroup>
			<TableHeader isGotc={isGotc} ref={ref} className={className}>
				<TableRow className="sticky">
					<TableHeaderCell textAlign="center">
						{t("rankings.leaderboard.rank")}
						<TableDivider className={`header ${i18n.dir()}`} />
					</TableHeaderCell>
					<TableHeaderCell>{t("rankings.leaderboard.displayname")}</TableHeaderCell>
					<TableHeaderCell textAlign="right">
						{t("rankings.leaderboard.points")}
					</TableHeaderCell>
				</TableRow>
			</TableHeader>
		</React.Fragment>
	);

	return (
		<React.Fragment>
			<Exist when={isShowStickyHeader}>
				{ReactDOM.createPortal(
					<StickyHeader {...stickyHeaderPosition}>
						<Table>{renderHeader()}</Table>
					</StickyHeader>,
					document.getElementById("root")!
				)}
			</Exist>
			<Table ref={table}>
				{renderHeader(header)}
				<TableBody>
					{isLoading ? (
						<TableRow>
							<TableCell textAlign="center" colSpan={3}>
								<Preloader />
							</TableCell>
						</TableRow>
					) : noRankingsAvailable ? (
						<TableRow>
							<TableCell textAlign="center" colSpan={3}>
								{t("rankings.leaderboard.empty")}
							</TableCell>
						</TableRow>
					) : (
						rankings.map((ranking, index) => {
							const isUser = isUserProject
								? ranking.userId === user?.userId
								: ranking.teamId === user?.teamId;
							const name = isUserProject ? ranking.userName : ranking.teamName;

							return (
								<TableRow key={index} highlight={isUser} className={className}>
									<TableCell textAlign="center">
										{getRank(ranking)}
										<TableDivider className={i18n.dir()} highlight={isUser} />
									</TableCell>
									<TableCellUserName>
										<AvatarWrapper>
											<Avatar
												src={ranking.gamezoneAvatar}
												alt={`${ranking.gamezoneLevel}`}
											/>
										</AvatarWrapper>
										<Exist when={isBracketPredictor}>
											<ButtonLink
												to={`/another-brackets/${ranking?.teamId}`}
												$disabled={!isScoring}>
												{ranking.teamName}
											</ButtonLink>
										</Exist>
										<Exist when={isFantasyClassic}>
											<ButtonLink
												to={`/public-team/${ranking?.teamId}/${roundId}`}
												$disabled={!isRoundComplete}>
												{ranking.teamName}
											</ButtonLink>
										</Exist>
										<Exist when={isFantasyDaily}>
											<ButtonLink
												to={`/public-team/${String(
													ranking?.userId
												)}/${roundId}?username=${ranking.userName}`}
												$disabled={!isRoundComplete}>
												{ranking.userName}
											</ButtonLink>
										</Exist>
										<Exist when={isDefaultProject}>{name}</Exist>
									</TableCellUserName>
									<TableCell textAlign="right">{getPoints(ranking)}</TableCell>
								</TableRow>
							);
						})
					)}
				</TableBody>
			</Table>
		</React.Fragment>
	);
};
