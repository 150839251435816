import {ILanding} from "modules/types";
import {LandingCartType} from "modules/enums";

import backgroundFantasyClassic from "apps/FANTASY_CLASSIC/assets/images/landing/background.jpg";
import backgroundFantasyClassicMobile from "apps/FANTASY_CLASSIC/assets/images/landing/background_mobile.svg";
import backgroundFantasyDaily from "apps/FANTASY_DAILY/assets/images/landing/lp_background-UNCOMPRESSED.jpg";
import backgroundFantasyDailyMobile from "apps/FANTASY_DAILY/assets/images/landing/lp_background_mobile-UNCOMPRESSED.jpg";

import fantasyDailyLandingIcon1 from "apps/FANTASY_DAILY/assets/images/landing/fantasyDailyLandingIcon1.svg";
import fantasyDailyLandingIcon2 from "apps/FANTASY_DAILY/assets/images/landing/fantasyDailyLandingIcon2.svg";
import fantasyDailyLandingIcon3 from "apps/FANTASY_DAILY/assets/images/landing/fantasyDailyLandingIcon3.svg";

import backgroundBracketPredictor from "apps/BRACKET_PREDICTOR/assets/images/landing/lp_background-UNCOMPRESSED.jpg";
import backgroundBracketPredictorMobile from "apps/BRACKET_PREDICTOR/assets/images/landing/lp_background_mobile-UNCOMPRESSED.jpg";
import bracketLandigIcon1 from "apps/BRACKET_PREDICTOR/assets/images/landing/bracketLandigIcon1.svg";
import bracketLandigIcon2 from "apps/BRACKET_PREDICTOR/assets/images/landing/bracketLandigIcon2.svg";
import bracketLandigIcon3 from "apps/BRACKET_PREDICTOR/assets/images/landing/bracketLandigIcon3.svg";
import fantasyClassicLanding1 from "apps/FANTASY_CLASSIC/assets/images/landing/1.svg";
import fantasyClassicLanding2 from "apps/FANTASY_CLASSIC/assets/images/landing/2.svg";
import fantasyClassicLanding3 from "apps/FANTASY_CLASSIC/assets/images/landing/3.svg";

import backgroundMatchPredictor from "apps/MATCH_PREDICTOR/assets/images/landing/lp_background-UNCOMPRESSED.jpg";
import backgroundMatchPredictorMobile from "apps/MATCH_PREDICTOR/assets/images/landing/lp_background_mobile-UNCOMPRESSED.jpg";
import matchPredictorIcon1 from "apps/MATCH_PREDICTOR/assets/images/landing/cell_icon_1.svg";
import matchPredictorIcon2 from "apps/MATCH_PREDICTOR/assets/images/landing/cell_icon_2.svg";
import matchPredictorIcon3 from "apps/MATCH_PREDICTOR/assets/images/landing/cell_icon_3.svg";

import backgroundWhoAmI from "apps/WHOAMI/assets/images/landing/lp_background.svg";
import backgroundWhoAmIMobile from "apps/WHOAMI/assets/images/landing/lp_background_mobile.svg";
import whoAmILandingIcon1 from "apps/WHOAMI/assets/images/landing/WhoAmILandingIcon1.svg";
import whoAmILandingIcon2 from "apps/WHOAMI/assets/images/landing/WhoAmILandingIcon2.svg";
import whoAmILandingIcon3 from "apps/WHOAMI/assets/images/landing/WhoAmILandingIcon3.svg";

import backgroundPotm from "apps/POTM/assets/images/landing/lp_background.svg";
import backgroundPotmMobile from "apps/POTM/assets/images/landing/lp_background_mobile.svg";
import potmLandingIcon1 from "apps/POTM/assets/images/landing/potmIcon1.svg";
import potmLandingIcon2 from "apps/POTM/assets/images/landing/potmIcon2.svg";
import potmLandingIcon3 from "apps/POTM/assets/images/landing/potmIcon3.svg";

import gottLandingIcon1 from "apps/GOTT/assets/images/landing/gottIcon1.svg";
import gottLandingIcon2 from "apps/GOTT/assets/images/landing/gottIcon2.svg";
import gottLandingIcon3 from "apps/GOTT/assets/images/landing/gottIcon3.svg";

const prizesLink = "/help/prizes";
const rulesLink = "/help/rules";

export const FANTASY_CLASSIC_LANDING: ILanding = {
	ns: "fantasy_classic",
	background: backgroundFantasyClassic,
	backgroundMobile: backgroundFantasyClassicMobile,
	backgroundColor: "#03cfb8",
	buttonBackground: "#550065",
	buttonColor: "#FFF",
	buttonLink: "/team",
	color: "#550065",
	items: [
		{
			icon: fantasyClassicLanding1,
			link: "/team",
		},
		{
			icon: fantasyClassicLanding2,
			link: "/team",
		},
		{
			icon: fantasyClassicLanding3,
			link: "/team",
			buttonType: LandingCartType.Login,
		},
	],
};

export const FANTASY_DAILY_LANDING: ILanding = {
	ns: "fantasy_daily",
	itemsFontFamily: `"Poppins", sans-serif`,
	background: backgroundFantasyDaily,
	backgroundMobile: backgroundFantasyDailyMobile,
	backgroundColor: "#7CE0D3",
	buttonBackground: "#FFBF00",
	buttonColor: "#8A1538",
	buttonLink: "/team",
	color: "#FFF",
	items: [
		{
			icon: fantasyDailyLandingIcon1,
			link: "/team",
		},
		{
			icon: fantasyDailyLandingIcon2,
			link: "/team",
		},
		{
			icon: fantasyDailyLandingIcon3,
			link: "/team",
		},
	],
};

export const MATCH_PREDICTOR_LANDING: ILanding = {
	ns: "match_predictor",
	background: backgroundMatchPredictor,
	backgroundMobile: backgroundMatchPredictorMobile,
	backgroundColor: "#FFA02F",
	buttonBackground: "#00CFB7",
	buttonColor: "#FFF",
	buttonLink: "/match",
	color: "#2B5B6C",
	items: [
		{
			icon: matchPredictorIcon1,
			link: "/match",
		},
		{
			icon: matchPredictorIcon2,
			link: "/leagues",
		},
		{
			icon: matchPredictorIcon3,
			link: "/rankings",
		},
	],
};

export const BRACKET_PREDICTOR_LANDING: ILanding = {
	ns: "bracket_predictor",
	background: backgroundBracketPredictor,
	backgroundMobile: backgroundBracketPredictorMobile,
	backgroundColor: "#2b5b6c",
	buttonBackground: "#00CFB7",
	buttonColor: "#FFF",
	buttonLink: "/brackets",
	color: "#2B5B6C",
	items: [
		{
			icon: bracketLandigIcon1,
			link: "/brackets",
		},
		{
			icon: bracketLandigIcon2,
			link: "/brackets",
		},
		{
			icon: bracketLandigIcon3,
			link: prizesLink,
		},
	],
};

export const POTM_LANDING: ILanding = {
	ns: "player_of_the_match_vote",
	background: backgroundPotm,
	backgroundMobile: backgroundPotmMobile,
	backgroundColor: "#091D49",
	buttonBackground: "#F3C246",
	buttonColor: "#091D49",
	buttonLink: "/",
	color: "#FFF",
	items: [
		{
			icon: potmLandingIcon1,
			link: "/",
		},
		{
			icon: potmLandingIcon2,
			link: prizesLink,
		},
		{
			icon: potmLandingIcon3,
			link: rulesLink,
		},
	],
};

export const WHOAMI_LANDING: ILanding = {
	ns: "whoami",
	background: backgroundWhoAmI,
	backgroundMobile: backgroundWhoAmIMobile,
	backgroundColor: "#8A1538",
	buttonBackground: "#FF004C",
	buttonColor: "#FFF",
	buttonLink: "/game",
	color: "#FFF",
	items: [
		{
			icon: whoAmILandingIcon1,
			link: "/game",
		},
		{
			icon: whoAmILandingIcon2,
			link: prizesLink,
		},
		{
			icon: whoAmILandingIcon3,
			link: rulesLink,
		},
	],
};

export const GOTT_LANDING: ILanding = {
	ns: "goal_of_the_tournament",
	background: "#002C5F",
	backgroundMobile: "#002C5F",
	backgroundColor: "#F1F0D8",
	buttonBackground: "#36F597",
	buttonColor: "#020F2A",
	buttonLink: "/",
	color: "#FFF",
	items: [
		{
			icon: gottLandingIcon1,
			link: "/",
		},
		{
			icon: gottLandingIcon2,
			link: prizesLink,
		},
		{
			icon: gottLandingIcon3,
			link: rulesLink,
		},
	],
};

export const LANDING: {
	[key: string]: ILanding | null;
} = {
	TRIVIA: null,
	FANTASY_CLASSIC: FANTASY_CLASSIC_LANDING,
	FANTASY_DAILY: FANTASY_DAILY_LANDING,
	BRACKET_PREDICTOR: BRACKET_PREDICTOR_LANDING,
	CONTENT_BRACKET: null,
	FIVES: null,
	HYUNDAI_QUIZ: null,
	MATCH_PREDICTOR: MATCH_PREDICTOR_LANDING,
	POTM: POTM_LANDING,
	WHOAMI: WHOAMI_LANDING,
	GOTT: GOTT_LANDING,
};
