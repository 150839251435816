import React, {createContext, useContext, useEffect} from "react";
import styled from "styled-components";
import {Container, Exist, HOCLayout, LeagueSponsorBanner, PagePreloader} from "components";
import {fetchLeagues} from "modules/actions";
import {LeagueFilters, LeagueSubPageWrapper, PageTitle} from "pages/leagues/common";
import {useDispatch, useSelector} from "react-redux";
import {Navigate, Outlet, useParams} from "react-router-dom";
import {chain} from "lodash";
import {getLeagueById, getLeaguesRequestState} from "modules/selectors";
import {ILeague} from "modules/types";
import {RequestState, useFetchContests, PROJECT_NAME} from "modules";
import {Grid} from "@mui/material";
import {media} from "assets/css/media";

const TitleWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 16px;

	@media screen and (max-width: ${media.mobile}) {
		align-items: flex-start;
		flex-direction: column;
		gap: 24px;
	}
`;

const LeagueContext = createContext<ILeague | null>(null);

export const useLeagueContext = () => {
	const leagueContext = useContext(LeagueContext);
	if (!leagueContext) {
		throw new Error("No LeagueContext.Provider found when calling useLeagueContext.");
	}
	return leagueContext;
};

export const LeagueOutlet: React.FC = () => {
	const dispatch = useDispatch();
	const params = useParams();
	const leagueId = chain(params).get("id", 0).toNumber().value();
	const league = useSelector(getLeagueById)(leagueId);
	const requestState = useSelector(getLeaguesRequestState);
	const isIdle = requestState.leagues === RequestState.IDLE;
	const isLoading =
		requestState.leagues === RequestState.IDLE ||
		requestState.leagues === RequestState.Requested;

	useFetchContests();

	useEffect(() => {
		if (isIdle) {
			dispatch(fetchLeagues({}));
		}
	}, [dispatch, isIdle]);

	if (isLoading) {
		return <PagePreloader />;
	}

	if (!league) {
		return <Navigate to="/leagues" />;
	}

	return (
		<HOCLayout>
			<LeagueSponsorBanner leagueClass={league.class} />
			<LeagueSubPageWrapper className={league.class}>
				<Container>
					<Grid container spacing={2}>
						<Grid item xs={12} md={8}>
							<TitleWrapper>
								<PageTitle>{league.name}</PageTitle>
								<Exist when={PROJECT_NAME !== "BRACKET_PREDICTOR"}>
									<LeagueFilters className={league.class} />
								</Exist>
							</TitleWrapper>
						</Grid>
					</Grid>
				</Container>
				<LeagueContext.Provider value={league}>
					<Outlet />
				</LeagueContext.Provider>
			</LeagueSubPageWrapper>
		</HOCLayout>
	);
};
