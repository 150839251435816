import {createAction} from "redux-act";
import {IRankingsResponse} from "modules/reducers";
import {IRankingFilters} from "modules/types";

export interface IFetchLeaderboardPayload {
	page?: number;
	limit?: number;
	startId?: number;
	stage?: string;
	type?: string;
	country?: string;
}

export const fetchLeaderboard = createAction<IFetchLeaderboardPayload>();
export const fetchLeaderboardSuccess = createAction<IRankingsResponse>();
export const fetchLeaderboardFailed = createAction<Error["message"]>();
export const fetchMoreLeaderboard = createAction();
export const clearLeaderboard = createAction();

export const setLeaderboardFilters = createAction<IRankingFilters>();
