import React from "react";
import {ProjectName, PROJECT_NAME} from "modules";
import {useMatch} from "react-router-dom";
import {WhoAmIBanner, WhoAmIGameBanner} from "./WhoAmIBanner";
import {every} from "lodash";
import {MatchPredictorBanner} from "components/TopBanner/MatchPredictorBanner";
import {useMediaQuery} from "@mui/material";
import {media} from "assets/css/media";
import {FancestryBanner} from "./FancestryBanner";
import {HyundaiBanner} from "./HyundaiBanner";

export const TopBanner: React.FC = () => {
	const isMobile = useMediaQuery(`(max-width: ${media.mobile})`);
	const isLeaderboardPage = useMatch("/rankings");
	const isGamePage = useMatch("/game");
	const isLandingPage = useMatch("/");
	const isMatchPageAndDesktop = useMatch("/match") && !isMobile;

	if (every([PROJECT_NAME === ProjectName.WhoAmI, isLeaderboardPage])) {
		return <WhoAmIBanner />;
	}

	if (every([PROJECT_NAME === ProjectName.WhoAmI, isGamePage])) {
		return <WhoAmIGameBanner />;
	}

	if (
		every([PROJECT_NAME === ProjectName.MatchPredictor, !isLandingPage, !isMatchPageAndDesktop])
	) {
		return <MatchPredictorBanner />;
	}

	if (every([PROJECT_NAME === ProjectName.FancestryQuiz])) {
		return <FancestryBanner />;
	}

	if (every([PROJECT_NAME === ProjectName.Gott])) {
		return <HyundaiBanner />;
	}

	return null;
};

export * from "./MatchPredictorBanner";
export * from "./FantasyDailyBanner";
