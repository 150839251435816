import React, {Fragment, useEffect, useRef} from "react";
import {useSelector} from "react-redux";
import {getIsAdditionalDataRequired, getIsUserLogged} from "modules/selectors";
import {useNavigate} from "react-router-dom";
import PersistStorage from "modules/utils/PersistStorage";

interface IProps {
	children: React.ReactNode;
}

export const HOCAuthRedirect: React.FC<IProps> = ({children}) => {
	const navigate = useRef(useNavigate());
	const isLoggedIn = useSelector(getIsUserLogged);
	const isAdditionalDataRequired = useSelector(getIsAdditionalDataRequired);

	useEffect(() => {
		const redirectPath = PersistStorage.GET("redirect_path");
		if (isLoggedIn && redirectPath) {
			navigate.current(redirectPath);
			PersistStorage.REMOVE("redirect_path");
		}
	}, [navigate, isLoggedIn]);

	useEffect(() => {
		if (isAdditionalDataRequired) {
			navigate.current("/register");
		}
	}, [navigate, isAdditionalDataRequired]);

	return <Fragment>{children}</Fragment>;
};
