import React, {Fragment} from "react";
import styled from "styled-components";
import {ReactComponent as TwitterIcon} from "assets/images/icons/twitter.svg";
import {ReactComponent as FacebookIcon} from "assets/images/icons/facebook_2.svg";
import {ReactComponent as ShareIcon} from "assets/images/icons/shareFilled.svg";
import {PrimaryButton} from "components";
import {useMediaQuery} from "@mui/material";
import {media} from "assets/css/media";
import {LANGUAGE_PATH, share, shareNavigator, ShareNet, ShareSource} from "modules";
import {IShareParameters} from "modules/types";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 12px;

	svg {
		height: 24px;
		width: 24px;
	}

	@media (max-width: ${media.mobile}) {
		svg {
			height: 20px;
			width: 20px;
		}
	}
`;

const StyledPrimaryButton = styled(PrimaryButton)`
	width: 100%;
	max-width: 335px;
	gap: 8px;
`;

const IconButton = styled(PrimaryButton)`
	height: 40px;
	width: 40px;
	padding: 0 4px;
`;

interface ILeagueSocialShare {
	code: string;
}

export const LeagueSocialShare: React.FC<ILeagueSocialShare> = ({code}) => {
	const {t, i18n} = useTranslation(LANGUAGE_PATH);
	const isMobile = useMediaQuery(`(max-width: ${media.mobile})`);
	const isNavigatorAccessible = "share" in navigator && isMobile;

	const shareParameters: IShareParameters = {
		message: t("social.share.league_page", {"league pin": code}),
		leagueCode: code,
		type: ShareSource.League,
	};

	const handleNativeShare = () => {
		void shareNavigator({
			...shareParameters,
			sc: ShareNet.Native,
			locale: i18n.language,
		});
	};

	const handleShare = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const {sc} = e.currentTarget.dataset;

		share({
			...shareParameters,
			sc: sc as ShareNet,
			locale: i18n.language,
		});
	};

	return (
		<Wrapper>
			{isNavigatorAccessible ? (
				<StyledPrimaryButton onClick={handleNativeShare}>
					{t("leagues.createleague.share")}
					<ShareIcon />
				</StyledPrimaryButton>
			) : (
				<Fragment>
					<IconButton onClick={handleShare} data-sc={ShareNet.Facebook}>
						<FacebookIcon />
						{t("leagues.createleague.facebook")}
					</IconButton>
					<IconButton onClick={handleShare} data-sc={ShareNet.Twitter}>
						<TwitterIcon />
						{t("leagues.createleague.twitter")}
					</IconButton>
				</Fragment>
			)}
		</Wrapper>
	);
};
