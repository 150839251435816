import React from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getIsNotificationBarOpen} from "modules/selectors";
import {closeNotificationBar} from "modules/actions";
import {media} from "assets/css/media";
import {PureButton} from "components/Button";
import {ReactComponent as Close} from "assets/images/icons/close.svg";
import {LeagueClass} from "modules";
import commsBankLogo from "assets/images/logos/commsBankModalLogo.svg";

const FantasyWrapper = styled.div`
	background-color: #e9e9e9;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 8px;
	padding: 16px 60px;

	@media (max-width: ${media.mobile}) {
		padding: 16px;
	}
`;

const FantasyLabel = styled.p`
	font-weight: 500;
	font-size: 12px;
`;

const LeagueBannerWrapper = styled.div`
	width: 100%;
	background-color: #000;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
`;

const LeagueBannerText = styled.p`
	font-size: 12px;
	color: #fff;
`;

export const FantasyDailyBanner: React.FC = () => {
	const {t} = useTranslation("fantasy_daily");
	const dispatch = useDispatch();
	const isOpen = useSelector(getIsNotificationBarOpen);

	const closeNotification = () => {
		dispatch(closeNotificationBar());
	};

	if (!isOpen) {
		return null;
	}

	return (
		<FantasyWrapper>
			<FantasyLabel>{t("team.notication_bar.body")}</FantasyLabel>
			<PureButton>
				<Close onClick={closeNotification} />
			</PureButton>
		</FantasyWrapper>
	);
};

interface IProps {
	leagueClass: LeagueClass;
}

export const LeagueSponsorBanner: React.FC<IProps> = ({leagueClass}) => {
	const {t} = useTranslation("fantasy_daily");

	if (leagueClass === LeagueClass.CommsBank) {
		return (
			<LeagueBannerWrapper>
				<LeagueBannerText>{t("banner.sponsor.text", "Proud Partner")}</LeagueBannerText>
				<img src={commsBankLogo} alt="CommonwealthBank" />
			</LeagueBannerWrapper>
		);
	}

	return null;
};
