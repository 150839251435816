import {createSelector} from "reselect";
import {IStore} from "modules/types/store";
import {BracketRoundType, RoundStatus, Stage, TournamentStatus} from "modules/enums";
import {
	chain,
	find,
	first,
	flatMap,
	flattenDeep,
	get,
	keyBy,
	last,
	map,
	memoize,
	sortBy,
} from "lodash";
import {FantasyClassicRoundType} from "modules/constants";
import {ITournament} from "modules/reducers";

export const getRoundsState = (store: IStore) => store.rounds;

export const getRounds = createSelector(getRoundsState, ({rounds}) => rounds);

export const getRoundById = createSelector(getRounds, (rounds) => keyBy(rounds, "id"));

export const getSortedRounds = createSelector(getRounds, (rounds) => sortBy(rounds, "id"));

export const getTournaments = createSelector(getRounds, (rounds) =>
	flattenDeep(map(rounds, (round) => round.tournaments))
);

export const getTournamentById = createSelector(getTournaments, (tournaments) =>
	memoize((id: number | null = 0) => tournaments.find((tour) => tour.id === id))
);

export const getRoundsRequestState = createSelector(
	getRoundsState,
	({requestState}) => requestState
);

export const getGameView = createSelector(getRoundsState, ({gameView}) => gameView);

export const getActualRound = createSelector(
	getRounds,
	(rounds) =>
		rounds.find(({status}) => [RoundStatus.Active, RoundStatus.Scheduled].includes(status)) ||
		last(rounds)
);

export const getActualRoundId = createSelector(getActualRound, (round) => round?.id);

export const getActualRoundStage = createSelector(
	getActualRoundId,
	(id) => FantasyClassicRoundType[id || 1]
);

export const getCompleteOrActiveRounds = createSelector(getRounds, (rounds) =>
	rounds.filter(({status}) => [RoundStatus.Active, RoundStatus.Complete].includes(status))
);

export const getSelectedRoundState = createSelector(getRoundsState, (state) => state.selectedRound);

export const getSelectedRoundId = createSelector(
	getActualRound,
	getSelectedRoundState,
	(actualRound, reducerRound) => reducerRound || actualRound?.id
);

export const getSelectedRound = createSelector(getRounds, getSelectedRoundId, (rounds, id) =>
	rounds.find((round) => round.id === id)
);

export const getSelectedRoundFixtures = createSelector(getSelectedRound, (selectedRound) =>
	selectedRound ? sortBy(selectedRound.tournaments, "date") : []
);

export const getIsNextMatchDay = createSelector(
	getActualRound,
	getSelectedRound,
	(actualRound, selectedRound) => get(actualRound, "id", 0) + 1 === get(selectedRound, "id", 0)
);

export const getIsNoActiveRound = createSelector(
	getActualRoundId,
	getSelectedRoundId,
	(actualRoundId, selectedRoundId) => actualRoundId !== selectedRoundId
);

export const getIsRoundLocked = createSelector(
	getActualRound,
	getIsNoActiveRound,
	(actualRound, isNoActiveRound) =>
		actualRound?.status === RoundStatus.Complete || isNoActiveRound
);

export const getIsSomeRoundActive = createSelector(getRounds, (rounds) => {
	const activeRound = rounds.find(({status}) => [RoundStatus.Active].includes(status));
	return Boolean(activeRound);
});

export const getIsSomeTournamentActive = createSelector(getTournaments, (tournaments) => {
	return tournaments.some(({status}) => [TournamentStatus.Active].includes(status));
});

export const getScheduleRounds = createSelector(getRounds, (rounds) => {
	return rounds.filter((round) => round.status === RoundStatus.Scheduled);
});

export const getFirstScheduledRound = createSelector(getScheduleRounds, (rounds) => first(rounds));

export const getAllTournaments = createSelector(getRounds, (rounds) =>
	flatMap(rounds, ({tournaments}) => tournaments)
);

export const getKnockoutTournaments = createSelector(getRounds, (rounds) =>
	chain(rounds)
		.filter(({stage}) => stage === Stage.Knockout)
		.flatMap(({tournaments}) => tournaments)
		.value()
);

export const getTournamentForRoundAndBracketId = createSelector(
	getRounds,
	(rounds) => (roundType: BracketRoundType, bracketId: number) =>
		rounds
			.find(({id}) => roundType === id)
			?.tournaments.find((tournament) => {
				if (roundType === BracketRoundType.F) {
					return !tournament.isThirdPlacePlayOff; // Hack to satisfy https://geniussports.atlassian.net/browse/F2P1-19763
				} else {
					return tournament.bracketId === bracketId;
				}
			})
);

export const getIsAllRoundsScheduled = createSelector(getRounds, (rounds) =>
	rounds.every(({status}) => status === RoundStatus.Scheduled)
);

export const getIsGroupRound = createSelector(
	getActualRound,
	(actualRound) => actualRound?.stage === Stage.Group
);

const isActiveOrCompleteTournament = (tournaments: ITournament[]) =>
	Boolean(
		tournaments.find((tournament) =>
			[TournamentStatus.Active, TournamentStatus.Complete].includes(tournament.status)
		)
	);

export const checkIsFullLockout = createSelector(getAllTournaments, isActiveOrCompleteTournament);

export const checkIsKnockoutLockout = createSelector(
	getKnockoutTournaments,
	isActiveOrCompleteTournament
);

export const getStartSeasonDate = createSelector(
	getRounds,
	(rounds) => first(rounds)?.startDate || ""
);

export const getIsScoring = createSelector(getRounds, (rounds) =>
	Boolean(
		find(rounds, (round) => [RoundStatus.Active, RoundStatus.Complete].includes(round.status))
	)
);

export const getAreAllRoundsComplete = createSelector(getRounds, (rounds) =>
	rounds.every(({status}) => status === RoundStatus.Complete)
);

export const getAreGroupRoundsComplete = createSelector(getRounds, (rounds) =>
	rounds
		.filter(({stage}) => stage === Stage.Group)
		.every(({status}) => status === RoundStatus.Complete)
);

type StageName = "R16" | "QF" | "SF" | "F";
const stageNameToStageId = (stageName: StageName): number => {
	switch (stageName) {
		case "R16":
			return 4;
		case "QF":
			return 5;
		case "SF":
			return 6;
		case "F":
			return 7;
		default:
			throw Error("Unknown stage name");
	}
};

export const getIsStageComplete = createSelector(
	getRounds,
	(rounds) => (stageName: StageName) =>
		rounds.find(({id}) => id === stageNameToStageId(stageName))?.status === RoundStatus.Complete
);

export interface IBracketKnockoutScore {
	ftScore: number | null;
	penaltyScore: number | null;
}

export const getKnockoutScoreForBracket = createSelector(
	getRounds,
	(rounds) =>
		(stageName: StageName, bracketId: number, squadId: number): IBracketKnockoutScore => {
			const round = rounds.find(({id}) => id === stageNameToStageId(stageName));
			if (!round) {
				return {
					ftScore: 0,
					penaltyScore: null,
				};
			}

			const tournament = round.tournaments.find((t) => {
				if (stageName === "F") {
					return !t.isThirdPlacePlayOff; // Hack to satisfy https://geniussports.atlassian.net/browse/F2P1-19763
				} else {
					return t.bracketId === bracketId;
				}
			});

			if (!tournament) {
				return {
					ftScore: 0,
					penaltyScore: null,
				};
			}

			return tournament.awaySquadId === squadId
				? {
						ftScore: tournament.awayScore,
						penaltyScore: tournament.awayPenaltyScore,
				  }
				: {
						ftScore: tournament.homeScore,
						penaltyScore: tournament.homePenaltyScore,
				  };
		}
);
