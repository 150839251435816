import React from "react";
import {DropdownBurgerButton} from "components";
import {toggleDropdownMenu} from "modules/actions";
import {useDispatch, useSelector} from "react-redux";
import {getIsDropdownMenuOpen} from "modules/selectors";

export const DropdownButton: React.FC = () => {
	const dispatch = useDispatch();
	const isOpen = useSelector(getIsDropdownMenuOpen);
	const openMenuClass = isOpen ? " open" : "";

	const toggleMenu = () => {
		dispatch(toggleDropdownMenu(!isOpen));
	};

	return <DropdownBurgerButton onClick={toggleMenu} className={openMenuClass} />;
};
