import {IStore} from "modules/types/store";
import {createSelector} from "reselect";
import {find, last, memoize, sortBy} from "lodash";
import {getSelectedRound} from "modules/selectors";
import {Groups} from "modules/enums";

export const getSquads = ({squads}: IStore) => squads;

export const getCountries = ({squads}: IStore) => sortBy(squads, "name");

export const getSquadById = createSelector(getSquads, (squads) =>
	memoize((id: number = 0) => find(squads, {id}))
);

export const getSquadTournamentById = createSelector(getSelectedRound, (round) =>
	memoize((id: number = 0) =>
		last(
			round?.tournaments.filter((tournament) =>
				[tournament.homeSquadId, tournament.awaySquadId].includes(id)
			)
		)
	)
);

export const getSquadsByGroup = createSelector(
	getSquads,
	(squads) => (groupName: Groups) => squads.filter((squad) => squad.group === groupName) || []
);
