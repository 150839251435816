import {createAction} from "redux-act";
import {IContest, ISelectedOption} from "apps/FIVES/modules/types";

export const fetchContestsJSON = createAction();
export const fetchContestsJSONSuccess = createAction<IContest[]>();
export const fetchContestsJSONFailed = createAction<Error["message"]>();

export const getContests = createAction();

export const setAnsweredQuestion = createAction<ISelectedOption>();

export const goToNextRound = createAction();
export const goToPreviousRound = createAction();
export const setActiveContest = createAction<number>();

export const togglePredictions = createAction<boolean>();
