import {createAction} from "redux-act";

export const toggleWelcomeModal = createAction<boolean>();

export const closeSaveAnswersModal = createAction();
export const openSaveAnswersModal = createAction();

export const closeSaveModal = createAction();
export const closeErrorModal = createAction();

export const toggleCongratsModal = createAction<boolean>();
