import React from "react";
import styled from "styled-components";
import notFoundImage from "assets/images/icons/not_found.png";
import {useTranslation} from "react-i18next";
import {media} from "assets/css/media";

const Wrapper = styled.div`
	border-radius: ${({theme}) => theme.leagues.leagues_border_radius};
	background-color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 20px;
	padding: 20px;
`;

const NotFoundImage = styled.img`
	max-width: 270px;
`;

const Text = styled.p`
	font-size: 16px;
	line-height: 160%;
	color: ${({theme}) => theme.leagues.leagues_text};
	font-weight: 500;
	text-align: center;

	@media (max-width: ${media.mobile}) {
		font-size: 14px;
	}
`;

export const NoScoring: React.FC = () => {
	const {t} = useTranslation();

	return (
		<Wrapper>
			<NotFoundImage src={notFoundImage} />
			<Text>{t("rankings.leaderboard.not_started")}</Text>
		</Wrapper>
	);
};
