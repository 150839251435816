import styled from "styled-components";
import {IMediaPoints, media} from "assets/css/media";

export const MobileVisible = styled.div<{breakpoint?: keyof IMediaPoints}>`
	display: none;

	@media (max-width: ${({breakpoint = "mobile" as keyof IMediaPoints}) => media[breakpoint]}) {
		display: block;

		&.flex-center {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
`;

export const DesktopVisible = styled.div<{breakpoint?: keyof IMediaPoints}>`
	@media (max-width: ${({breakpoint = "mobile" as keyof IMediaPoints}) => media[breakpoint]}) {
		display: none;
	}
`;
