import {IStore} from "modules/types/store";
import {createSelector} from "reselect";

export const getModalsState = (state: IStore) => state.global_modals;

export const getLoginModal = createSelector(getModalsState, ({loginModal}) => loginModal);
export const getShareModal = createSelector(getModalsState, ({shareModal}) => shareModal);
export const getErrorLeagueModal = createSelector(
	getModalsState,
	({errorLeagueModal}) => errorLeagueModal
);

export const getGotcLeagueModal = createSelector(
	getModalsState,
	({gotcLeagueModal}) => gotcLeagueModal
);

export const getTermsModal = createSelector(getModalsState, ({termsModal}) => termsModal);
