import React, {SyntheticEvent} from "react";
import emptyPlayer from "assets/images/emptyPlayer.svg";
import styled from "styled-components";
import {IMAGES_URL} from "modules/constants";
import {IPlayerPosition} from "modules/enums";

const Img = styled.img`
	position: relative;
`;

interface IProps {
	squadId: number;
	alt?: string;
	className?: string;
	position: IPlayerPosition;
}

export const PlayerJersey: React.FC<IProps> = ({squadId, alt, className, position}) => {
	const type = position === IPlayerPosition.Goalkeeper ? "goalkeeper" : "outfield";
	const jerseyImage = `${IMAGES_URL}squads/${type}/${squadId}.png`;

	const loadFallback = (e: SyntheticEvent<HTMLImageElement, Event>) => {
		const image = e.target as HTMLImageElement;
		if (image.src !== emptyPlayer) {
			image.src = emptyPlayer;
			image.setAttribute("data-default-jersey", "true");
		}
	};

	return <Img src={jerseyImage} alt={alt} className={className} onError={loadFallback} />;
};
