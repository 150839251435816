import React from "react";
import {isFunction, padStart} from "lodash";
import {DateTime} from "luxon";

const MS_IN_SECOND = 1000;
const SEC_IN_MINUTE = 60;
const MIN_IN_HOUR = 60;
const HRS_IN_DAY = 24;

const defaultTimerValue = {
	days: 0,
	hours: 0,
	minutes: 0,
	seconds: 0,
	diff: 0,
};

interface IProps {
	format?: string;
	onComplete?: () => void;
	onTick?: (time_to_end: number) => void;
	children?: (params: IState) => React.ReactNode;
	date: number;
	// be_time_diff: number;
	correction?: boolean;
	is_animated?: boolean;
	addToTimer: number;
}

interface IState {
	days: number;
	hours: number;
	minutes: number;
	seconds: number;
	diff: number;
	completed: boolean;
}

class Timer extends React.Component<IProps, IState> {
	public static defaultProps = {
		format: "hh:mm:ss",
		correction: true,
		addToTimer: 0,
	};

	public state = {
		...defaultTimerValue,
		completed: false,
		additionalTime: 0,
	};

	private timer?: NodeJS.Timeout;

	constructor(props: IProps) {
		super(props);

		this.state = {
			...this.state,
			...this.timerValues(this.duration),
		};
	}

	/**
	 * @ignore
	 */
	public render() {
		const {children, is_animated} = this.props;

		if (children) {
			return children(this.state);
		}

		return is_animated ? this.animated_timer : this.base_timer;
	}

	public shouldComponentUpdate(
		nextProps: Readonly<IProps>,
		nextState: Readonly<IState>
	): boolean {
		return this.state.seconds !== nextState.seconds;
	}

	/**
	 * @ignore
	 */
	public componentDidMount() {
		if (this.duration <= 0) {
			return;
		}

		this.timer = setInterval(() => this.diff(), 333);
	}

	/**
	 * @ignore
	 */
	public componentWillUnmount() {
		if (this.timer) {
			clearInterval(this.timer);
		}
	}

	private get start_date() {
		// const {be_time_diff, correction} = this.props;
		return DateTime.fromMillis(Date.now() - this.props.addToTimer);
	}

	private get duration(): number {
		const startDate = this.start_date;
		const endDate = DateTime.fromMillis(this.props.date);

		return endDate.diff(startDate).toObject().milliseconds || 0;
	}

	private timerValues(diff: number) {
		if (diff < MS_IN_SECOND) {
			return defaultTimerValue;
		}

		const seconds = Math.floor(diff / MS_IN_SECOND);
		const minutes = Math.floor(seconds / SEC_IN_MINUTE);
		const hours = Math.floor(minutes / MIN_IN_HOUR);

		return {
			diff,
			days: Math.floor(hours / HRS_IN_DAY),
			hours: hours % HRS_IN_DAY,
			minutes: minutes % MIN_IN_HOUR,
			seconds: seconds % SEC_IN_MINUTE,
		};
	}

	private diff() {
		const diff = this.duration;
		const {onTick} = this.props;

		if (isFunction(onTick)) {
			onTick(diff);
		}

		if (diff < MS_IN_SECOND) {
			return this.handleCompleteState();
		}

		this.setState(this.timerValues(diff));
	}

	private handleCompleteState() {
		if (this.timer) {
			clearInterval(this.timer);
		}

		const {onComplete} = this.props;

		this.setState({
			completed: true,
			...defaultTimerValue,
		});

		if (isFunction(onComplete)) {
			onComplete();
		}
	}

	private get base_timer() {
		const {diff, minutes, seconds} = this.state;

		if (!diff) {
			return "00:00";
		}

		return [minutes, seconds].map((unit) => padStart(String(unit), 2, "0")).join(":");
	}

	private get animated_timer() {
		const {diff, seconds} = this.state;

		if (!diff) {
			return "0";
		}

		return seconds;
	}
}

// const mapStateToProps = (state: ITriviaStore) => ({
// 	be_time_diff: getBETimeDiff(state),
// });

// export const Timer = connect(mapStateToProps)(TimerComponent);

export default Timer;
