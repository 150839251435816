import React, {useState} from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {getJsonState} from "modules/selectors";
import {LanguageType} from "modules";
import {SectionContent, SectionWrapper} from "./common";
import {media} from "assets/css/media";
import {PureButton} from "components/Button";
import {get, map} from "lodash";
import {ReactComponent as ArrowIcon} from "assets/images/icons/arrow_down.svg";

const Wrapper = styled(SectionWrapper)`
	padding: 0;
`;

const FaqItemWrapper = styled.div`
	padding: 0 20px;
	border-bottom: 1px solid #f3f3f3;

	&:last-child {
		border-bottom: none;
	}
`;

const Content = styled(SectionContent)<{$isOpen: boolean}>`
	display: ${({$isOpen}) => ($isOpen ? "block" : "none")};
	padding-bottom: 32px;
`;

const TitleWrapper = styled(PureButton)`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const Title = styled.h3`
	font-weight: 500;
	font-size: 16px;
	line-height: 48px;
	text-align: start;
	color: #000000;

	@media (max-width: ${media.mobile}) {
		font-size: 14px;
		line-height: 48px;
	}
`;

const Arrow = styled(ArrowIcon)`
	fill: #b6b5b2;
	transition: 0.2s transform ease-in-out;

	&.open {
		transform: rotateZ(180deg);
	}
`;

interface IFaqItem {
	title: string;
	text: string;
}

export const FaqItem: React.FC<IFaqItem> = ({title, text}) => {
	const [isOpen, setIsOpen] = useState(false);
	const toggleIsOpen = () => setIsOpen(!isOpen);

	return (
		<FaqItemWrapper>
			<TitleWrapper onClick={toggleIsOpen}>
				<Title>{title}</Title>
				<Arrow className={isOpen ? "open" : ""} />
			</TitleWrapper>
			<Content $isOpen={isOpen} dangerouslySetInnerHTML={{__html: text}} />
		</FaqItemWrapper>
	);
};

export const FAQs: React.FC = () => {
	const {i18n} = useTranslation();
	const {faq} = useSelector(getJsonState);

	if (!faq) {
		return null;
	}

	return (
		<Wrapper>
			{map(faq, ({title, id, text}) => (
				<FaqItem
					key={id}
					title={get(title, [i18n.language as LanguageType], "")}
					text={get(text, [i18n.language as LanguageType], "")}
				/>
			))}
		</Wrapper>
	);
};
