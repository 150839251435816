import {LinkType, PROJECT_NAME, ProjectName, PROJECTS_NAVIGATION_ITEMS} from "modules";
import i18next from "i18next";
import {filter, find} from "lodash";
import {ISubNavigationItem} from "modules/types";
import isPropValid from "@emotion/is-prop-valid";

const PROJECT_PATH = {
	POTM: "/potm",
	CONTENT_BRACKET: "/content-bracket",
	FANTASY_CLASSIC: "/fantasy-classic",
	FANTASY_DAILY: "/fantasy-daily",
	MATCH_PREDICTOR: "/match-predictor",
	DRAW_PREDICTOR: "/predictor",
	TRIVIA: "/trivia",
	FIVES: "/fives",
	HYUNDAI_QUIZ: "/hyundai-quiz",
	BRACKET_PREDICTOR: "/bracket-predictor",
	GAME_ZONE: "",
	WHOAMI: "/whoami",
	GOTT: "/gott",
	GOALSCORER: "/goalscorer",
	FANCESTRY_QUIZ: "/fancestry-quiz",
};

const currentLanguage = i18next.language || "en";
const LANGUAGE_PATH = currentLanguage === "en" ? "" : "/" + currentLanguage;

export const getProjectLink = (project: ProjectName, path = "/") =>
	PROJECT_PATH[project] + LANGUAGE_PATH + path;

export const getProjectNavigation = () => {
	const activeProject = find(
		PROJECTS_NAVIGATION_ITEMS,
		({projectName}) => projectName === PROJECT_NAME
	);
	const filteredProjects = filter(
		PROJECTS_NAVIGATION_ITEMS,
		({projectName}) => projectName !== PROJECT_NAME
	);

	return {
		name: activeProject?.name,
		to: "/",
		linkType: LinkType.Internal,
		subItems: filteredProjects,
	} as ISubNavigationItem;
};

export const secretKeyRedirect = (): void => {
	if (window.location.href !== `${window.location.origin}/`) {
		window.location.href = window.origin;
	}
};

const pr = new Intl.PluralRules("en-US", {
	type: "ordinal",
});

const suffixes = new Map([
	["one", "st"],
	["two", "nd"],
	["few", "rd"],
	["other", "th"],
]);

export const formatOrdinals = (n: number) => {
	const rule = pr.select(n);
	const suffix = suffixes.get(rule) as string;
	return `${n}${suffix}`;
};

// This implements the default behavior from styled-components v5
export const shouldForwardProp = (propName: string, target: unknown) => {
	if (typeof target === "string") {
		// For HTML elements, forward the prop if it is a valid HTML attribute
		return isPropValid(propName);
	}
	// For other elements, forward all props
	return true;
};

export * from "./fantasy";
export * from "./squads";
