import React, {useCallback, useContext, useEffect} from "react";
import styled, {ThemeContext} from "styled-components";
import {Modal, SecondaryButton} from "components";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {getLoginModal, getUserSSOParams} from "modules/selectors";
import {closeLoginModal} from "modules/actions";
import {media} from "assets/css/media";
import {PrimaryButton} from "components/Button";
import {SSO} from "modules";
import PersistStorage from "modules/utils/PersistStorage";

const ModalContent = styled.div`
	text-align: center;
	padding: 60px 80px 35px;

	@media (max-width: ${media.mobile}) {
		padding: 60px 16px 28px;
	}
`;

const PlayIcon = styled.img`
	margin-bottom: 20px;
`;

const Title = styled.h2`
	font-weight: 500;
	font-size: 20px;
	line-height: 160%;
`;

const Description = styled.p`
	font-size: 16px;
	line-height: 160%;
	margin-bottom: 20px;
`;

const ButtonsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 12px;

	button {
		max-width: 287px;
	}
`;

export const LoginModal: React.FC = () => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const {isOpen, text, redirectPath, data} = useSelector(getLoginModal);
	const parameters = useSelector(getUserSSOParams);
	const theme = useContext(ThemeContext);

	useEffect(() => {
		if (data) {
			PersistStorage.SET("redirect_data", data);
		}
	}, [data]);

	useEffect(() => {
		if (redirectPath) {
			PersistStorage.SET("redirect_path", redirectPath);
		}
	}, [redirectPath]);

	const onClose = useCallback(() => {
		PersistStorage.REMOVE("redirect_data");
		PersistStorage.REMOVE("redirect_path");
		dispatch(closeLoginModal());
	}, [dispatch]);

	const onLogin = () => {
		SSO.handleLogin(parameters);
	};

	const onRegister = () => {
		SSO.handleRegister(parameters);
	};

	if (!isOpen) {
		return null;
	}

	return (
		<Modal onClose={onClose}>
			<ModalContent>
				<PlayIcon src={theme?.modal.modal_warning_icon} alt={t("model.login.header")} />
				<Title>{t("model.login.header")}</Title>
				{text && <Description>{t(text)}</Description>}
				<ButtonsWrapper>
					<SecondaryButton className="fullwidth" onClick={onLogin}>
						{t("model.login.button_login")}
					</SecondaryButton>
					<PrimaryButton className="fullwidth" onClick={onRegister}>
						{t("model.login.button_register")}
					</PrimaryButton>
				</ButtonsWrapper>
			</ModalContent>
		</Modal>
	);
};
