import {IDictionary, ISubNavigationItem} from "modules/types";
import {ProjectName} from "modules/enums";
import {IS_SPONSOR_ENABLED} from "apps/GAME_ZONE/modules/constants";
import {DateTime} from "luxon";
import insideFIFA from "assets/images/logos/InsideFIFALogo.svg";
import FIFAPlus from "assets/images/logos/FIFAPlus.webp";
import PlayZone from "assets/images/logos/PlayZone.webp";

export const COMING_SOON = JSON.parse(process.env.REACT_APP_IS_COMING_SOON || "false") as boolean;

const LeaguesLink = "/leagues";
const LeaguesJoinLink = "/leagues/join";
const LeaguesCreateLink = "/leagues/create";

const hyundaiLeagueCopy = "landing.nav.gotc_league";
const hyundaiLeagueLink = "/hyundai-league";

const helpLink = "/help";
const rulesLink = "/help/rules";
const prizesLink = "/help/prizes";
const faqLink = "/help/faqs";
const contactLink = "/help/contact";
const tcLink = "/help/terms-of-service";
const bracketsLink = "/brackets";
const secondChanceLink = "/second-chance";
const potmEditorialLink = "/potm/editorial";

const leaguesCopy = "landing.nav.leagues";
const myTeamCopy = "landing.nav.my_team";
const myLeaguesDropdownCopy = "landing.nav_leagues_drop_down.my_leagues";
const joinLeaguesDropdownCopy = "landing.nav_drop_down.join";
const createLeaguesDropdownCopy = "landing.nav_leagues_drop_down.create";
const leaguesLeaderboardCopy = "landing.nav.leaderboard";

const helpCopy = "landing.nav.help";
const rulesCopy = "landing.nav_help_drop_down.rules";
const prizesCopy = "landing.nav_help_drop_down.prizes";
const faqCopy = "landing.nav_help_drop_down.faq";
const contactCopy = "landing.nav_help_drop_down.contact";
const tcCopy = "landing.nav_help_drop_down.t&c";

const fivesCopy = "summary.nav.fives";

const matchPredictorCustomClass = "match-predictor";
const bracketPredictorCustomClass = "bracket-predictor";
const whoamiCustomClass = "who_am_i";
const goalScorerCustomClass = "goalscorer";

const FIVES_CLASSIC_BASE_URL = "/fives";
const FANTASY_CLASSIC_BASE_URL = "/fantasy-classic";
const FANTASY_DAILY_BASE_URL = "/fantasy-daily";
const MATCH_PREDICTOR_BASE_URL = "/match-predictor";
const BRACKET_PREDICTOR_BASE_URL = "/bracket-predictor";
const WHOAMI_BASE_URL = "/whoami";
const GOTT_BASE_URL = "/gott";
const GOALSCORER_BASE_URL = "/goalscorer";
const FANCESTRY_BASE_URL = "/fancestry-quiz";

const TRIVIA_SUB_NAVIGATION: ISubNavigationItem[] = [
	{
		name: "landing.nav.trivia_hub",
		to: "/",
		href: "/trivia",
		clickable: true,
		projectName: ProjectName.Trivia,
		customClass: "trivia",
	},
	{
		name: "subheader.fifa.help",
		to: "/help",
		href: "/trivia/help",
		clickable: true,
		projectName: ProjectName.Trivia,
		customClass: "trivia",
	},
];

const BRACKETS_SUB_NAVIGATION: ISubNavigationItem[] = [
	{
		name: "subheader.fifa.help",
		to: "/help",
		href: "/content-bracket/help",
		clickable: true,
		projectName: ProjectName.Brackets,
		customClass: "brackets",
	},
];

const FIVES_SUB_NAVIGATION: ISubNavigationItem[] = [
	{
		name: fivesCopy,
		href: FIVES_CLASSIC_BASE_URL,
		to: "/",
		projectName: ProjectName.Fives,
	},
	{
		name: leaguesCopy,
		href: FIVES_CLASSIC_BASE_URL + LeaguesLink,
		to: LeaguesLink,
		projectName: ProjectName.Fives,
		customClass: "fives",
		authorizationRequired: true,
		subItems: [
			{
				name: myLeaguesDropdownCopy,
				href: FIVES_CLASSIC_BASE_URL + LeaguesLink,
				to: LeaguesLink,
				projectName: ProjectName.Fives,
			},
			{
				name: joinLeaguesDropdownCopy,
				href: FIVES_CLASSIC_BASE_URL + LeaguesJoinLink,
				to: LeaguesJoinLink,
				projectName: ProjectName.Fives,
			},
			{
				name: createLeaguesDropdownCopy,
				href: FIVES_CLASSIC_BASE_URL + LeaguesCreateLink,
				to: LeaguesCreateLink,
				projectName: ProjectName.Fives,
			},
		],
	},
	{
		name: leaguesLeaderboardCopy,
		href: FIVES_CLASSIC_BASE_URL + "/rankings",
		to: "/rankings",
		projectName: ProjectName.Fives,
		customClass: "fives",
		authorizationRequired: true,
	},
	{
		name: helpCopy,
		href: FIVES_CLASSIC_BASE_URL + "/help",
		to: "/help",
		clickable: true,
		projectName: ProjectName.Fives,
	},
];

const HYUNDAI_SUB_NAVIGATION: ISubNavigationItem[] = [
	{
		name: "summary.nav.editorial",
		href: "/hyundai-quiz/editorial",
		to: "/editorial",
		clickable: true,
		projectName: ProjectName.HyundaiQuiz,
	},
	{
		name: "summary.nav.help",
		href: "/hyundai-quiz/help",
		to: "/help",
		clickable: true,
		projectName: ProjectName.HyundaiQuiz,
	},
];

const FANTASY_CLASSIC_NAVIGATION: ISubNavigationItem[] = [
	{
		name: myTeamCopy,
		href: FANTASY_CLASSIC_BASE_URL + "/team",
		to: "/team",
		projectName: ProjectName.Fantasy,
		customClass: "fantasy",
	},
	{
		name: leaguesCopy,
		href: FANTASY_CLASSIC_BASE_URL + LeaguesLink,
		to: LeaguesLink,
		projectName: ProjectName.Fantasy,
		customClass: "fantasy",
		authorizationRequired: true,
		subItems: [
			{
				name: myLeaguesDropdownCopy,
				href: FANTASY_CLASSIC_BASE_URL + LeaguesLink,
				to: LeaguesLink,
				projectName: ProjectName.Fantasy,
			},
			{
				name: joinLeaguesDropdownCopy,
				href: FANTASY_CLASSIC_BASE_URL + LeaguesJoinLink,
				to: LeaguesJoinLink,
				projectName: ProjectName.Fantasy,
			},
			{
				name: createLeaguesDropdownCopy,
				href: FANTASY_CLASSIC_BASE_URL + LeaguesCreateLink,
				to: LeaguesCreateLink,
				projectName: ProjectName.Fantasy,
			},
		],
	},
	{
		name: leaguesLeaderboardCopy,
		href: FANTASY_CLASSIC_BASE_URL + "/rankings",
		to: "/rankings",
		projectName: ProjectName.Fantasy,
		customClass: "fantasy",
		authorizationRequired: true,
	},
	{
		name: helpCopy,
		href: FANTASY_CLASSIC_BASE_URL + helpLink,
		to: helpLink,
		clickable: true,
		projectName: ProjectName.Fantasy,
		customClass: "fantasy",
		subItems: [
			{
				name: rulesCopy,
				href: FANTASY_CLASSIC_BASE_URL + rulesLink,
				to: rulesLink,
				projectName: ProjectName.Fantasy,
			},
			{
				name: faqCopy,
				href: FANTASY_CLASSIC_BASE_URL + faqLink,
				to: faqLink,
				projectName: ProjectName.Fantasy,
			},
			{
				name: contactCopy,
				href: FANTASY_CLASSIC_BASE_URL + contactLink,
				to: contactLink,
				projectName: ProjectName.Fantasy,
			},
			{
				name: prizesCopy,
				href: FANTASY_CLASSIC_BASE_URL + prizesLink,
				to: prizesLink,
				projectName: ProjectName.Fantasy,
			},
		],
	},
];

const FANTASY_DAILY_NAVIGATION: ISubNavigationItem[] = [
	{
		name: myTeamCopy,
		href: FANTASY_DAILY_BASE_URL + "/team",
		to: "/team",
		projectName: ProjectName.FantasyDaily,
		customClass: "fantasy_daily",
	},
	{
		name: leaguesCopy,
		href: FANTASY_DAILY_BASE_URL + LeaguesLink,
		to: LeaguesLink,
		projectName: ProjectName.FantasyDaily,
		customClass: "fantasy_daily",
		authorizationRequired: true,
		subItems: [
			{
				name: myLeaguesDropdownCopy,
				href: FANTASY_DAILY_BASE_URL + LeaguesLink,
				to: LeaguesLink,
				projectName: ProjectName.FantasyDaily,
			},
			{
				name: joinLeaguesDropdownCopy,
				href: FANTASY_DAILY_BASE_URL + LeaguesJoinLink,
				to: LeaguesJoinLink,
				projectName: ProjectName.FantasyDaily,
			},
			{
				name: createLeaguesDropdownCopy,
				href: FANTASY_DAILY_BASE_URL + LeaguesCreateLink,
				to: LeaguesCreateLink,
				projectName: ProjectName.FantasyDaily,
			},
		],
	},
	{
		name: leaguesLeaderboardCopy,
		href: FANTASY_DAILY_BASE_URL + "/rankings",
		to: "/rankings",
		projectName: ProjectName.FantasyDaily,
		customClass: "fantasy_daily",
		authorizationRequired: true,
	},
	{
		name: helpCopy,
		href: FANTASY_DAILY_BASE_URL + helpLink,
		to: helpLink,
		clickable: true,
		projectName: ProjectName.FantasyDaily,
		customClass: "fantasy_daily",
		subItems: [
			{
				name: rulesCopy,
				href: FANTASY_DAILY_BASE_URL + rulesLink,
				to: rulesLink,
				projectName: ProjectName.FantasyDaily,
			},
			{
				name: prizesCopy,
				href: FANTASY_DAILY_BASE_URL + prizesLink,
				to: prizesLink,
				projectName: ProjectName.FantasyDaily,
			},
			{
				name: faqCopy,
				href: FANTASY_DAILY_BASE_URL + faqLink,
				to: faqLink,
				projectName: ProjectName.FantasyDaily,
			},
			{
				name: contactCopy,
				href: FANTASY_DAILY_BASE_URL + contactLink,
				to: contactLink,
				projectName: ProjectName.FantasyDaily,
			},
			{
				name: tcCopy,
				href: FANTASY_DAILY_BASE_URL + tcLink,
				to: tcLink,
				projectName: ProjectName.FantasyDaily,
			},
		],
	},
];

const MATCH_PREDICTOR_NAVIGATION: ISubNavigationItem[] = [
	{
		name: "landing.nav.match",
		href: MATCH_PREDICTOR_BASE_URL + "/match",
		to: "/match",
		projectName: ProjectName.MatchPredictor,
		customClass: matchPredictorCustomClass,
	},
	{
		name: hyundaiLeagueCopy,
		href: MATCH_PREDICTOR_BASE_URL + hyundaiLeagueLink,
		to: hyundaiLeagueLink,
		projectName: ProjectName.MatchPredictor,
		customClass: "fourth_step",
		authorizationRequired: true,
		hidden: true,
	},
	{
		name: leaguesCopy,
		href: MATCH_PREDICTOR_BASE_URL + LeaguesLink,
		to: LeaguesLink,
		projectName: ProjectName.MatchPredictor,
		customClass: "five_step",
		authorizationRequired: true,
		subItems: [
			{
				name: myLeaguesDropdownCopy,
				href: MATCH_PREDICTOR_BASE_URL + LeaguesLink,
				to: LeaguesLink,
				projectName: ProjectName.MatchPredictor,
			},
			{
				name: joinLeaguesDropdownCopy,
				href: MATCH_PREDICTOR_BASE_URL + LeaguesJoinLink,
				to: LeaguesJoinLink,
				projectName: ProjectName.MatchPredictor,
			},
			{
				name: createLeaguesDropdownCopy,
				href: MATCH_PREDICTOR_BASE_URL + LeaguesCreateLink,
				to: LeaguesCreateLink,
				projectName: ProjectName.MatchPredictor,
			},
		],
	},
	{
		name: leaguesLeaderboardCopy,
		href: MATCH_PREDICTOR_BASE_URL + "/rankings",
		to: "/rankings",
		projectName: ProjectName.MatchPredictor,
		customClass: matchPredictorCustomClass,
		authorizationRequired: true,
	},
	{
		name: helpCopy,
		href: MATCH_PREDICTOR_BASE_URL + helpLink,
		to: helpLink,
		clickable: true,
		projectName: ProjectName.MatchPredictor,
		customClass: matchPredictorCustomClass,
		subItems: [
			{
				name: rulesCopy,
				href: MATCH_PREDICTOR_BASE_URL + rulesLink,
				to: rulesLink,
				projectName: ProjectName.MatchPredictor,
			},
			{
				name: prizesCopy,
				href: MATCH_PREDICTOR_BASE_URL + prizesLink,
				to: prizesLink,
				projectName: ProjectName.MatchPredictor,
			},
			{
				name: faqCopy,
				href: MATCH_PREDICTOR_BASE_URL + faqLink,
				to: faqLink,
				projectName: ProjectName.MatchPredictor,
			},
			{
				name: contactCopy,
				href: MATCH_PREDICTOR_BASE_URL + contactLink,
				to: contactLink,
				projectName: ProjectName.MatchPredictor,
			},
			{
				name: tcCopy,
				href: MATCH_PREDICTOR_BASE_URL + tcLink,
				to: tcLink,
				projectName: ProjectName.MatchPredictor,
			},
		],
	},
];

const WHOAMI_NAVIGATION: ISubNavigationItem[] = [
	{
		name: "landing.nav.my_guesses",
		href: WHOAMI_BASE_URL,
		to: "/",
		projectName: ProjectName.WhoAmI,
		customClass: whoamiCustomClass,
	},
	{
		name: leaguesLeaderboardCopy,
		href: WHOAMI_BASE_URL + "/rankings",
		to: "/rankings",
		projectName: ProjectName.WhoAmI,
		customClass: "fantasy",
		authorizationRequired: true,
	},
	{
		name: helpCopy,
		href: WHOAMI_BASE_URL + helpLink,
		to: helpLink,
		clickable: true,
		projectName: ProjectName.WhoAmI,
		customClass: whoamiCustomClass,
		subItems: [
			{
				name: rulesCopy,
				href: WHOAMI_BASE_URL + rulesLink,
				to: rulesLink,
				projectName: ProjectName.WhoAmI,
			},
			{
				name: faqCopy,
				href: WHOAMI_BASE_URL + faqLink,
				to: faqLink,
				projectName: ProjectName.WhoAmI,
			},
			{
				name: contactCopy,
				href: WHOAMI_BASE_URL + contactLink,
				to: contactLink,
				projectName: ProjectName.WhoAmI,
			},
		],
	},
];

const GOTT_NAVIGATION: ISubNavigationItem[] = [
	{
		name: "landing.nav.gott",
		href: GOTT_BASE_URL + "/landing",
		to: "/landing",
		projectName: ProjectName.Gott,
	},
	{
		name: "landing.nav.my_votes",
		fallbackName: "Votes",
		href: GOTT_BASE_URL,
		to: "/",
		projectName: ProjectName.Gott,
		hidden: COMING_SOON,
	},
	{
		name: helpCopy,
		href: GOTT_BASE_URL + helpLink,
		to: helpLink,
		clickable: true,
		projectName: ProjectName.Gott,
		customClass: whoamiCustomClass,
		subItems: [
			{
				name: rulesCopy,
				href: GOTT_BASE_URL + rulesLink,
				to: rulesLink,
				projectName: ProjectName.Gott,
			},
			{
				name: faqCopy,
				href: GOTT_BASE_URL + faqLink,
				to: faqLink,
				projectName: ProjectName.Gott,
			},
		],
	},
];

const GOALSCORER_NAVIGATION: ISubNavigationItem[] = [
	{
		name: "landing.nav.my_goalscorers",
		fallbackName: "My goalscorers",
		href: GOALSCORER_BASE_URL,
		to: "/",
		projectName: ProjectName.Goalscorer,
	},
	{
		name: leaguesCopy,
		href: GOALSCORER_BASE_URL + LeaguesLink,
		to: LeaguesLink,
		projectName: ProjectName.Goalscorer,
		authorizationRequired: true,
		subItems: [
			{
				name: myLeaguesDropdownCopy,
				href: GOALSCORER_BASE_URL + LeaguesLink,
				to: LeaguesLink,
				projectName: ProjectName.Goalscorer,
			},
			{
				name: createLeaguesDropdownCopy,
				href: GOALSCORER_BASE_URL + LeaguesCreateLink,
				to: LeaguesCreateLink,
				projectName: ProjectName.Goalscorer,
			},
			{
				name: joinLeaguesDropdownCopy,
				href: GOALSCORER_BASE_URL + LeaguesJoinLink,
				to: LeaguesJoinLink,
				projectName: ProjectName.Goalscorer,
			},
		],
	},
	{
		name: leaguesLeaderboardCopy,
		href: GOALSCORER_BASE_URL + "/rankings",
		to: "/rankings",
		projectName: ProjectName.Goalscorer,
		customClass: goalScorerCustomClass,
		authorizationRequired: true,
	},
	{
		name: helpCopy,
		href: GOALSCORER_BASE_URL + helpLink,
		to: helpLink,
		clickable: true,
		projectName: ProjectName.Goalscorer,
		customClass: goalScorerCustomClass,
		subItems: [
			{
				name: rulesCopy,
				href: GOALSCORER_BASE_URL + rulesLink,
				to: rulesLink,
				projectName: ProjectName.Goalscorer,
			},
			{
				name: faqCopy,
				href: GOALSCORER_BASE_URL + faqLink,
				to: faqLink,
				projectName: ProjectName.Goalscorer,
			},
		],
	},
];

const hideSecondChance = DateTime.now() < DateTime.fromISO("2023-08-03T10:44:00.000Z");

const BRACKET_PREDICTOR_NAVIGATION: ISubNavigationItem[] = [
	{
		name: "landing.nav.my_bracket",
		href: BRACKET_PREDICTOR_BASE_URL + bracketsLink,
		to: bracketsLink,
		projectName: ProjectName.BracketsPredictor,
		customClass: bracketPredictorCustomClass,
	},
	{
		name: "landing.nav.second_chance",
		href: BRACKET_PREDICTOR_BASE_URL + secondChanceLink,
		to: secondChanceLink,
		projectName: ProjectName.BracketsPredictor,
		customClass: bracketPredictorCustomClass,
		hidden: hideSecondChance,
	},
	{
		name: leaguesCopy,
		href: BRACKET_PREDICTOR_BASE_URL + LeaguesLink,
		to: LeaguesLink,
		projectName: ProjectName.BracketsPredictor,
		customClass: bracketPredictorCustomClass,
		authorizationRequired: true,
		hiddenPaths: [secondChanceLink],
		subItems: [
			{
				name: myLeaguesDropdownCopy,
				href: BRACKET_PREDICTOR_BASE_URL + LeaguesLink,
				to: LeaguesLink,
				projectName: ProjectName.BracketsPredictor,
			},
			{
				name: joinLeaguesDropdownCopy,
				href: BRACKET_PREDICTOR_BASE_URL + LeaguesJoinLink,
				to: LeaguesJoinLink,
				projectName: ProjectName.BracketsPredictor,
			},
			{
				name: createLeaguesDropdownCopy,
				href: BRACKET_PREDICTOR_BASE_URL + LeaguesCreateLink,
				to: LeaguesCreateLink,
				projectName: ProjectName.BracketsPredictor,
			},
		],
	},
	{
		name: leaguesLeaderboardCopy,
		href: BRACKET_PREDICTOR_BASE_URL + "/rankings",
		to: "/rankings",
		projectName: ProjectName.BracketsPredictor,
		customClass: bracketPredictorCustomClass,
		authorizationRequired: true,
	},
	{
		name: helpCopy,
		href: BRACKET_PREDICTOR_BASE_URL + helpLink,
		to: helpLink,
		clickable: true,
		projectName: ProjectName.BracketsPredictor,
		customClass: bracketPredictorCustomClass,
	},
];

const POTM_NAVIGATION: ISubNavigationItem[] = [
	{
		name: "landing.subheader.votes",
		href: "/potm",
		to: "/",
		projectName: ProjectName.Potm,
		customClass: bracketPredictorCustomClass,
	},
	{
		name: "landing.subheader.editorial",
		href: potmEditorialLink,
		to: "/editorial",
		projectName: ProjectName.Potm,
		customClass: bracketPredictorCustomClass,
	},
	{
		name: helpCopy,
		href: "/potm" + helpLink,
		to: helpLink,
		clickable: true,
		projectName: ProjectName.Potm,
		customClass: bracketPredictorCustomClass,
	},
];

const GAME_ZONE_NAVIGATION = IS_SPONSOR_ENABLED
	? [
			{
				name: helpCopy,
				href: helpLink,
				to: helpLink,
				clickable: true,
				projectName: ProjectName.GameZone,
			},
	  ]
	: [];

const FANCESTRY_NAVIGATION: ISubNavigationItem[] = [
	{
		name: "landing.nav.fancestry",
		href: FANCESTRY_BASE_URL,
		to: "/",
		projectName: ProjectName.FancestryQuiz,
	},
	{
		name: helpCopy,
		href: FANCESTRY_BASE_URL + helpLink,
		to: helpLink,
		clickable: true,
		projectName: ProjectName.FancestryQuiz,
		subItems: [
			{
				name: rulesCopy,
				href: FANCESTRY_BASE_URL + rulesLink,
				to: rulesLink,
				projectName: ProjectName.FancestryQuiz,
			},
			{
				name: prizesCopy,
				href: FANCESTRY_BASE_URL + prizesLink,
				to: prizesLink,
				projectName: ProjectName.FancestryQuiz,
			},
			{
				name: faqCopy,
				href: FANCESTRY_BASE_URL + faqLink,
				to: faqLink,
				projectName: ProjectName.FancestryQuiz,
			},
		],
	},
];

export const PROJECTS_NAVIGATION_ITEMS: ISubNavigationItem[] = [
	{
		name: "subheader.fifa.play_zone",
		to: "/",
		href: "/",
		projectName: ProjectName.GameZone,
	},
	{
		name: "subheader.fifa.goalscorer_title",
		to: "/",
		href: "/goalscorer/",
		projectName: ProjectName.Goalscorer,
	},
	{
		name: "subheader.nav.fives",
		to: "/",
		href: "/fives/",
		projectName: ProjectName.Fives,
	},
	{
		name: "subheader.fifa.whoami_title",
		to: "/",
		href: "/whoami/",
		projectName: ProjectName.WhoAmI,
	},
	{
		name: "subheader.fifa.trivia_title",
		to: "/",
		href: "/trivia/",
		projectName: ProjectName.Trivia,
	},
	{
		name: "subheader.fifa.brackets_title",
		to: "/",
		href: "/content-bracket/",
		projectName: ProjectName.Brackets,
		hidden: true,
	},
	{
		name: "subheader.fifa.gott_title",
		to: "/",
		href: "/gott/",
		projectName: ProjectName.Gott,
		hidden: true,
	},
	{
		name: "subheader.fifa.match_predictor_title",
		to: "/",
		href: "/match-predictor/match",
		projectName: ProjectName.MatchPredictor,
		hidden: true,
	},
	{
		name: "subheader.fifa.bracket_predictor_title",
		to: "/",
		href: "/bracket-predictor/",
		projectName: ProjectName.BracketsPredictor,
		hidden: true,
	},
	{
		name: "subheader.fifa.fantasy_daily_title",
		to: "/",
		href: "/fantasy-daily/team",
		projectName: ProjectName.FantasyDaily,
		hidden: true,
	},
	{
		name: "subheader.fifa.potm",
		to: "/",
		href: "/potm/",
		projectName: ProjectName.Potm,
		hidden: true,
	},
	{
		name: "subheader.fifa.fancestry",
		to: "/",
		href: "/fancestry-quiz/",
		projectName: ProjectName.FancestryQuiz,
		hidden: true,
	},
];

export const MOBILE_MENU_NAVIGATION: ISubNavigationItem[] = [
	{
		name: "header.hamburger.play_zone",
		to: "/",
		href: "/",
		projectName: ProjectName.GameZone,
	},
	{
		name: "header.hamburger.goalscorer",
		to: "/",
		href: "/goalscorer/",
		projectName: ProjectName.Goalscorer,
	},
	{
		name: "header.hamburger.fives",
		to: "/",
		href: "/fives/",
		projectName: ProjectName.Fives,
	},
	{
		name: "header.hamburger.who",
		to: "/",
		href: "/whoami/",
		projectName: ProjectName.WhoAmI,
	},
	{
		name: "header.hamburger.trivia",
		to: "/",
		href: "/trivia/",
		projectName: ProjectName.Trivia,
	},
	{
		name: "header.hamburger.h2h",
		to: "/",
		href: "/content-bracket/",
		projectName: ProjectName.Brackets,
		hidden: true,
	},
	{
		name: "header.hamburger.gott",
		to: "/",
		href: "/gott/",
		projectName: ProjectName.Gott,
		hidden: true,
	},
	{
		name: "header.hamburger.match_predictor",
		to: "/",
		href: "/match-predictor/match",
		projectName: ProjectName.MatchPredictor,
		hidden: true,
	},
	{
		name: "header.hamburger.bracket",
		to: "/",
		href: "/bracket-predictor/",
		projectName: ProjectName.BracketsPredictor,
		hidden: true,
	},
	{
		name: "header.hamburger.daily",
		to: "/",
		href: "/fantasy-daily/team",
		projectName: ProjectName.FantasyDaily,
		hidden: true,
	},
	{
		name: "header.hamburger.potm",
		to: "/",
		href: "/potm/",
		projectName: ProjectName.Potm,
		hidden: true,
	},
	{
		name: "header.hamburger.fancestry",
		to: "/",
		href: "/fancestry-quiz/",
		projectName: ProjectName.FancestryQuiz,
		hidden: true,
	},
];

export const SUB_NAVIGATION_ITEMS: {
	[key: string]: ISubNavigationItem[];
} = {
	TRIVIA: TRIVIA_SUB_NAVIGATION,
	FANTASY_CLASSIC: FANTASY_CLASSIC_NAVIGATION,
	FANTASY_DAILY: FANTASY_DAILY_NAVIGATION,
	CONTENT_BRACKET: BRACKETS_SUB_NAVIGATION,
	FIVES: FIVES_SUB_NAVIGATION,
	HYUNDAI_QUIZ: HYUNDAI_SUB_NAVIGATION,
	MATCH_PREDICTOR: MATCH_PREDICTOR_NAVIGATION,
	BRACKET_PREDICTOR: BRACKET_PREDICTOR_NAVIGATION,
	POTM: POTM_NAVIGATION,
	GAME_ZONE: GAME_ZONE_NAVIGATION,
	WHOAMI: WHOAMI_NAVIGATION,
	GOTT: GOTT_NAVIGATION,
	GOALSCORER: GOALSCORER_NAVIGATION,
	FANCESTRY_QUIZ: FANCESTRY_NAVIGATION,
};

export const TUTORIAL: IDictionary<boolean | string[]> = {
	CONTENT_BRACKET: true,
	MATCH_PREDICTOR: ["/match"],
	BRACKET_PREDICTOR: [bracketsLink],
	FANTASY_CLASSIC: ["/team"],
	FANTASY_DAILY: ["/team"],
	WHOAMI: ["/"],
	GOTT: ["/game"],
	GAME_ZONE: ["/"],
	GOALSCORER: ["/"],
	FIVES: ["/"],
};

export interface IDropDownLink {
	title: string;
	subtitle?: string;
	href?: string;
	bgc?: string;
	colour?: string;
	hover?: string;
	subLinks?: {
		logo?: string;
		bgc?: string;
		colour?: string;
		hover?: string;
		links: IDropDownLink[];
	};
}

export const MENU_LINKS: IDropDownLink[] = [
	{
		title: "header.fifa.tournaments",
		subLinks: {
			bgc: "#E4E8F0",
			colour: "#505B73",
			hover: "#00b8ff",
			links: [
				{
					title: "header.fifa_second.overview",
					href: "https://www.fifa.com/fifaplus/en/tournaments",
				},
				{
					title: "header.fifa_second.world_cup_2026",
					href: "https://www.fifa.com/en/tournaments/mens/worldcup/canadamexicousa2026",
				},
				{
					title: "header.fifa_second.mundial_2025",
					href: "https://www.fifa.com/en/tournaments/mens/mundial-de-clubes/usa-2025",
				},
				{
					title: "header.fifa_second.womens_world_cup_2027",
					href: "https://www.fifa.com/en/tournaments/womens/womensworldcup/brazil-2027",
				},
				{
					title: "header.fifa_second.olympics_2024",
					href: "https://www.fifa.com/en/tournaments/olympicgames/paris2024",
				},
				{
					title: "header.fifa_second.u20_womens_world_cup_2024",
					href: "https://www.fifa.com/en/tournaments/womens/u20womensworldcup/colombia-2024",
				},
				{
					title: "header.fifa_second.futsal_world_cup_2024",
					href: "https://www.fifa.com/en/tournaments/mens/futsalworldcup/uzbekistan-2024",
				},
			],
		},
	},
	{title: "header.fifa.match_centre", href: "https://www.fifa.com/fifaplus/en/match-centre"},
	{title: "header.fifa.news", href: "https://www.fifa.com/fifaplus/en/news"},

	{
		title: "header.fifa.watch",
		subLinks: {
			logo: FIFAPlus,
			bgc: "#E4E8F0",
			colour: "#505B73",
			hover: "#00b8ff",
			links: [
				{
					title: "header.fifa_second.home",
					href: "https://www.plus.fifa.com/",
				},
				{
					title: "header.fifa_second.live",
					href: "https://www.plus.fifa.com/en/showcase/efee4958-f2c2-49c1-9007-db6d4090553b?intcmp=%28p_fifacom%29_%28d_plusfifa%29_%28c_webheader-main%29_%28sc_watch%29_%28ssc_livestreaming%29_%28sssc_%29_%28l_en%29_%28da_27112023%29",
				},
				{
					title: "header.fifa_second.originals",
					href: "https://www.fifa.com/fifaplus/en/originals",
				},
				{
					title: "header.fifa_second.archive",
					href: "https://www.fifa.com/fifaplus/en/archive",
				},
				{
					title: "header.fifa_second.wc_2026_qualifiers",
					href: "https://www.plus.fifa.com/showcase/fceffc49-6bb9-4c7e-99dc-a032f2797aa5?intcmp=%28p_fifacom%29_%28d_plusfifa%29_%28c_webheader-main%29_%28sc_watch%29_%28ssc_livestreaming%29_%28sssc_%29_%28l_en%29_%28da_27112023%29",
				},
			],
		},
	},
	{
		title: "header.fifa.play",
		subLinks: {
			logo: PlayZone,
			bgc: "#E4E8F0",
			colour: "#505B73",
			hover: "#00b8ff",
			links: [
				{title: "header.fifa_second.play_zone", href: "https://play.fifa.com/"},
				{title: "header.fifa_second.fives", href: "https://play.fifa.com/fives/"},
				{title: "header.fifa_second.goalscorer", href: "https://play.fifa.com/goalscorer/"},
				{title: "header.fifa_second.whoami", href: "https://play.fifa.com/whoami/"},
				{title: "header.fifa_second.trivia", href: "https://play.fifa.com/trivia/"},
				{
					title: "header.fifa_second.roblox",
					href: "https://www.roblox.com/games/9486506804/FIFA-WORLD",
				},
				{
					title: "header.fifa_second.more_games",
					href: "https://play.fifa.com/other-games/",
				},
			],
		},
	},
	{
		title: "header.fifa.shop",
		subLinks: {
			bgc: "#E4E8F0",
			colour: "#505B73",
			hover: "#00b8ff",
			links: [
				{
					title: "header.fifa.store",
					href: "https://store.fifa.com",
				},
				{
					title: "header.fifa.tickets",
					href: "https://www.fifa.com/fifaplus/en/tickets",
				},
				{
					title: "header.fifa.collect",
					href: "https://www.fifa.com/fifaplus/en/collect",
				},
			],
		},
	},
	{
		title: "header.fifa.inside_fifa",
		subLinks: {
			logo: insideFIFA,
			bgc: "#E4E8F0",
			colour: "#505B73",
			hover: "#00b8ff",
			links: [
				{
					title: "header.fifa_second.overview",
					href: "https://www.fifa.com/en/inside",
				},
				{
					title: "header.fifa_second.about_fifa",
					href: "https://www.fifa.com/about-fifa",
				},
				{
					title: "header.fifa_second.womens_football",
					href: "https://www.fifa.com/womens-football",
				},
				{
					title: "header.fifa_second.social_impact",
					href: "https://www.fifa.com/social-impact",
				},
				{
					title: "header.fifa_second.football_development",
					href: "https://www.fifa.com/football-development",
				},
				{
					title: "header.fifa_second.technical",
					href: "https://www.fifa.com/technical",
				},
				{
					title: "header.fifa_second.legal",
					href: "https://www.fifa.com/legal",
				},
				{
					title: "header.fifa_second.world_ranking",
					href: "https://www.fifa.com/fifa-world-ranking",
				},
			],
		},
	},
];

export const DROPDOWN_MENU_LINKS: IDropDownLink[] = [
	{
		title: "header.dropdown.tournaments",
		subLinks: {
			bgc: "#E4E8F0",
			colour: "#505B73",
			hover: "#00b8ff",
			links: [
				{
					title: "header.dropdown_second.overview",
					href: "https://www.fifa.com/fifaplus/en/tournaments",
				},
				{
					subtitle: "header.dropdown_second.upcoming",
					title: "header.dropdown_second.olympic_football",
					href: "https://www.fifa.com/en/tournaments/olympicgames/paris2024",
				},
				{
					title: "header.dropdown_second.u20_womens_world_cup_2024",
					href: "https://www.fifa.com/en/tournaments/womens/u20womensworldcup/colombia-2024",
				},
				{
					title: "header.dropdown_second.futsal_world_cup_2024",
					href: "https://www.fifa.com/en/tournaments/mens/futsalworldcup/uzbekistan-2024",
				},
				{
					title: "header.dropdown_second.u17_womens_world_cup_2024",
					href: "https://www.fifa.com/en/tournaments/womens/u17womensworldcup/dominican-republic-2024",
				},
				{
					title: "header.dropdown_second.beach_soccer_2025",
					href: "https://www.fifa.com/en/tournaments/mens/beachsoccerworldcup/seychelles-2025",
				},
				{
					subtitle: "header.dropdown_second.recent",
					title: "header.dropdown_second.beach_soccer_2024",
					href: "https://www.fifa.com/en/tournaments/mens/beachsoccerworldcup/united-arab-emirates-2024",
				},
				{
					title: "header.dropdown_second.the_best",
					href: "https://www.fifa.com/fifaplus/en/the-best-fifa-football-awards",
				},
				{
					title: "header.dropdown_second.fifa_club_world_cup",
					href: "https://www.fifa.com/fifaplus/tournaments/mens/fifa-club-world-cup/saudi-arabia-2023",
				},
				{
					title: "header.dropdown_second.fifa_u17_world_cup",
					href: "https://www.fifa.com/fifaplus/en/tournaments/mens/u17worldcup/indonesia-2023",
				},
				{
					title: "header.dropdown_second.womens_world_cup_2023",
					href: "https://www.fifa.com/fifaplus/en/tournaments/womens/womensworldcup/australia-new-zealand2023",
				},
			],
		},
	},
	{title: "header.dropdown.match_centre", href: "https://www.fifa.com/fifaplus/en/match-centre"},
	{
		title: "header.dropdown.news",
		href: "https://www.fifa.com/fifaplus/en/news",
	},
	{
		title: "header.dropdown.watch",
		subLinks: {
			logo: FIFAPlus,
			bgc: "#E4E8F0",
			colour: "#505B73",
			hover: "#00b8ff",
			links: [
				{
					title: "header.dropdown_second.home",
					href: "https://www.plus.fifa.com/",
				},
				{
					title: "header.dropdown_second.live",
					href: "https://www.plus.fifa.com/en/showcase/efee4958-f2c2-49c1-9007-db6d4090553b?intcmp=%28p_fifacom%29_%28d_plusfifa%29_%28c_webburger-main%29_%28sc_watch%29_%28ssc_livestreaming%29_%28sssc_%29_%28l_en%29_%28da_27112023%29",
				},
				{
					title: "header.dropdown_second.originals",
					href: "https://www.fifa.com/fifaplus/en/originals",
				},
				{
					title: "header.dropdown_second.archive",
					href: "https://www.fifa.com/fifaplus/en/archive",
				},
				{
					title: "header.dropdown_second.world_cup_2026_qualifiers",
					href: "https://www.plus.fifa.com/showcase/fceffc49-6bb9-4c7e-99dc-a032f2797aa5?intcmp=%28p_fifacom%29_%28d_plusfifa%29_%28c_webburger-main%29_%28sc_watch%29_%28ssc_livestreaming%29_%28sssc_%29_%28l_en%29_%28da_27112023%29",
				},
			],
		},
	},
	{
		title: "header.dropdown.play",
		subLinks: {
			logo: PlayZone,
			bgc: "#E4E8F0",
			colour: "#505B73",
			hover: "#00b8ff",
			links: [
				{
					title: "header.dropdown_second.playzone",
					href: "https://play.fifa.com/",
				},
				{
					title: "header.dropdown_second.fives",
					href: "https://play.fifa.com/fives/",
				},
				{
					title: "header.dropdown_second.goalscorer",
					href: "https://play.fifa.com/goalscorer/",
				},
				{
					title: "header.dropdown_second.whoami",
					href: "https://play.fifa.com/whoami/",
				},
				{
					title: "header.dropdown_second.trivia",
					href: "https://play.fifa.com/trivia/",
				},
				{
					title: "header.dropdown_second.roblox",
					href: "https://www.roblox.com/games/9486506804/FIFA-WORLD?intcmp=%28p_fifacom%29_%28d_roblox%29_%28c_webburger-main%29_%28sc_play%29_%28ssc_roblox%29_%28sssc_%29_%28l_en%29_%28da_27112023%29",
				},
				{
					title: "header.dropdown_second.more",
					href: "https://play.fifa.com/other-games/?intcmp=%28p_fifacom%29_%28d_playzone%29_%28c_webburger-main%29_%28sc_play%29_%28ssc_more%29_%28sssc_%29_%28l_en%29_%28da_27112023%29",
				},
			],
		},
	},
	{
		title: "header.dropdown.shop",
		subLinks: {
			bgc: "#E4E8F0",
			colour: "#505B73",
			hover: "#00b8ff",
			links: [
				{
					title: "header.dropdown_second.store",
					href: "https://store.fifa.com",
				},
				{
					title: "header.dropdown_second.tickets",
					href: "https://www.fifa.com/fifaplus/en/tickets",
				},
				{
					title: "header.dropdown_second.collect",
					href: "https://collect.fifa.com",
				},
			],
		},
	},
	{
		title: "header.dropdown.inside_fifa",
		subLinks: {
			logo: insideFIFA,
			bgc: "#E4E8F0",
			colour: "#505B73",
			hover: "#00b8ff",
			links: [
				{
					title: "header.dropdown_second.overview",
					href: "https://www.fifa.com/en/inside",
				},
				{
					title: "header.dropdown_second.about_fifa",
					href: "https://www.fifa.com/about-fifa",
				},
				{
					title: "header.dropdown_second.womens_football",
					href: "https://www.fifa.com/womens-football",
				},
				{
					title: "header.dropdown_second.social_impact",
					href: "https://www.fifa.com/social-impact",
				},
				{
					title: "header.dropdown_second.football_development",
					href: "https://www.fifa.com/football-development",
				},
				{
					title: "header.dropdown_second.technical",
					href: "https://www.fifa.com/technical",
				},
				{
					title: "header.dropdown_second.legal",
					href: "https://www.fifa.com/legal",
				},
				{
					title: "header.dropdown_second.world_ranking",
					href: "https://www.fifa.com/fifa-world-ranking",
				},
			],
		},
	},
];
