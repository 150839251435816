import {createSelector} from "reselect";
import {IStore} from "modules/types/store";

const getLeaderboardState = (state: IStore) => state.leaderboard;

export const getLeaderboard = createSelector(getLeaderboardState, (state) => state.leaderboard);

export const getLeaderboardRequestState = createSelector(
	getLeaderboardState,
	({requestState}) => requestState
);

export const getLeaderboardFilters = createSelector(
	getLeaderboardState,
	({leaderboardFilters}) => leaderboardFilters
);
