import styled, {css} from "styled-components";
import {media} from "assets/css/media";
import {PureButton} from "components/Button";

interface IProps {
	isLeaderboard?: boolean;
}

const getStyles = ({isLeaderboard}: IProps) =>
	isLeaderboard
		? css`
				border-radius: ${({theme}) => theme.leagues.leagues_border_radius};
		  `
		: css`
				border-bottom-left-radius: ${({theme}) => theme.leagues.leagues_border_radius};
				border-bottom-right-radius: ${({theme}) => theme.leagues.leagues_border_radius};
		  `;

export const TableWrapper = styled.div<IProps>`
	background: #ffffff;
	color: ${({theme}) => theme.leagues.leagues_text};
	overflow: hidden;
	${getStyles}

	&.fantasy {
		overflow: visible;
	}

	@media screen and (max-width: ${media.mobile}) {
		border-radius: 0;
		overflow: visible;
	}
`;

export const TableScroll = styled.div`
	max-height: 650px;
	overflow-y: auto;

	::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		border-radius: 10px;
	}

	::-webkit-scrollbar {
		height: 5px;
		width: 5px;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: #999;
	}

	@media screen and (max-width: ${media.mobile}) {
		max-height: none;
		overflow-y: visible;
	}
`;

export const Table = styled.table`
	width: 100%;
`;
interface IHeadProps {
	isGotc?: boolean;
}
const gotcStyle = css`
	th {
		background: #002c5f;
		color: #fff;
	}
`;
export const TableHeader = styled.thead<IHeadProps>`
	${(props) => props.isGotc && gotcStyle}

	&.comms_bank {
		th {
			background-color: #fee501;
			color: #000000;
		}
	}
`;

export const TableBody = styled.tbody``;

export interface ITableRowProps {
	highlight?: boolean;
}

export const TableRow = styled.tr<ITableRowProps>`
	border-bottom: 1px solid #e6e6e6;
	background-color: ${({theme, highlight}) =>
		highlight ? theme.leagues.leagues_active_background : null};
	color: ${({theme, highlight}) => (highlight ? theme.leagues.leagues_active_color : null)};

	&:last-child {
		border-bottom: none;
	}

	&.comms_bank {
		background-color: ${({highlight}) => (highlight ? "#FEE501" : null)};
		color: ${({highlight}) => (highlight ? "#000" : null)};
	}
`;

interface ITableCellProps {
	textAlign?: string;
	sticky?: boolean;
}

export const TableHeaderCell = styled.th<ITableCellProps>`
	background-color: ${({theme}) => theme.leagues.leagues_head_background || theme.colors.primary};
	color: ${({theme}) => theme.leagues.leagues_head_text || "#fff"};
	position: relative;
	padding: 14px;
	font-size: 14px;
	font-weight: 500;
	line-height: 24px;
	text-align: ${({textAlign}) => textAlign || "left"};
	vertical-align: middle;
`;

export const TableCell = styled.td<ITableCellProps>`
	position: relative;
	padding: 14px;
	font-size: 14px;
	font-weight: 500;
	line-height: 24px;
	text-align: ${({textAlign}) => textAlign || "left"};
`;

export const TableDivider = styled.div<ITableRowProps>`
	height: 24px;
	width: 1px;
	background-color: ${({theme, highlight}) =>
		highlight ? theme.leagues.leagues_active_color : "#EEEEE4"};
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);

	&.header {
		background-color: #b6b5b2;
	}

	&.rtl {
		right: unset;
		left: 0;
	}
`;

export const TableLoadMore = styled(PureButton)`
	width: 100%;
	padding: 24px;
	font-weight: 500;
	font-size: 16px;
	color: ${({theme}) => theme.leagues.leagues_text_tertiary};

	@media (max-width: ${media.mobile}) {
		&.mobile-bg {
			background: ${({theme}) => theme.leagues.leagues_background};
		}
	}
`;
