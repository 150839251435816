import React from "react";
import {useMediaQuery} from "@mui/material";
import {media} from "assets/css/media";
import {Exist} from "components/Exist";
import {DesktopNavigation} from "./DesktopNavigation";
import {MobileNavigation} from "./MobileNavigation";
import {INavigateRound} from "modules/reducers";
import styled from "styled-components";

const NavigationContainer = styled.section`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	box-sizing: border-box;
	width: 100%;
	min-width: 110px;
	min-height: 52px;
	margin: 0 auto;
	background: ${({theme}) => theme.header.header_sub_nav_background};
`;

interface IRoundsNavigation {
	ns?: string;
	rounds: INavigateRound[];
	onSelect: (id: number) => void;
	onComplete: () => void;
}

export const RoundsNavigation: React.FC<IRoundsNavigation> = ({
	rounds,
	ns,
	onSelect,
	onComplete,
}) => {
	const isMobile = useMediaQuery(`(max-width: ${media.desktop})`);

	if (!rounds.length) {
		return null;
	}

	return (
		<NavigationContainer>
			<Exist when={!isMobile}>
				<DesktopNavigation
					rounds={rounds}
					ns={ns}
					onSelect={onSelect}
					onComplete={onComplete}
				/>
			</Exist>

			<Exist when={isMobile}>
				<MobileNavigation
					rounds={rounds}
					ns={ns}
					onSelect={onSelect}
					onComplete={onComplete}
				/>
			</Exist>
		</NavigationContainer>
	);
};

export * from "./common";
export * from "./FixtureList";
