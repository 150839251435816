import {ALLOWED_LANGUAGES, BASE_URL} from "modules/constants";

export const getLanguageFromPath = () => {
	const splitPath = window.location.pathname.split("/").filter(Boolean);

	if (BASE_URL === "/") {
		return splitPath[0];
	}

	return splitPath[1] || splitPath[0];
};

// Base functionality not working correct for Game Zone.
// Added check for Game Zone's BASE_URL
const baseUrlForChangeLang = BASE_URL === "/" ? "" : BASE_URL;

export const prepareLanguageForRouter = () => {
	const languagePath = getLanguageFromPath();

	if (languagePath && ALLOWED_LANGUAGES.includes(languagePath)) {
		return `${BASE_URL}/${languagePath}`;
	}

	return BASE_URL;
};

export const changeRouteLanguage = (oldLang: string, newLang: string) => {
	const isPathContainsLang = window.location.pathname.includes(
		`${baseUrlForChangeLang}/${oldLang}`
	);

	if (isPathContainsLang) {
		const newPath = window.location.pathname.replace(
			`${baseUrlForChangeLang}/${oldLang}`,
			`${baseUrlForChangeLang}/${newLang}`
		);
		window.location.replace(newPath);
		return;
	}

	const newPath = window.location.pathname.replace(
		`${baseUrlForChangeLang}`,
		`${baseUrlForChangeLang}/${newLang}`
	);
	window.location.replace(newPath);
};
