import React from "react";
import styled from "styled-components";
import {
	getProjectNavigation,
	PROJECT_NAME,
	SUB_NAVIGATION_ITEMS,
	useIsShowTutorial,
	useShowNavigationLink,
} from "modules";
import {Exist} from "components/Exist";
import {SubLink} from "./SubLink";
import {TutorialLink} from "./TutorialLink";
import {Box} from "@mui/material";
import {media} from "assets/css/media";
import {NavLink} from "react-router-dom";
import {ReactComponent as Arrow} from "assets/images/icons/arrow.svg";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
	box-sizing: border-box;
	height: 82px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding-inline-start: 58px;
	padding-inline-end: 40px;
	background: #fff;

	a {
		margin-inline-end: 20px;
		margin-inline-start: 20px;
		color: #505b73;
		font-weight: 500;
	}

	@media screen and (max-width: ${media.tablet}) {
		display: none;
	}

	@media screen and (max-width: ${media.large_desktop}) {
		padding-inline-start: 8px;
	}
`;

const Dropdown = styled.div`
	position: absolute;
	bottom: 8px;
	display: none;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	transform: translateY(100%);
	background: #fff;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	padding: 15px 8px;
	text-transform: uppercase;

	&.ltr {
		left: 0;
	}

	&.rtl {
		right: 0;
	}

	&.align-right {
		&.ltr {
			left: auto;
			right: 0;
		}

		&.rtl {
			right: auto;
			left: 0;
		}
	}

	a {
		position: relative;
		padding: 10px 20px;
		font-weight: 500;
		font-size: 14px;
		line-height: 160%;
		color: #505b73;
		white-space: nowrap;
		margin: 0;
		width: 100%;

		&:after {
			content: "";
			transition: width ease-in-out 250ms;
			position: absolute;
			width: 0;
			left: 50%;
			transform: translateX(-50%);
			bottom: 4px;
			height: 3px;
			background: linear-gradient(90deg, #9b26b6 0.2%, #e93cac 105.78%);
		}

		&:hover:after {
			width: calc(100% - 40px);
			background: linear-gradient(90deg, #9b26b6 0.2%, #e93cac 105.78%);
		}

		&:focus &:active,
		&:hover {
			background: linear-gradient(90deg, #9b26b6 0.2%, #e93cac 105.78%);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}
`;

const LinkWrapper = styled.div<{isProjectNav?: boolean}>`
	position: relative;
	width: 100%;

	@media screen and (min-width: ${media.tablet}) {
		width: max-content;
	}

	.realign {
		justify-content: space-between;
	}

	.active {
		&:after {
			width: calc(100% - 40px);
			background: linear-gradient(90deg, #9b26b6 0.2%, #e93cac 105.78%);
		}
	}

	> a {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;

		@media screen and (max-width: ${media.tablet}) {
			padding: 25px 0px;
			width: max-content;
			justify-content: left;
		}

		&:after {
			content: "";
			transition: width ease-in-out 250ms;
			position: absolute;
			width: 0;
			left: 50%;
			transform: translateX(-50%);
			bottom: 14px;
			height: 3px;
			background: linear-gradient(90deg, #9b26b6 0.2%, #e93cac 105.78%);
		}

		&.active-project {
			color: #9b26b6;
		}

		&.active,
		&:hover {
			background: linear-gradient(90deg, #9b26b6 0.2%, #e93cac 105.78%);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;

			&:after {
				width: ${({isProjectNav}) => !isProjectNav && "100%"};
			}
		}
	}

	&:hover {
		${Dropdown} {
			display: flex;
			z-index: 8;
		}
	}

	.project-dropdown-arrow {
		width: 15px;
		margin-left: 9px;
		transform: rotate(-90deg);
		fill: #505b73;
	}
`;

const ProjectNav = styled(NavLink)`
	font-family: "Poppins", sans-serif;
`;

export const DesktopSubNavigation: React.FC = () => {
	const {t, i18n} = useTranslation();
	const projectNavigation = getProjectNavigation();
	const isShowTutorial = useIsShowTutorial();
	const showNavigationLink = useShowNavigationLink();

	return (
		<Wrapper>
			<Box
				sx={{
					display: "flex",
					width: "100%",
					height: "100%",
				}}>
				{SUB_NAVIGATION_ITEMS[PROJECT_NAME] &&
					SUB_NAVIGATION_ITEMS[PROJECT_NAME].map((item) => (
						<Exist when={showNavigationLink(item)} key={item.name}>
							<LinkWrapper className={item.customClass}>
								<ProjectNav
									style={{
										pointerEvents: `${
											item.subItems?.length && !item.clickable
												? "none"
												: "auto"
										}`,
									}}
									to={item.to}>
									{t(item.name, item.fallbackName ?? "")}
									<Exist when={Boolean(item.subItems?.length)}>
										<Arrow className="project-dropdown-arrow" />
									</Exist>
								</ProjectNav>
								<Exist when={Boolean(item.subItems?.length)}>
									<Dropdown className={i18n.dir()}>
										{item.subItems?.map((item) => (
											<SubLink key={item.name} item={item} />
										))}
									</Dropdown>
								</Exist>
							</LinkWrapper>
						</Exist>
					))}
				<Exist when={isShowTutorial}>
					<TutorialLink />
				</Exist>
			</Box>
			<Box
				sx={{
					display: "flex",
				}}>
				<LinkWrapper isProjectNav={true}>
					<SubLink item={projectNavigation} isProjectsNav={true} />
					<Dropdown className={i18n.dir() + " align-right"}>
						{projectNavigation.subItems?.map((item) => (
							<SubLink key={item.mobileName || item.name} item={item} />
						))}
					</Dropdown>
				</LinkWrapper>
			</Box>
		</Wrapper>
	);
};
