import React from "react";
import {getIsUSCAUser} from "modules/selectors";
import {useSelector} from "react-redux";
import styled from "styled-components";
import geicoLogo from "assets/images/logos/geicoLogo.png";
import {HEADER_HEIGHT_TB} from "modules";
import {media} from "assets/css/media";

const FancestryBannerWrapper = styled.div`
	display: none;
	position: sticky;
	top: ${HEADER_HEIGHT_TB}px;
	z-index: 5;
	width: 100%;
	background-color: #104293;
	height: 40px;
	justify-content: center;
	align-items: center;

	@media (max-width: ${media.mobile}) {
		display: flex;
	}
`;

export const FancestryBanner: React.FC = () => {
	const showFancestryBanner = useSelector(getIsUSCAUser);

	if (!showFancestryBanner) {
		return null;
	}

	return (
		<FancestryBannerWrapper>
			<img src={geicoLogo} alt="Presented By Geico" />
		</FancestryBannerWrapper>
	);
};
