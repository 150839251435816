import styled from "styled-components";
import React from "react";
import {useTranslation} from "react-i18next";
import {PROJECT_NAME, ProjectName} from "modules";
import commsBankLogo from "assets/images/logos/commsBankModalLogo.svg";
import bmoLogo from "assets/images/logos/bmoLogo.svg";
import {useSelector} from "react-redux";
import {getIsBRUser, getIsUSCAUser, getShowCommsBankBranding} from "modules/selectors";
import geicoLogo from "assets/images/logos/geicoLogo.png";
import claroLogo from "assets/images/logos/claroLogo.png";

const BannerWrapper = styled.div`
	width: 100%;
	background-color: #061121;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
`;

const SponsorText = styled.div`
	font-weight: 600;
	font-size: 16px;
	color: #fff;
`;

const FantasyDailyBannerWrapper = styled.div`
	width: 100%;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	font-size: 12px;

	&.comms-bank {
		background-color: #000;
		color: #fff;
	}

	&.bmo {
		background-color: #0079c1;
		color: #fff;
	}
`;

const FancestryBannerWrapper = styled.div`
	width: 100%;
	background-color: #104293;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const BracketPredictorBannerWrapper = styled.div`
	width: 100%;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	font-size: 12px;
	background-color: #000;
	color: #fff;
`;

const defaultText = "Proud Partner";

const PlayZoneBanner: React.FC = () => {
	const {t} = useTranslation();

	return (
		<BannerWrapper>
			<SponsorText>{t("modal.footer.play_zone")}</SponsorText>
		</BannerWrapper>
	);
};

const FantasyDailyBanner: React.FC = () => {
	const {t} = useTranslation("fantasy_daily");
	const showCommsBank = useSelector(getShowCommsBankBranding);
	const showBMO = useSelector(getIsUSCAUser);

	if (showCommsBank) {
		return (
			<FantasyDailyBannerWrapper className="comms-bank">
				<p>{t("banner.sponsor.text", defaultText)}</p>
				<img src={commsBankLogo} alt="CommonwealthBank" />
			</FantasyDailyBannerWrapper>
		);
	}

	if (showBMO) {
		return (
			<FantasyDailyBannerWrapper className="bmo">
				<p>{t("banner.sponsor_bmo.text", defaultText)}</p>
				<img src={bmoLogo} alt="BMO" />
			</FantasyDailyBannerWrapper>
		);
	}

	return null;
};

const FancestryBanner: React.FC = () => {
	const showFancestryBanner = useSelector(getIsUSCAUser);

	if (!showFancestryBanner) {
		return null;
	}

	return (
		<FancestryBannerWrapper>
			<img src={geicoLogo} alt="Presented By Geico" />
		</FancestryBannerWrapper>
	);
};

const BracketPredictorBanner: React.FC = () => {
	const {t} = useTranslation("bracket_predictor");
	const showSponsor = useSelector(getIsBRUser);

	if (showSponsor) {
		return (
			<BracketPredictorBannerWrapper>
				<p>{t("banner.sponsor.text", defaultText)}</p>
				<img src={claroLogo} alt="Claro" />
			</BracketPredictorBannerWrapper>
		);
	}

	return null;
};

export const SponsorBanner: React.FC = () => {
	if (PROJECT_NAME === ProjectName.GameZone) {
		return <PlayZoneBanner />;
	}

	if (PROJECT_NAME === ProjectName.FantasyDaily) {
		return <FantasyDailyBanner />;
	}

	if (PROJECT_NAME === ProjectName.FancestryQuiz) {
		return <FancestryBanner />;
	}

	if (PROJECT_NAME === ProjectName.BracketsPredictor) {
		return <BracketPredictorBanner />;
	}

	return null;
};
