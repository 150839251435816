import {createAction} from "redux-act";
import {StatusType} from "modules";

export interface IContest {
	id: number;
	number: number;
	startDate: string;
	endDate: string;
	status: StatusType;
}

export interface IContestsResponse {
	contests: IContest[];
}

export const fetchContests = createAction();
export const fetchContestsSuccess = createAction<IContestsResponse>();
export const fetchContestsError = createAction<Error["message"]>();

export const selectContestId = createAction<number | null>();
