import {IStore} from "modules/types/store";
import {createSelector} from "reselect";

export const getMenuState = (state: IStore) => state.menu;

export const isMenuOpen = createSelector(getMenuState, ({isOpen}) => isOpen);

export const getIsDropdownMenuOpen = createSelector(getMenuState, ({isDropdown}) => isDropdown);

export const getActiveSubMenu = createSelector(getMenuState, ({activeSubMenu}) => activeSubMenu);
