import React, {useEffect} from "react";
import styled, {css, keyframes} from "styled-components";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getIsPromoBannerOpen, getIsUserLogged, getPrompt} from "modules/selectors";
import {closePromoBanner, fetchPrompt} from "modules/actions";
import {PrimaryButton, PureButton} from "components/Button";
import {ReactComponent as Close} from "assets/images/icons/closeRound.svg";
import {ReactComponent as CloseWhite} from "assets/images/icons/closeWhite.svg";
import {ReactComponent as FifaLogo} from "assets/images/logos/fifa_logo.svg";
import {
	HEADER_HEIGHT,
	HEADER_HEIGHT_LD,
	HEADER_HEIGHT_TB,
	PromoName,
	PROMO_BANNER,
	trackAdobeAnalytics,
	PROMO_NAME,
	getPageName,
} from "modules";
import {media} from "assets/css/media";
import Cookies from "js-cookie";
import {chain, get, identity} from "lodash";
import {useLocation, useMatch} from "react-router-dom";
import {WC26Banner} from "./WC26Banner";

const slideIn = keyframes`
	from {
		transform: translateX(-100%);
	}
	to {
		transform: translateX(0);
	}
`;

interface IWrapperProps {
	$backgroundImage?: string;
	$backgroundImageMobile?: string;
}

const getBackgroundImageStyles = ({$backgroundImage, $backgroundImageMobile}: IWrapperProps) =>
	$backgroundImage &&
	css`
		background-image: url(${$backgroundImage});

		@media (max-width: ${media.mobile}) {
			background-image: url(${$backgroundImageMobile});
		}
	`;

const Wrapper = styled.a<IWrapperProps>`
	${getBackgroundImageStyles};
	background-position: center left;
	background-size: cover;
	width: calc(100% - 40px);
	display: flex;
	min-height: 70px;
	z-index: 9;
	position: fixed;
	top: ${HEADER_HEIGHT + 20}px;
	left: 20px;
	animation: ${slideIn} 0.4s ease-in-out forwards;
	box-shadow: 0 0 4px #222;

	@media (max-width: ${media.large_desktop}) {
		top: ${HEADER_HEIGHT_LD + 20}px;
	}

	@media (max-width: ${media.tablet}) {
		top: ${HEADER_HEIGHT_TB + 20}px;
	}

	&.no-header {
		top: 20px;
	}
`;

interface ILogoProps {
	$backgroundColor?: string;
	$color?: string;
}

const LogoWrapper = styled.div<ILogoProps>`
	background-color: ${({$backgroundColor}) => $backgroundColor || "#00287a"};
	display: flex;
	align-items: center;
	padding-left: 35px;
	padding-right: 20px;

	svg {
		fill: ${({$color}) => $color || null};
	}

	@media (max-width: ${media.mobile}) {
		padding-left: 12px;
		padding-right: 12px;

		svg {
			width: 70px;
		}
	}
`;

interface IContentProps {
	$backgroundColor: string;
	$backgroundImage?: string;
	$backgroundImageMobile?: string;
	$color: string;
	$font?: string;
	$textBackground?: boolean;
}

const Content = styled.div<IContentProps>`
	background-color: ${({$backgroundColor}) => $backgroundColor};
	${getBackgroundImageStyles};
	background-position: center right;
	background-size: cover;
	color: ${({$color}) => $color};
	font-family: ${({$font}) => $font || null};
	font-size: 16px;
	line-height: 140%;
	flex: 1;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 8px;
	padding: ${({$textBackground}) => ($textBackground ? "6px 10px" : "10px")};
	padding-right: 60px;

	@media (max-width: ${media.mobile}) {
		font-size: 12px;
		padding: ${({$textBackground}) => ($textBackground ? "6px 12px" : "12px")};
		padding-right: 15px;
	}
`;

interface ITextProps {
	$textBackground?: boolean;
}

const textBackgroundStyles = css`
	padding: 8px 12px;
	background: rgba(3, 24, 44, 0.7);
	border-radius: 4px;

	@media (max-width: ${media.mobile}) {
		padding: 6px 12px;
	}
`;

const TextWrapper = styled.div<ITextProps>`
	${({$textBackground}) => $textBackground && textBackgroundStyles};
`;

const Title = styled.h6`
	font-weight: 900;
`;

const Description = styled.p``;

const ButtonsWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 24px;

	svg {
		vertical-align: middle;
	}
`;

interface IButtonProps {
	$backgroundColor: string;
	$hoverColor: string;
	$color: string;
}

const StyledPrimaryButton = styled(PrimaryButton)<IButtonProps>`
	background: ${({$backgroundColor}) => $backgroundColor};
	color: ${({$color}) => $color};

	&:hover:not([disabled]) {
		background: ${({$hoverColor}) => $hoverColor};
		color: ${({$color}) => $color};
	}

	@media (max-width: ${media.mobile}) {
		display: none;
	}
`;

interface IBannerProps {
	game: PromoName;
	message: string | null;
}

const PromoBannerComponent: React.FC<IBannerProps> = ({game, message}) => {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const {pathname} = useLocation();
	const path = chain(pathname).split("/").filter(identity).pop().defaultTo("").value();
	const isQuizPage = useMatch({
		path: "/quiz",
		end: false,
	});
	const className = isQuizPage ? "no-header" : "";
	const hasSeenBanner = Cookies.get(`${game}_promo_banner`) === "true";
	const {
		backgroundColor,
		backgroundImage,
		backgroundImageMobile,
		fullWidthBackgroundImage,
		fullWidthBackgroundImageMobile,
		logoBackground,
		logoColor,
		textColor,
		textBackground,
		buttonBackground,
		buttonColor,
		buttonHover,
		buttonLink,
		buttonText,
		title,
		description,
		font,
		lightCloseButton,
	} = get(PROMO_BANNER, game);

	const setBannerViewed = () => {
		Cookies.set(`${game}_promo_banner`, "true", {
			expires: 1,
		});
	};

	const closeBanner = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		e.preventDefault();

		trackAdobeAnalytics({
			event: "assetClick",
			asset: `${game}:banner`,
			pageName: getPageName(path),
		});

		setBannerViewed();
		dispatch(closePromoBanner());
	};

	if (hasSeenBanner) {
		return null;
	}

	if (game === PromoName.WC26) {
		return (
			<WC26Banner
				className={className}
				message={message}
				setBannerViewed={setBannerViewed}
				closeBanner={closeBanner}
			/>
		);
	}

	return (
		<Wrapper
			href={buttonLink}
			onClick={setBannerViewed}
			className={className}
			$backgroundImage={fullWidthBackgroundImage}
			$backgroundImageMobile={fullWidthBackgroundImageMobile}>
			<LogoWrapper $backgroundColor={logoBackground} $color={logoColor}>
				<FifaLogo width="74" />
			</LogoWrapper>
			<Content
				$backgroundColor={backgroundColor}
				$backgroundImage={backgroundImage}
				$backgroundImageMobile={backgroundImageMobile}
				$color={textColor}
				$font={font}>
				<TextWrapper $textBackground={textBackground}>
					<Title>{t(title)}</Title>
					<Description>{message || t(description)}</Description>
				</TextWrapper>

				<ButtonsWrapper>
					<StyledPrimaryButton
						$backgroundColor={buttonBackground}
						$color={buttonColor}
						$hoverColor={buttonHover}>
						{t(buttonText)}
					</StyledPrimaryButton>

					<PureButton onClick={closeBanner}>
						{lightCloseButton ? <CloseWhite /> : <Close />}
					</PureButton>
				</ButtonsWrapper>
			</Content>
		</Wrapper>
	);
};

export const PromoBanner: React.FC = () => {
	const dispatch = useDispatch();
	const {game, message} = useSelector(getPrompt);
	const isOpen = useSelector(getIsPromoBannerOpen);
	const isLoggedIn = useSelector(getIsUserLogged);

	useEffect(() => {
		if (isLoggedIn) {
			dispatch(
				fetchPrompt({
					game: PROMO_NAME as PromoName,
				})
			);
		}
	}, [dispatch, isLoggedIn]);

	if (!game) {
		return null;
	}

	if (!isOpen) {
		return null;
	}

	return <PromoBannerComponent game={game} message={message} />;
};
