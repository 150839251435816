import React, {useEffect} from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {closePlayZoneBanner, fetchAchievementsCount} from "modules/actions";
import {PrimaryButton, PureButton} from "components/Button";
import {ReactComponent as Close} from "assets/images/icons/diamondClose.svg";
import playZoneLogo from "assets/images/logos/playZoneLogo.png";
import playZoneLogoMobile from "assets/images/logos/playZoneLogoMobile.svg";
import playZoneBannerBg from "assets/images/playZoneBannerBg.png";
import {media} from "assets/css/media";
import Cookies from "js-cookie";
import {useMediaQuery} from "@mui/material";
import {getIsPlayZoneBannerOpen} from "modules/selectors";
import {getGamezoneBanerData} from "modules/selectors/gamezone_baner";
import {useLocation} from "react-router-dom";
import {getPageName, trackAdobeAnalytics} from "modules";

const Wrapper = styled.a`
	width: 100%;
	display: flex;
	height: 70px;
	z-index: 5;
`;

const LogoWrapper = styled.div`
	background-color: #092339;
	display: flex;
	align-items: center;

	@media (max-width: ${media.mobile}) {
		padding-left: 0;
		padding-right: 0;
	}
`;

const Logo = styled.img`
	height: 70px;
	width: 195px;

	@media (max-width: ${media.mobile}) {
		display: none;
	}
`;

const LogoMobile = styled.img`
	display: none;
	@media (max-width: ${media.mobile}) {
		display: inline;
	}
`;

const Content = styled.div`
	background-image: url(${playZoneBannerBg});
	background-size: cover;
	color: #fff;
	font-family: "Qatar2022", sans-serif;
	font-size: 16px;
	line-height: 140%;
	flex: 1;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 8px;
	padding: 0 25px 0 15px;

	@media (max-width: ${media.mobile}) {
		font-size: 12px;
		padding-left: 12px;
		padding-right: 15px;
	}
`;

const Title = styled.h6`
	font-weight: 400;

	.highlight {
		color: #00cfb7;
	}
`;

const Description = styled.p`
	font-weight: 500;
`;

const ButtonsWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 24px;

	svg {
		vertical-align: middle;
	}
`;

const StyledPrimaryButton = styled(PrimaryButton)`
	background: #1199fa;

	&:after,
	&:before {
		background: #1199fa;
		border-color: #1199fa;
	}

	&:hover:not([disabled]):after,
	&:hover:not([disabled]):before {
		background: #1199fa;
		border-color: #1199fa;
	}

	&:hover:not([disabled]) {
		background: #1199fa;
	}

	@media (max-width: ${media.mobile}) {
		display: none;
	}
`;

export const PlayZoneBanner: React.FC = () => {
	const {t} = useTranslation("fantasy_classic");
	const location = useLocation();
	const dispatch = useDispatch();
	const hasSeenBanner = Cookies.get("play_zone_banner") === "true";
	const isOpen = useSelector(getIsPlayZoneBannerOpen);
	const isMobile = useMediaQuery(`(max-width: ${media.tablet})`);
	const banerData = useSelector(getGamezoneBanerData);
	const titleText = isMobile ? "play_zone.banner.copy_mobile" : "play_zone.banner.copy_desktop";

	useEffect(() => {
		dispatch(fetchAchievementsCount({game: "fantasy_classic"}));
	}, [dispatch]);

	const setBannerViewed = () => {
		trackAdobeAnalytics({
			event: "assetClick",
			asset: "playzone:banner",
			pageName: getPageName(location.pathname.replace("/", "")),
		});
		Cookies.set("play_zone_banner", "true", {
			expires: 1,
		});
	};

	const closeBanner = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		e.preventDefault();
		setBannerViewed();
		dispatch(closePlayZoneBanner());
	};

	if (hasSeenBanner) {
		return null;
	}

	if (!isOpen) {
		return null;
	}

	return (
		<Wrapper href="/badges" onClick={setBannerViewed}>
			<LogoWrapper>
				<Logo src={playZoneLogo} />
				<LogoMobile src={playZoneLogoMobile} />
			</LogoWrapper>
			<Content>
				<div>
					<Title
						dangerouslySetInnerHTML={{
							__html: t(titleText, {fantasy_badges: banerData.earnedCount}),
						}}
					/>
					<Description>
						{t(
							isMobile
								? "play_zone.banner.copy_desktop_two"
								: "play_zone.banner.copy_mobile_two"
						)}
					</Description>
				</div>

				<ButtonsWrapper>
					<StyledPrimaryButton>{t("play_zone.banner.view")}</StyledPrimaryButton>

					<PureButton onClick={closeBanner}>
						<Close />
					</PureButton>
				</ButtonsWrapper>
			</Content>
		</Wrapper>
	);
};
