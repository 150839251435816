import React, {useCallback} from "react";
import styled from "styled-components";
import {Modal, SecondaryButton} from "components";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {getErrorLeagueModal} from "modules/selectors";
import {closeErrorLeagueModal} from "modules/actions";
import error from "assets/images/icons/error.svg";
import {media} from "assets/css/media";
import {useNavigate} from "react-router-dom";
import {PROJECT_NAME, ProjectName} from "modules";
import {get} from "lodash";

const ModalContent = styled.div`
	text-align: center;
	color: #03122b;
	padding: 60px 80px 35px;

	@media (max-width: ${media.mobile}) {
		padding: 60px 16px 28px;
	}
`;

const ErrorIcon = styled.img`
	margin-bottom: 40px;
`;

const Title = styled.h2`
	font-weight: 600;
	font-size: 30px;
	margin-bottom: 24px;

	@media (max-width: ${media.mobile}) {
		font-size: 24px;
	}
`;

const Description = styled.p`
	font-weight: 500;
	margin-bottom: 24px;
	line-height: 160%;

	@media (max-width: ${media.mobile}) {
		font-size: 14px;
	}
`;

const redirectUrls = {
	[ProjectName.Fantasy]: "/team",
	[ProjectName.FantasyDaily]: "/team",
};

export const ErrorCreateLeagueModal: React.FC = () => {
	const dispatch = useDispatch();
	const {message} = useSelector(getErrorLeagueModal);
	const {t} = useTranslation();
	const navigate = useNavigate();

	const onClose = useCallback(() => {
		dispatch(closeErrorLeagueModal());
		const redirectUrl = get(redirectUrls, PROJECT_NAME, "") as string;

		if (redirectUrl) {
			navigate(redirectUrl);
		}
	}, [dispatch, navigate]);

	if (!message) {
		return null;
	}

	return (
		<Modal onClose={onClose}>
			<ModalContent>
				<ErrorIcon src={error} alt="Tick" width="56" />
				<Title>{t("modal.league_error.need_create_team_title")}</Title>
				<Description>{message}</Description>
				<SecondaryButton onClick={onClose}>
					{t("modal.league_error.need_create_team_button")}
				</SecondaryButton>
			</ModalContent>
		</Modal>
	);
};
