import {createAction} from "redux-act";

export interface ICompetition {
	id: number;
	feedId: string;
	name: string;
	image: string | null;
	seasonFeedId: string;
	isEnabled: boolean;
	tile: string | null;
	header: string | null;
}

export interface ICompetitionResponse {
	competitions: ICompetition[];
}

export const fetchCompetitions = createAction();
export const fetchCompetitionsSuccess = createAction<ICompetitionResponse>();
export const fetchCompetitionsError = createAction<Error["message"]>();
