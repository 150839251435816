import {createAction} from "redux-act";
import {IRound} from "modules/reducers";
import {GameView} from "modules/enums";

export const fetchRounds = createAction();
export const fetchRoundsSuccess = createAction<IRound[]>();
export const fetchRoundsFailed = createAction();

export const selectRound = createAction<number | null>();

export const setGameView = createAction<GameView>();
