import {createAction} from "redux-act";
import {IUser} from "modules/types";
import {Gender} from "modules/enums";

export const userFetch = createAction();
export const userFetchSuccess = createAction<IUser>();
export const userFetchFailed = createAction<Error["message"]>();

export const userChecked = createAction();

export interface ILoginPayload {
	token: string;
	redirectUrl: string;
}

export const userLogin = createAction<ILoginPayload>();
export const userLoginSuccess = createAction<IUser>();
export const userLoginFailed = createAction<Error["message"]>();
export interface IRegisterPayload extends ILoginPayload {
	username: string;
}

export const userRegister = createAction<IRegisterPayload>();
export const userRegisterAllFields = createAction<ILoginPayload>();
export const userRegisterSuccess = createAction<IUser>();
export const userRegisterFailed = createAction<Error["message"]>();

export interface IUpdatePayload {
	firstName?: string;
	lastName?: string;
	username?: string;
	bracketPredictorName?: string;
	tutorialMatchPredictor?: boolean;
	isGotcLeagueModalViewed?: boolean;
	termsOptin2?: boolean;
	termsGamezone?: boolean;
	termsMatchPredictor?: boolean;
	termsFantasyClassic?: boolean;
	termsFantasyDaily?: boolean;
	termsFancestryQuiz?: boolean;
	termsPotm?: boolean;
	termsBracketPredictor?: boolean;
	optin1MatchPredictor?: boolean;
	optin2MatchPredictor?: boolean;
	optin1Potm?: boolean;
	optin2Potm?: boolean;
	optin1Gamezone?: boolean;
	gender?: Gender;
	nationality?: string;
	province?: string;
	isAusOrNzlResident?: boolean;
	optinCommsBankTermsAndConditions?: boolean;
	optinCommsBank?: boolean;
	optinGeicoFancestry?: boolean;
	optinGeicoFancestryTermsAndConditions?: boolean;
}

export const userUpdate = createAction<IUpdatePayload>();
export const userUpdateSuccess = createAction<IUser>();
export const userUpdateFailed = createAction<Error["message"]>();

export const userAskForRegistration = createAction<string>();

export const userLogout = createAction();
export const userLogoutSuccess = createAction();
export const userLogoutFailed = createAction<Error["message"]>();

export const userSetSSOParams = createAction<URLSearchParams>();

export interface IBackdoorPayload {
	email: string;
}
export const userBackdoorLogin = createAction<IBackdoorPayload>();

export interface ILoginAppPayload {
	fifaSession: string;
	fifaHash: string;
}

export const userLoginApp = createAction<ILoginAppPayload>();

export interface IRegisterAppPayload extends ILoginAppPayload {
	username: string;
}

export const userRegisterApp = createAction<IRegisterAppPayload>();
export const userRegisterAllFieldsApp = createAction<ILoginAppPayload>();

export const userAskForRegistrationApp = createAction<ILoginAppPayload>();
