import React, {useState} from "react";
import styled from "styled-components";
import {getLanguageFromPath, SUPPORTED_LANGUAGES} from "modules";
import {PureButton} from "components/Button";
import {get} from "lodash";
import {ReactComponent as CloseIcon} from "assets/images/icons/close.svg";
import {media} from "assets/css/media";

const Wrapper = styled.div`
	position: absolute;
	min-height: 48px;
	width: 100%;
	left: 0;
	top: 100%;
	background: rgba(255, 255, 255, 0.85);
	padding: 12px 0;
	padding-inline-start: 78px;
	padding-inline-end: 78px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 16px;

	svg {
		vertical-align: middle;
	}

	@media (max-width: ${media.large_desktop}) {
		padding-inline-start: 28px;
		padding-inline-end: 28px;
	}

	@media (max-width: ${media.mobile}) {
		padding-inline-start: 16px;
		padding-inline-end: 16px;
	}
`;

const Message = styled.p`
	font-weight: 500;
	font-size: 12px;
	line-height: 160%;
	color: #061121;
`;

const MESSAGE = {
	pt: "Infelizmente, o idioma em que você estava navegando no FIFA+ não está disponível nesta página. Por isso, mudamos o idioma para inglês por enquanto.",
	it: "Sfortunatamente la lingua che hai scelto come principale su FIFA+ non supporta questa pagina.  Per questo motivo abbiamo effettuato il passaggio alla lingua inglese",
	id: "Sayangnya, bahasa yang Anda gunakan untuk melihat FIFA+ tidak tersedia pada halaman ini. Oleh karena itu, kami telah mengembalikan Anda ke bahasa Inggris untuk saat ini.",
	ko: "죄송합니다. 현재 FIFA+에서 사용하고 계신 언어로는 해당 페이지가 지원되지 않고 있습니다. 대신 당분간 해당 페이지는 영문으로 보실 수 있습니다. 양해 부탁드립니다.",
	ja: "残念ながら、あなたがFIFA+で閲覧していた言語は、現時点でこのページではサポートされていません。そのため、当面の間、デフォルトの言語を英語に変更いたしました。",
};

export const LanguageUnsupported: React.FC = () => {
	const [showMessage, setShowMessage] = useState(true);
	const languagePath = getLanguageFromPath();
	const message = get(MESSAGE, languagePath, "") as string;

	const handleClose = () => {
		setShowMessage(false);
	};

	if (!message) {
		return null;
	}

	if (!showMessage) {
		return null;
	}

	if (SUPPORTED_LANGUAGES.includes(languagePath)) {
		return null;
	}

	return (
		<Wrapper>
			<Message>{message}</Message>
			<PureButton onClick={handleClose}>
				<CloseIcon />
			</PureButton>
		</Wrapper>
	);
};
