import {createAction} from "redux-act";
import {
	IPredictionKnockout,
	IPredictionsScoring,
	ILivePredictions,
} from "apps/BRACKET_PREDICTOR/modules/reducers";

export const fetchLivePredictions = createAction();
export const fetchLivePredictionsSuccess = createAction<ILivePredictions>();
export const fetchLivePredictionsFailed = createAction<Error["message"]>();

export const fetchRealLivePredictionsResult = createAction();
export const fetchRealLivePredictionsResultSuccess = createAction<ILivePredictions>();
export const fetchRealLivePredictionsResultFailed = createAction<Error["message"]>();

export const setDisplayRealLivePredictionsResult = createAction<boolean>();

export interface IAddLivePredictionToKnockoutStagePayload {
	prediction: IPredictionKnockout;
	state: "R16" | "QF" | "SF" | "F";
}

export const addLivePredictionToKnockoutStage =
	createAction<IAddLivePredictionToKnockoutStagePayload>();

export const saveLivePredictions = createAction();
export const saveLivePredictionsSuccess = createAction<ILivePredictions>();
export const saveLivePredictionsFailed = createAction<Error["message"]>();

export const moveLivePredictionsToTemporaryState = createAction();
export const saveTemporaryLivePredictions = createAction();
export const saveTemporaryLivePredictionsSuccess = createAction<ILivePredictions>();
export const saveTemporaryLivePredictionsFailed = createAction<Error["message"]>();

export const clearLivePredictions = createAction();
export const clearLivePredictionsSuccess = createAction();
export const clearLivePredictionsFailed = createAction<Error["message"]>();

export const autopickLivePredictions = createAction();
export const autopickLivePredictionsSuccess = createAction<{predictions: ILivePredictions}>();
export const autopickLivePredictionsFailed = createAction<Error["message"]>();

export const fetchLivePredictionsScores = createAction();
export const fetchLivePredictionsScoresSuccess = createAction<IPredictionsScoring>();
export const fetchLivePredictionsScoresFailed = createAction<Error["message"]>();

export interface IAnotherLeagueMemberLivePredictions {
	userId: number;
}

export const fetchAnotherLeagueMemberLivePredictions =
	createAction<IAnotherLeagueMemberLivePredictions>();
export const fetchAnotherLeagueMemberLivePredictionsSuccess = createAction<ILivePredictions>();
export const fetchAnotherLeagueMemberLivePredictionsFailed = createAction<Error["message"]>();
