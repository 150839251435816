import {Storage} from "modules/utils/Storage";

/**
 * Wrapper for localStorage that made storage key uniq for any base path.
 * It's required for multiple projects on the same domain but at different folders
 */
export abstract class PersistStorage extends Storage {
	protected static storage = localStorage;
}

export default PersistStorage;
