import {ADOBE_GAME_NAME, ADOBE_PAGE_NAME} from "modules/constants";
import i18n from "i18next";
import {User} from "modules/utils";

const getUserStatus = () => {
	return User.IS_AUTHENTICATED() ? "logged in" : "not logged in";
};

const getUserId = () => {
	return User.GET()?.pingId ?? "";
};

export const getPageName = (section?: string) => {
	const gameName = ADOBE_GAME_NAME === "playzone" ? "" : `:${ADOBE_GAME_NAME}`;
	if (section) {
		return `${ADOBE_PAGE_NAME}play${gameName}:${section}`;
	}
	return `${ADOBE_PAGE_NAME}play${gameName}`;
};

let pageLoadSent = false;

export function trackAdobeAnalyticsViewChange(section?: string) {
	if (!window.dataLayer) {
		console.warn("No window.dataLayer found");
		return;
	}

	let event = "viewChange";

	// If this is the first time the page is loaded, send a pageLoad event instead of viewChange
	if (!pageLoadSent) {
		pageLoadSent = true;
		event = "pageLoad";
	}

	window.dataLayer.push({
		event,
		pageName: getPageName(section),
		asset: `${ADOBE_GAME_NAME}:${event}`,
		language: i18n.language,
		userStatus: getUserStatus(),
		ssoId: getUserId(),
	});
}

export function trackAdobeAnalytics(data: Record<string, string | number>) {
	if (!window.dataLayer) {
		console.warn("No window.dataLayer found!");
		return;
	}

	window.dataLayer.push({
		...data,
		asset: `${ADOBE_GAME_NAME}:${data.event}`,
		language: i18n.language,
		userStatus: getUserStatus(),
		ssoId: getUserId(),
	});
}
