import React from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {media} from "assets/css/media";
import hyundaiLogo from "apps/MATCH_PREDICTOR/assets/images/brand_logo.svg";

const MatchPredictorWrapper = styled.div`
	background-color: #36f597;
	height: 40px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	font-family: "Poppins", sans-serif;

	&.mobile {
		display: none;
	}

	@media (max-width: ${media.mobile}) {
		&.desktop {
			display: none;
		}

		&.mobile {
			display: flex;
		}
	}
`;

const MatchPredictorLabel = styled.p`
	font-weight: 500;
	font-size: 14px;
	color: #002c5f;
`;

interface IProps {
	className?: string;
}

export const MatchPredictorBanner: React.FC<IProps> = ({className}) => {
	const {t} = useTranslation("match_predictor");

	return (
		<MatchPredictorWrapper className={className}>
			<MatchPredictorLabel>
				{t("hyundai.banner.brought_to_you", "Brought to you by")}
			</MatchPredictorLabel>
			<img src={hyundaiLogo} alt="Hyundai" width="60px" />
		</MatchPredictorWrapper>
	);
};
