import {ISubNavigationItem} from "modules/types";
import React from "react";
import {LinkType, PROJECT_NAME, useShowNavigationLink} from "modules";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {ReactComponent as ArrowIcon} from "assets/images/icons/arrow.svg";
import {media} from "assets/css/media";
import {useMediaQuery} from "@mui/material";

const Arrow = styled(ArrowIcon)`
	margin-inline-start: 8px;
	fill: #9b26b6;
	width: 15px;
	transform: rotate(-90deg);

	@media screen and (max-width: ${media.mobile}) {
		margin-inline-end: 24px;
	}
`;

const getIsInner = (item: ISubNavigationItem) =>
	item.linkType === LinkType.Internal || item.projectName === PROJECT_NAME;

interface IParams {
	isActive: boolean;
}

const getClassName = (
	isActive: IParams,
	item: ISubNavigationItem,
	isMobile: boolean,
	isProjectsNav?: boolean
) => {
	let className = "";

	if (isActive && isProjectsNav) {
		className += " active-project";
	}

	if (item.customClass) {
		className += ` ${item.customClass}`;
	}

	if (isMobile) {
		className += " realign";
	}

	return className;
};

interface IProps {
	item: ISubNavigationItem;
	isProjectsNav?: boolean;
}

export const SubLink: React.FC<IProps> = ({item, isProjectsNav}) => {
	const {t} = useTranslation();
	const isMobile = useMediaQuery(`(max-width: ${media.mobile})`);
	const showNavigationLink = useShowNavigationLink();

	if (!showNavigationLink(item)) {
		return null;
	}

	if (getIsInner(item)) {
		return (
			<NavLink
				end
				to={item.to}
				className={(props) => getClassName(props, item, isMobile, isProjectsNav)}>
				{t(item.name)}
				{isProjectsNav && !isMobile && <Arrow />}
			</NavLink>
		);
	}

	return (
		<a href={item.href} className={item.customClass}>
			{t(item.name)}
		</a>
	);
};
