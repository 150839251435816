import React, {useState} from "react";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {getUser, getUserRequestState} from "modules/selectors";
import {useTranslation} from "react-i18next";
import {FormInput, Modal, PrimaryButton} from "components";
import {userUpdate} from "modules/actions";
import {RequestState} from "modules";

const Content = styled.div`
	text-align: center;
	padding: 56px 24px 40px;
`;

const Form = styled.form`
	.mb {
		margin-bottom: 24px;
	}
`;

const Title = styled.h2`
	font-family: "Qatar2022", sans-serif;
	font-weight: 500;
	font-size: 24px;
	line-height: 38px;
	text-align: center;
`;

const Description = styled.p`
	font-family: "Poppins", sans-serif;
	font-size: 16px;
	line-height: 26px;
	text-align: center;
	margin-bottom: 20px;
`;

const usernameValidation = (name: string) => {
	if (name.trim() === "") {
		return "error.message.username_required";
	}
	if (name.trim().length > 40) {
		return "error.message.username_too_long";
	}
	return "";
};

export const UsernameModal: React.FC = () => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const [error, setError] = useState("");
	const [username, setUsername] = useState("");
	const user = useSelector(getUser);
	const requestState = useSelector(getUserRequestState);
	const isLoading = requestState === RequestState.Requested;
	const isOpen = Boolean(user && !user.username?.trim());
	const isInvalid = !!usernameValidation(username);

	const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = e.currentTarget;
		const error = usernameValidation(value);
		setError(error);
		setUsername(value);
	};

	const handleSubmit = (e: React.SyntheticEvent<HTMLButtonElement | HTMLFormElement>) => {
		e.preventDefault();

		if (isInvalid) {
			return;
		}

		dispatch(
			userUpdate({
				username,
			})
		);
	};

	if (!isOpen) {
		return null;
	}

	return (
		<Modal>
			<Content>
				<Title>{t("modal.username.header")}</Title>
				<Description>{t("modal.username.desc")}</Description>
				<Form onSubmit={handleSubmit}>
					<FormInput
						className="mb"
						variant="standard"
						type="username"
						required={true}
						name="username"
						label={t("modal.username.placeholder_user")}
						placeholder={t("modal.username.placeholder_user")}
						fullWidth={true}
						value={username}
						onChange={handleFormChange}
						error={!!error}
						helperText={t(error)}
					/>
					<PrimaryButton type="submit" disabled={isInvalid || isLoading}>
						{t("modal.username.button submit")}
					</PrimaryButton>
				</Form>
			</Content>
		</Modal>
	);
};
