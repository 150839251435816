import styled from "styled-components";
import {media} from "assets/css/media";
import commsBankBackground from "assets/images/leagues/sponsors/commsBankPageBackground.svg";

export const LeaguePageWrapper = styled.div`
	background: ${({theme}) => theme.leagues.leagues_background || "#00287A"};
	background-repeat: no-repeat;
	background-position: top;
	background-size: cover;
	padding-top: 40px;
	padding-bottom: 80px;
	min-height: 1200px;

	@media screen and (max-width: ${media.mobile}) {
		background: ${({theme}) => theme.leagues.leagues_background_mobile || null};
	}
`;

export const LeagueSubPageWrapper = styled.div`
	padding-top: 40px;
	padding-bottom: 80px;
	min-height: 1200px;
	background: ${({theme}) => theme.leagues.leagues_background || "#00287A"};

	&.comms_bank {
		background-image: url(${commsBankBackground});
		background-size: 100%;
		background-position: left;
		background-repeat: no-repeat;
		background-color: #fff;
	}

	@media screen and (max-width: ${media.mobile}) {
		padding-bottom: 0;
		background: ${({theme}) => theme.leagues.leagues_background_mobile || null};

		&.comms_bank {
			background-position: 100% 120%;
		}
	}
`;

export const PageTitle = styled.h1`
	font-weight: 600;
	font-size: 24px;
	line-height: 120%;
	color: ${({theme}) => theme.leagues.leagues_text_secondary || "#fff"};

	@media screen and (max-width: ${media.mobile}) {
		font-size: 20px;
	}
`;

export * from "./LeagueNavigation";
export * from "./RankingsTable";
export * from "./UserTable";
export * from "./LeagueFilters";
export * from "./NoScoring";
