import {createAction} from "redux-act";

export interface ICountry {
	id: number;
	feedId: string;
	name: string;
	code2: string;
	code3: string;
}

export interface ICountriesResponse {
	countries: ICountry[];
}

export const fetchCountries = createAction();
export const fetchCountriesSuccess = createAction<ICountriesResponse>();
export const fetchCountriesError = createAction<Error["message"]>();
