import "assets/css/reset.css";
import "assets/css/core.css";
import "assets/css/fonts.css";
import "core-js/actual/url-search-params";
import "core-js/actual/object/from-entries";

import React, {lazy, Suspense} from "react";
import * as ReactDOMClient from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import {PagePreloader} from "components";

let errorCount = 0;
const MAX_ERRORS = 100;
Sentry.init({
	dsn: "https://8e2fa71bc4354a02b2a80d418f24f274@o151969.ingest.sentry.io/6160897",
	environment: process.env.REACT_APP_SENTRY_ENV || "development",
	sampleRate: 0.1,
	tracesSampleRate: 0.1,
	allowUrls: [".f2p.media.geniussports.com", "fifa.com"],
	integrations: [
		new Sentry.BrowserTracing(),
		new Sentry.Integrations.Breadcrumbs({
			console: false,
		}),
	],
	denyUrls: [
		"quantcast",
		"xsca",
		// browser's extensions
		/extensions\//i,
		/^chrome:\/\//i,
		/^moz-extension:\/\//i,
	],
	ignoreErrors: ["TypeError: Failed to fetch"],
	autoSessionTracking: true,
	beforeSend: (event: Sentry.Event) => {
		errorCount++;
		if (errorCount > MAX_ERRORS) {
			return null;
		}
		return event;
	},
});

const PROJECT_NAME = process.env.REACT_APP_PROJECT_NAME;
const ProjectIndex = lazy(
	() => import(`apps/${process.env.REACT_APP_PROJECT_NAME || ""}`) as Promise<{default: React.FC}>
);

const root = document.getElementById("root");

if (!root) {
	throw Error("Root element was not found");
}

ReactDOMClient.createRoot(root).render(
	<React.StrictMode>
		<Suspense fallback={<PagePreloader />}>
			{PROJECT_NAME !== "" ? <ProjectIndex /> : null}
		</Suspense>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
