import React from "react";
import styled, {keyframes} from "styled-components";
import {useTranslation} from "react-i18next";
import {PrimaryButton, PureButton} from "components/Button";
import {ReactComponent as Close} from "assets/images/icons/closeWhite.svg";
import {HEADER_HEIGHT, HEADER_HEIGHT_LD, HEADER_HEIGHT_TB, PROMO_BANNER} from "modules";
import {media} from "assets/css/media";
import logo from "assets/images/promoBanner/qualifiersLogo.png";
import logoMobile from "assets/images/promoBanner/qualifiersLogoMobile.png";
import logoBackground from "assets/images/promoBanner/qualifiers.svg";
import logoBackgroundMobile from "assets/images/promoBanner/qualifiersMobile.svg";

const slideIn = keyframes`
	from {
		transform: translateX(-100%);
	}
	to {
		transform: translateX(0);
	}
`;

const Wrapper = styled.a`
	background-color: #000;
	width: calc(100% - 40px);
	display: flex;
	min-height: 70px;
	z-index: 9;
	position: fixed;
	top: ${HEADER_HEIGHT + 20}px;
	left: 20px;
	animation: ${slideIn} 0.4s ease-in-out forwards;
	box-shadow: 0 0 4px #222;

	@media (max-width: ${media.large_desktop}) {
		top: ${HEADER_HEIGHT_LD + 20}px;
	}

	@media (max-width: ${media.tablet}) {
		top: ${HEADER_HEIGHT_TB + 20}px;
	}

	&.no-header {
		top: 20px;
	}
`;

interface ILogoProps {
	$backgroundColor?: string;
	$color?: string;
}

const LogoWrapper = styled.div<ILogoProps>`
	background-image: url(${logoBackground});
	background-size: cover;
	display: flex;
	align-items: center;
	padding-left: 35px;
	padding-right: 60px;

	.mobile {
		display: none;
	}

	@media (max-width: ${media.mobile}) {
		background-image: url(${logoBackgroundMobile});
		padding-left: 12px;
		padding-right: 12px;

		.mobile {
			display: inline;
		}

		.desktop {
			display: none;
		}
	}
`;

const Content = styled.div`
	color: #fff;
	flex: 1;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 24px;
	padding: 10px;
	padding-right: 60px;

	@media (max-width: ${media.mobile}) {
		padding: 12px;
		padding-right: 15px;
	}
`;

const TextWrapper = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	gap: 24px;

	@media (max-width: ${media.mobile}) {
		flex-direction: column;
		align-items: flex-start;
		gap: 0;
	}
`;

const Title = styled.h6`
	font-size: 28px;
	font-weight: 700;
	line-height: 140%;

	@media (max-width: ${media.mobile}) {
		font-size: 16px;
	}
`;

const Description = styled.p`
	font-size: 16px;
	line-height: 140%;
	text-align: right;
	flex: 1;

	@media (max-width: ${media.mobile}) {
		font-size: 10px;
	}
`;

const ButtonsWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 24px;

	svg {
		vertical-align: middle;
	}
`;

const StyledPrimaryButton = styled(PrimaryButton)`
	border-radius: 0px 24px 24px 24px;
	background: #fff;
	color: #000;

	&:hover:not([disabled]) {
		background: #fff;
		color: #000;
	}

	@media (max-width: ${media.mobile}) {
		display: none;
	}
`;

interface IProps {
	className?: string;
	message: string | null;
	setBannerViewed: () => void;
	closeBanner: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
}

export const WC26Banner: React.FC<IProps> = ({
	className,
	message,
	setBannerViewed,
	closeBanner,
}) => {
	const {t} = useTranslation();
	const {buttonLink, title, description} = PROMO_BANNER.wc26;

	return (
		<Wrapper href={buttonLink} onClick={setBannerViewed} className={className}>
			<LogoWrapper>
				<img className="desktop" src={logo} alt="Qualifiers" />
				<img className="mobile" src={logoMobile} alt="Qualifiers" />
			</LogoWrapper>
			<Content>
				<TextWrapper>
					<Title>{t(title, "FIFA World Cup 26™")}</Title>
					<Description>{message || t(description)}</Description>
				</TextWrapper>

				<ButtonsWrapper>
					<StyledPrimaryButton>
						{t("promo_banner.highlights.button_play")}
					</StyledPrimaryButton>

					<PureButton onClick={closeBanner}>
						<Close />
					</PureButton>
				</ButtonsWrapper>
			</Content>
		</Wrapper>
	);
};
