import {IPromoBanner} from "modules/types";
import {PromoName} from "modules/enums";
import fivesBackground from "assets/images/promoBanner/fives-UNCOMPRESSED.jpg";
import goalscorerBackground from "assets/images/promoBanner/goalscorer-UNCOMPRESSED.jpg";
import h2hBackground from "assets/images/promoBanner/h2h-UNCOMPRESSED.jpg";
import playzoneBackground from "assets/images/promoBanner/playzone-UNCOMPRESSED.jpg";
import triviaBackground from "assets/images/promoBanner/trivia-UNCOMPRESSED.jpg";
import whoamiBackground from "assets/images/promoBanner/whoami-UNCOMPRESSED.jpg";
import matchPredictorBackground from "assets/images/promoBanner/matchPredictor-UNCOMPRESSED.jpg";
import bracketPredictorBackground from "assets/images/promoBanner/bracketPredictor-UNCOMPRESSED.jpg";
import potmBackground from "assets/images/promoBanner/potm-UNCOMPRESSED.jpg";
import fancestryQuizBackground from "assets/images/promoBanner/fancestryQuiz-UNCOMPRESSED.jpg";
import dailyFantasyBackground from "assets/images/promoBanner/dailyFantasy-UNCOMPRESSED.jpg";
import highlightsBackground from "assets/images/promoBanner/highlights-UNCOMPRESSED.jpg";
import fivesBackgroundMobile from "assets/images/promoBanner/fivesMobile-UNCOMPRESSED.jpg";
import goalscorerBackgroundMobile from "assets/images/promoBanner/goalscorerMobile-UNCOMPRESSED.jpg";
import h2hBackgroundMobile from "assets/images/promoBanner/h2hMobile-UNCOMPRESSED.jpg";
import playzoneBackgroundMobile from "assets/images/promoBanner/playzoneMobile-UNCOMPRESSED.jpg";
import triviaBackgroundMobile from "assets/images/promoBanner/triviaMobile-UNCOMPRESSED.jpg";
import whoamiBackgroundMobile from "assets/images/promoBanner/whoamiMobile-UNCOMPRESSED.jpg";
import matchPredictorBackgroundMobile from "assets/images/promoBanner/matchPredictorMobile-UNCOMPRESSED.jpg";
import bracketPredictorBackgroundMobile from "assets/images/promoBanner/bracketPredictorMobile-UNCOMPRESSED.jpg";
import potmBackgroundMobile from "assets/images/promoBanner/potmMobile-UNCOMPRESSED.jpg";
import fancestryQuizBackgroundMobile from "assets/images/promoBanner/fancestryQuizMobile-UNCOMPRESSED.jpg";
import dailyFantasyMobileBackground from "assets/images/promoBanner/dailyFantasyMobile-UNCOMPRESSED.jpg";
import highlightsMobileBackground from "assets/images/promoBanner/highlightsMobile-UNCOMPRESSED.jpg";
import wcU17Background from "assets/images/promoBanner/wcU17-UNCOMPRESSED.jpg";
import wcU17BackgroundMobile from "assets/images/promoBanner/wcU17Mobile-UNCOMPRESSED.jpg";

const buttonGradient = "linear-gradient(90deg, #9B26B6 0.2%, #E93CAC 105.78%)";
const fwwcFont = "'FWWC2023', sans-serif";

const GAMEZONE_PROMO: IPromoBanner = {
	backgroundColor: "#061121",
	backgroundImage: playzoneBackground,
	backgroundImageMobile: playzoneBackgroundMobile,
	textColor: "#FFF",
	buttonBackground: buttonGradient,
	buttonColor: "#FFF",
	buttonHover: buttonGradient,
	buttonLink: "/",
	buttonText: "promo_banner.playzone.button_view",
	title: "promo_banner.playzone.title",
	description: "promo_banner.playzone.desc",
};

const FANTASY_CLASSIC_PROMO: IPromoBanner = {
	backgroundColor: "#00CFB7",
	textColor: "#550065",
	buttonBackground: buttonGradient,
	buttonColor: "#FFF",
	buttonHover: buttonGradient,
	buttonLink: "/fantasy-classic/team",
	buttonText: "promo_banner.fantasy.button_play",
	title: "promo_banner.fantasy.title",
	description: "promo_banner.fantasy.desc",
};

const FANTASY_DAILY_PROMO: IPromoBanner = {
	backgroundColor: "transparent",
	fullWidthBackgroundImage: dailyFantasyBackground,
	fullWidthBackgroundImageMobile: dailyFantasyMobileBackground,
	logoBackground: "transparent",
	textColor: "#F1F0D8",
	buttonBackground: "#2B5B6C",
	buttonColor: "#FFF",
	buttonHover: "#00968D",
	buttonLink: "/fantasy-daily/team",
	buttonText: "promo_banner.daily.button_play",
	title: "promo_banner.daily.title",
	description: "promo_banner.daily.desc",
	font: fwwcFont,
};

const BRACKET_PREDICTOR_PROMO: IPromoBanner = {
	backgroundColor: "transparent",
	fullWidthBackgroundImage: bracketPredictorBackground,
	fullWidthBackgroundImageMobile: bracketPredictorBackgroundMobile,
	logoBackground: "transparent",
	logoColor: "#2B5B6C",
	textColor: "#2B5B6C",
	buttonBackground: "#2B5B6C",
	buttonColor: "#FFF",
	buttonHover: "#00968D",
	buttonLink: "/bracket-predictor/brackets",
	buttonText: "promo_banner.bracket_predictor.button_predict",
	title: "promo_banner.bracket_predictor.title",
	description: "promo_banner.bracket_predictor.desc",
	font: fwwcFont,
};

const TRIVIA_PROMO: IPromoBanner = {
	backgroundColor: "#061121",
	backgroundImage: triviaBackground,
	backgroundImageMobile: triviaBackgroundMobile,
	textColor: "#FFF",
	buttonBackground: buttonGradient,
	buttonColor: "#FFF",
	buttonHover: buttonGradient,
	buttonLink: "/trivia",
	buttonText: "promo_banner.trivia.button_play",
	title: "promo_banner.trivia.title",
	description: "promo_banner.trivia.desc",
};

const CONTENT_BRACKET_PROMO: IPromoBanner = {
	backgroundColor: "#061121",
	backgroundImage: h2hBackground,
	backgroundImageMobile: h2hBackgroundMobile,
	textColor: "#FFF",
	buttonBackground: buttonGradient,
	buttonColor: "#FFF",
	buttonHover: buttonGradient,
	buttonLink: "/content-bracket",
	buttonText: "promo_banner.cb.button_vote",
	title: "promo_banner.cb.title",
	description: "promo_banner.cb.desc",
};

const FIVES_PROMO: IPromoBanner = {
	backgroundColor: "#061121",
	backgroundImage: fivesBackground,
	backgroundImageMobile: fivesBackgroundMobile,
	textColor: "#FFF",
	buttonBackground: buttonGradient,
	buttonColor: "#FFF",
	buttonHover: buttonGradient,
	buttonLink: "/fives",
	buttonText: "promo_banner.fives.button_play",
	title: "promo_banner.fives.title",
	description: "promo_banner.fives.desc",
};

const GOALSCORER_PROMO: IPromoBanner = {
	backgroundColor: "#061121",
	backgroundImage: goalscorerBackground,
	backgroundImageMobile: goalscorerBackgroundMobile,
	textColor: "#FFF",
	buttonBackground: buttonGradient,
	buttonColor: "#FFF",
	buttonHover: buttonGradient,
	buttonLink: "/goalscorer",
	buttonText: "promo_banner.goalscorer.button_play",
	title: "promo_banner.goalscorer.title",
	description: "promo_banner.goalscorer.desc",
};

const HYUNDAI_QUIZ_PROMO: IPromoBanner = {
	backgroundColor: "#061121",
	textColor: "#FFF",
	buttonBackground: buttonGradient,
	buttonColor: "#FFF",
	buttonHover: buttonGradient,
	buttonLink: "/hyundai-quiz",
	buttonText: "promo_banner.sustainability_quiz.button_play",
	title: "promo_banner.sustainability_quiz.title",
	description: "promo_banner.sustainability_quiz.desc",
};

const MATCH_PREDICTOR_PROMO: IPromoBanner = {
	backgroundColor: "transparent",
	fullWidthBackgroundImage: matchPredictorBackground,
	fullWidthBackgroundImageMobile: matchPredictorBackgroundMobile,
	logoBackground: "transparent",
	logoColor: "#2B5B6C",
	textColor: "#2B5B6C",
	buttonBackground: "#2B5B6C",
	buttonColor: "#FFF",
	buttonHover: "#00968D",
	buttonLink: "/match-predictor/match",
	buttonText: "promo_banner.match_predictor.button_predict",
	title: "promo_banner.match_predictor.title",
	description: "promo_banner.match_predictor.desc",
	font: fwwcFont,
};

const POTM_PROMO: IPromoBanner = {
	backgroundColor: "transparent",
	fullWidthBackgroundImage: potmBackground,
	fullWidthBackgroundImageMobile: potmBackgroundMobile,
	logoBackground: "transparent",
	textColor: "#FFF",
	buttonBackground: "#F3C246",
	buttonColor: "#091D49",
	buttonHover: "#F3C246",
	buttonLink: "/potm",
	buttonText: "promo_banner.bud.button_vote",
	title: "promo_banner.bud.title",
	description: "promo_banner.bud.desc",
};

const WHOAMI_PROMO: IPromoBanner = {
	backgroundColor: "#8A1538",
	backgroundImage: whoamiBackground,
	backgroundImageMobile: whoamiBackgroundMobile,
	textColor: "#FFF",
	buttonBackground: buttonGradient,
	buttonColor: "#FFF",
	buttonHover: buttonGradient,
	buttonLink: "/whoami",
	buttonText: "promo_banner.who.button_guess",
	title: "promo_banner.who.title",
	description: "promo_banner.who.desc",
};

const GOTT_PROMO: IPromoBanner = {
	backgroundColor: "#002C5F",
	textColor: "#FFF",
	buttonBackground: buttonGradient,
	buttonColor: "#FFF",
	buttonHover: buttonGradient,
	buttonLink: "/gott",
	buttonText: "promo_banner.gott.button_vote",
	title: "promo_banner.gott.title",
	description: "promo_banner.gott.desc",
};

const FANCESTRY_QUIZ_PROMO: IPromoBanner = {
	backgroundColor: "transparent",
	fullWidthBackgroundImage: fancestryQuizBackground,
	fullWidthBackgroundImageMobile: fancestryQuizBackgroundMobile,
	logoBackground: "transparent",
	logoColor: "#2B5B6C",
	textColor: "#2B5B6C",
	buttonBackground: "#2B5B6C",
	buttonColor: "#FFF",
	buttonHover: "#00968D",
	buttonLink: "/fancestry-quiz",
	buttonText: "promo_banner.fancestry.button_play",
	title: "promo_banner.fancestry.title",
	description: "promo_banner.fancestry.desc",
	font: fwwcFont,
};

const HIGHLIGHTS_PROMO: IPromoBanner = {
	backgroundColor: "transparent",
	fullWidthBackgroundImage: highlightsBackground,
	fullWidthBackgroundImageMobile: highlightsMobileBackground,
	logoBackground: "transparent",
	textColor: "#FFF",
	textBackground: true,
	buttonBackground: "#2B5B6C",
	buttonColor: "#FFF",
	buttonHover: "#00968D",
	buttonLink: "https://www.plus.fifa.com/",
	buttonText: "promo_banner.highlights.button_play",
	title: "promo_banner.highlights.title",
	description: "promo_banner.highlights.desc",
	font: fwwcFont,
};

const WC26_PROMO: IPromoBanner = {
	backgroundColor: "transparent",
	fullWidthBackgroundImage: "",
	fullWidthBackgroundImageMobile: "",
	logoBackground: "transparent",
	textColor: "#FFF",
	textBackground: true,
	buttonBackground: "#FFF",
	buttonColor: "#000",
	buttonHover: "#FFF",
	buttonLink: "https://www.fifa.com/fifaplus/en/tournaments/mens/worldcup/canadamexicousa2026",
	buttonText: "promo_banner.wc26.button_play",
	title: "promo_banner.wc26.title",
	description: "promo_banner.wc26.desc",
};

const WC_U17_PROMO: IPromoBanner = {
	backgroundColor: "transparent",
	fullWidthBackgroundImage: wcU17Background,
	fullWidthBackgroundImageMobile: wcU17BackgroundMobile,
	logoBackground: "transparent",
	textColor: "#FFF",
	buttonBackground: "#FFD000",
	buttonColor: "#690F3C",
	buttonHover: "#690F3C",
	buttonLink: "https://www.fifa.com/fifaplus/en/tournaments/mens/u17worldcup/indonesia-2023",
	buttonText: "promo_banner.u17wc.button_view",
	title: "promo_banner.u17wc.title",
	description: "promo_banner.u17wc.desc",
	lightCloseButton: true,
};

export const PROMO_BANNER: Record<PromoName, IPromoBanner> = {
	[PromoName.GameZone]: GAMEZONE_PROMO,
	[PromoName.Trivia]: TRIVIA_PROMO,
	[PromoName.Fantasy]: FANTASY_CLASSIC_PROMO,
	[PromoName.FantasyDaily]: FANTASY_DAILY_PROMO,
	[PromoName.BracketsPredictor]: BRACKET_PREDICTOR_PROMO,
	[PromoName.Brackets]: CONTENT_BRACKET_PROMO,
	[PromoName.Fives]: FIVES_PROMO,
	[PromoName.HyundaiQuiz]: HYUNDAI_QUIZ_PROMO,
	[PromoName.MatchPredictor]: MATCH_PREDICTOR_PROMO,
	[PromoName.Potm]: POTM_PROMO,
	[PromoName.WhoAmI]: WHOAMI_PROMO,
	[PromoName.GOTT]: GOTT_PROMO,
	[PromoName.Goalscorer]: GOALSCORER_PROMO,
	[PromoName.FancestryQuiz]: FANCESTRY_QUIZ_PROMO,
	[PromoName.Highlights]: HIGHLIGHTS_PROMO,
	[PromoName.WC26]: WC26_PROMO,
	[PromoName.WCU17]: WC_U17_PROMO,
};
