import {FormCheckbox, FormCheckboxWithLabel, Modal, PrimaryButton} from "components";
import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {media} from "assets/css/media";
import {closeTermsModal, openTermsModal, userUpdate} from "modules/actions";
import background from "assets/images/termsModalBackground-UNCOMPRESSED.jpg";
import backgroundMobile from "assets/images/termsModalBackgroundMobile-UNCOMPRESSED.jpg";
import {getTermsModal, getUser, getUserRequestState} from "modules/selectors";
import {RequestState} from "modules";
import {FormGroup} from "@mui/material";
import {useMatch} from "react-router-dom";

const ModalContent = styled.div`
	padding: 20px;
`;

const ImageWrapper = styled.div`
	height: 227px;
	background-image: url(${background});
	background-size: cover;
	background-position: center;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;

	@media screen and (max-width: ${media.mobile}) {
		background-image: url(${backgroundMobile});
	}
`;

const Title = styled.h3`
	font-weight: 900;
	font-size: 56px;
	color: #fff;
	text-align: center;

	@media screen and (max-width: ${media.mobile}) {
		font-size: 34px;
	}
`;

const Text = styled.div`
	font-size: 16px;
	line-height: 160%;
	color: #000000;
	margin-bottom: 12px;

	@media screen and (max-width: ${media.mobile}) {
		font-size: 14px;
	}
`;

const StyledFormGroup = styled(FormGroup)`
	text-align: left;
	margin-bottom: 36px;

	& .MuiFormControlLabel-label {
		color: #000;
	}
`;

export const TermsModal: React.FC = () => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const {isOpen} = useSelector(getTermsModal);
	const [isTerms, setIsTerms] = useState(false);
	const user = useSelector(getUser);
	const requestState = useSelector(getUserRequestState);
	const isLoading = requestState === RequestState.Requested;
	const isHelpPages = useMatch({path: "/help", end: false});

	useEffect(() => {
		if (user && !user.termsGamezone) {
			dispatch(openTermsModal());
		}
	}, [dispatch, user]);

	useEffect(() => {
		if (user && user.termsGamezone) {
			dispatch(closeTermsModal());
		}
	}, [dispatch, user]);

	const acceptTerms = useCallback(() => {
		if (isTerms) {
			dispatch(
				userUpdate({
					termsGamezone: true,
				})
			);
		}
	}, [dispatch, isTerms]);

	const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const {checked} = e.target;
		setIsTerms(checked);
	};

	if (!isOpen || isHelpPages) {
		return null;
	}

	return (
		<Modal>
			<ImageWrapper>
				<Title>{t("maychallenge.registration_model.header")}</Title>
			</ImageWrapper>
			<ModalContent>
				<Text>{t("maychallenge.registration_model.desc")}</Text>
				<StyledFormGroup>
					<FormCheckboxWithLabel
						name="terms"
						control={<FormCheckbox onChange={handleFormChange} required={true} />}
						label={
							<span
								/* Required to add terms and privacy links through loco */
								/* eslint-disable-next-line react/no-danger */
								dangerouslySetInnerHTML={{
									__html: t("maychallenge.registration_model.opt_in"),
								}}
							/>
						}
					/>
				</StyledFormGroup>

				<PrimaryButton onClick={acceptTerms} disabled={!isTerms || isLoading}>
					{t("maychallenge.registration_model.button")}
				</PrimaryButton>
			</ModalContent>
		</Modal>
	);
};
