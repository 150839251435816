import {createAction} from "redux-act";
import {IPlayer, IPlayerProfileStatsRecord, IPLayerSingleStats} from "modules/reducers";

export const requestPlayers = createAction();
export const requestPlayersSuccess = createAction<IPlayer[]>();
export const requestPlayersFailed = createAction<Error["message"]>();

export const requestPlayersStats = createAction();
export const requestPlayersStatsSuccess = createAction<IPlayerProfileStatsRecord>();

export const requestSinglePlayerStats = createAction<number>();
export const requestSinglePlayerStatsSuccess = createAction<IPLayerSingleStats[]>();
export const singlePlayerStatsClear = createAction();
