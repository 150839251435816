import {Modal} from "components/Modal/index";
import React, {Fragment, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {closeGotcLeagueModal, userUpdate} from "modules/actions";
import {getGotcLeagueModal, getUser} from "modules/selectors";
import logo from "assets/images/second_banner_logos.png";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {media} from "assets/css/media";
import {eq, get, isNull, noop, some} from "lodash";
import {BASE_DOMAIN} from "modules";
import {Exist} from "components";
import {useNavigate} from "react-router-dom";

const ModalContent = styled.div`
	text-align: center;
	color: #03122b;
	padding: 60px 30px 35px;

	@media (max-width: ${media.mobile}) {
		padding: 60px 20px 20px;
	}
`;
const Logo = styled.img`
	display: block;
	max-width: 180px;
	width: 100%;
	margin: 0 auto 20px;
`;
const Title = styled.h3`
	font-family: "Qatar2022", sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 160%;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	text-align: center;
	color: #002c5f;
	margin-bottom: 15px;
`;
const Description = styled.p`
	font-family: "Poppins", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 160%;
	text-align: center;
	color: #000000;
	margin-bottom: 15px;
`;
const ButtonsWrapper = styled.div`
	display: flex;
	justify-content: center;

	a {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 9px 20px;
		gap: 10px;
		width: 260px;
		height: 44px;
		background: #36f597;
		border-radius: 5px;

		font-family: "Poppins", sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 160%;
		text-align: center;
		text-transform: capitalize;
		color: #002c5f;
	}
`;
const JoinGotcButton = styled.button`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 9px 20px;
	gap: 10px;
	width: 260px;
	height: 44px;
	background: #36f597;
	border-radius: 5px;

	font-family: "Poppins", sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 160%;
	text-align: center;
	text-transform: capitalize;
	color: #002c5f;

	border: none;
	outline: none;
`;

export const GotcLeagueConfirmModal: React.FC = () => {
	const {t} = useTranslation("match_predictor");
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const user = useSelector(getUser);
	const {isOpen} = useSelector(getGotcLeagueModal);
	const isGotcLeagueModalViewed = get(user, "isGotcLeagueModalViewed", null);
	const quizLink = `${BASE_DOMAIN}/hyundai-quiz`;
	const isGotcDisable = isNull(isGotcLeagueModalViewed);
	const isGotcWelcome = eq(isGotcLeagueModalViewed, false);
	const isOpenModal = some([isGotcDisable, isGotcWelcome, isOpen]);

	const makePledgeCopy = t("hyundai.modal.button", "Make your pledge here");
	const welcomeCopy = t("hyundai.modal.button_welcome", "Welcome GOTC league");

	const onClose = () => {
		if (isGotcDisable) {
			navigate("/match");
		} else {
			dispatch(closeGotcLeagueModal());
		}
	};

	const onJoinGotc = useCallback(() => {
		dispatch(userUpdate({isGotcLeagueModalViewed: true}));
	}, [dispatch]);

	return isOpenModal ? (
		<Modal onClose={isGotcWelcome ? noop : onClose}>
			<ModalContent>
				<Logo src={logo} alt={"logo"} />
				{isGotcWelcome ? (
					<Fragment>
						<Title>{t("hyundai_league.confirm_modal.title", "Hyundai Title")}</Title>
						<Description>
							{t("hyundai_league.confirm_modal.description", "Modal Descriptions")}
						</Description>
					</Fragment>
				) : (
					<Fragment>
						<Title>
							{t("hyundai.modal.title", "Hyundai goal of the century pledge")}
						</Title>
						<Description>
							{t("hyundai.modal.description", "Modal Descriptions")}
						</Description>
					</Fragment>
				)}
				<ButtonsWrapper>
					<Exist when={isGotcDisable}>
						<a href={quizLink}>{makePledgeCopy}</a>
					</Exist>
					<Exist when={isGotcWelcome}>
						<JoinGotcButton onClick={onJoinGotc}>{welcomeCopy}</JoinGotcButton>
					</Exist>
				</ButtonsWrapper>
			</ModalContent>
		</Modal>
	) : null;
};
