import React, {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {userLoginApp, userLogout} from "modules/actions";
import {getIsUserChecked} from "modules/selectors";
import {PagePreloader} from "components";
import Cookies from "js-cookie";
import {get} from "lodash";
import {IFifaSessionCookie} from "modules/types";

interface IProps {
	children: React.ReactNode;
}

export const SessionApp: React.FC<IProps> = ({children}) => {
	const dispatch = useDispatch();
	const isUserChecked = useSelector(getIsUserChecked);
	const [isAccessTokenChecked, setIsAccessTokenChecked] = useState(false);

	useEffect(() => {
		const fifaSession = Cookies.get("fifa_session");
		const fifaSessionObject = JSON.parse(fifaSession || "null") as IFifaSessionCookie | null;
		const fifaHash = Cookies.get("fifa_hash");
		const fifaUID = get(fifaSessionObject, "accessToken");

		if (fifaUID && fifaSession && fifaHash) {
			dispatch(
				userLoginApp({
					fifaSession,
					fifaHash,
				})
			);
		} else {
			dispatch(userLogout());
			setIsAccessTokenChecked(true);
		}
	}, [dispatch]);

	return isUserChecked || isAccessTokenChecked ? (
		<Fragment>{children}</Fragment>
	) : (
		<PagePreloader />
	);
};
