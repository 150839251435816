import {createAction} from "redux-act";
import {ICreateTeamPayload} from "apps/FANTASY_DAILY/modules/actions/team";

export const openFantasyTeamSavedModal = createAction();
export const closeFantasyTeamSavedModal = createAction();

export interface IPlayerProfileModalPayload {
	playerId: number;
	areFixturesOpen: boolean;
	matchdayId?: number;
}

export const openPlayerProfileModal = createAction<IPlayerProfileModalPayload>();
export const closePlayerProfileModal = createAction();

export const openStarPlayersFullModal = createAction();
export const closeStarPlayersFullModal = createAction();
export const openPlayerPoolAlertModal = createAction<number>();
export const closePlayerPoolAlertModal = createAction();

export interface ITermsModalPayload {
	type: "create" | "update";
	data: ICreateTeamPayload;
}

export const openFantasyTermsModal = createAction<ITermsModalPayload>();
export const closeFantasyTermsModal = createAction();

export interface ITeamLoginModalPayload {
	text?: string;
	redirectPath?: string;
	data?: Record<string, unknown>;
}

export const openTeamLoginModal = createAction<ITeamLoginModalPayload>();
export const closeTeamLoginModal = createAction();
