export enum ShareNet {
	Facebook = "fb",
	Twitter = "tw",
	Native = "native",
	Download = "download",
}

export enum ShareType {
	Facebook = "facebook",
	Twitter = "twitter",
	Native = "native",
}

export enum ShareItemType {
	ContentBracket = "content_brackets_moment",
	Trivia = "trivia_quiz",
	BracketPredictor = "bracket_prediction_before_lockout",
	LiveBracketPredictor = "bracket_prediction_after_lockout",
}

export enum ShareSource {
	General = "general",
	PostQuiz = "post_quiz",
	League = "invite-to-join-league",
	Predictions = "predictions",
	LivePredictions = "live-predictions",
	Prediction = "prediction",
	TeamResults = "results",
	Option = "option",
}

export enum RequestState {
	IDLE,
	Requested,
	Received,
	Error,
}

export enum EditState {
	IDLE,
	Edited,
}

export enum RequestStateType {
	Loading,
	Success,
	Error,
	Idle,
}

export enum SaveButtonType {
	Disabled,
	Saving,
	Saved,
	Enabled,
}

export enum PrivacyType {
	Private = "private",
	Public = "public",
}

export enum LanguageType {
	DE = "de",
	EN = "en",
	ES = "es",
	FR = "fr",
	AR = "ar",
	ID = "id",
	KO = "ko",
	PT = "pt",
	CIMODE = "cimode",
}

export enum StatusType {
	Active = "active",
	Complete = "complete",
	Scheduled = "scheduled",
}

export enum ViewPortVisibility {
	MobileOnly = "MobileOnly",
	DesktopOnly = "DesktopOnly",
}

export enum LinkType {
	Internal = "internal",
	External = "external",
}

export enum ProjectName {
	Brackets = "CONTENT_BRACKET",
	Fantasy = "FANTASY_CLASSIC",
	FantasyDaily = "FANTASY_DAILY",
	Predictor = "DRAW_PREDICTOR",
	Trivia = "TRIVIA",
	GameZone = "GAME_ZONE",
	Fives = "FIVES",
	HyundaiQuiz = "HYUNDAI_QUIZ",
	MatchPredictor = "MATCH_PREDICTOR",
	BracketsPredictor = "BRACKET_PREDICTOR",
	Potm = "POTM",
	WhoAmI = "WHOAMI",
	Gott = "GOTT",
	Goalscorer = "GOALSCORER",
	FancestryQuiz = "FANCESTRY_QUIZ",
}

export enum PromoName {
	Brackets = "content_brackets",
	Fantasy = "fantasy_classic",
	FantasyDaily = "fantasy_daily",
	Trivia = "trivia",
	GameZone = "gamezone",
	Fives = "fives",
	HyundaiQuiz = "sustainability_quiz",
	MatchPredictor = "match_predictor",
	BracketsPredictor = "bracket_predictor",
	Potm = "player_of_the_match_vote",
	WhoAmI = "whoami",
	GOTT = "goal_of_the_tournament",
	Goalscorer = "goalscorer",
	FancestryQuiz = "fancestry_quiz",
	Highlights = "highlights",
	WC26 = "wc26",
	WCU17 = "wc_u17",
}

export enum RoundStatus {
	Scheduled = "scheduled",
	Active = "playing",
	Complete = "complete",
}

export enum Stage {
	Group = "group",
	Knockout = "knockout",
}

export enum TournamentStatus {
	Scheduled = "scheduled",
	Active = "playing",
	Complete = "complete",
}

export enum GameView {
	Slide = "slide",
	List = "list",
}

export enum PlayerStatus {
	Available = "available",
	Eliminated = "eliminated",
	Unavailable = "unavailable",
	Unconfirmed = "unconfirmed",
	Substitute = "substitute",
}

export enum IPlayerPosition {
	Goalkeeper = 1,
	Defender = 2,
	Midfielder = 3,
	Forward = 4,
}

export enum LandingCartType {
	Login = "login",
}

export enum LeagueClass {
	Regular = "regular",
	Overall = "overall",
	Country = "country",
	GOTC = "gotc",
	CommsBank = "comms_bank",
}

export enum Gender {
	Male = "male",
	Female = "female",
	Other = "other",
	PreferNotToSay = "prefer-not-to-say",
}

export enum RoundType {
	RD16 = "rd16",
	QUARTERFINALS = "quarterfinals",
	SEMIFINALS = "semifinals",
	FINAL = "final",
}

export enum BracketRoundType {
	R16 = 4,
	QF = 5,
	SF = 6,
	F = 7,
}

export enum Groups {
	A = "a",
	B = "b",
	C = "c",
	D = "d",
	E = "e",
	F = "f",
	G = "g",
	H = "h",
}
