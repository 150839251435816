import {createAction} from "redux-act";
import {Groups} from "modules/enums";

export const openSavedModal = createAction();
export const toggleSavedModal = createAction();

export const toggleWelcomeModal = createAction<boolean>();
export const toggleTutorialModal = createAction<boolean>();

export interface ITeamLoginModalPayload {
	text?: string;
	redirectPath?: string;
	data?: Record<string, unknown>;
}

export const openTeamAuthModal = createAction<ITeamLoginModalPayload>();
export const closeTeamAuthModal = createAction();

export const openHowToScoreModal = createAction();
export const closeHowToScoreModal = createAction();

export const openTransitionModal = createAction();
export const closeTransitionModal = createAction();

export const openLockoutNewUserModal = createAction();
export const closeLockoutNewUserModal = createAction();

export interface IKnockoutScoreBreakdownModal {
	stageName: string;
	bracketId: number;
}

export const setKnockoutScoreBreakdownModal = createAction<IKnockoutScoreBreakdownModal>();
export const closeKnockoutScoreBreakdownModal = createAction();

export const setGroupScoreBreakdownModal = createAction<{group: Groups}>();
export const closeGroupScoreBreakdownModal = createAction();

export const setLiveGroupStageModal = createAction<{group: Groups}>();
export const closeLiveGroupStageModal = createAction();

export const openSecondChanceModal = createAction();
export const closeSecondChanceModal = createAction();
