import {createAction} from "redux-act";
import {IGameBar} from "apps/FANTASY_DAILY/modules/reducers";

export interface IGameBarPayload {
	matchDay?: number;
}

export const requestGameBar = createAction<IGameBarPayload>();
export const requestGameBarSuccess = createAction<IGameBar>();
export const requestGameBarFailed = createAction();
