import {createAction} from "redux-act";
import {IDictionary} from "modules/types";
import {IFaq, IGeoLocation, IHelpPages} from "modules/reducers";

export const fetchChecksumsJSON = createAction();
export const fetchChecksumsJSONSuccess = createAction<IDictionary<string>>();
export const fetchChecksumsJSONFailed = createAction();

export const fetchHelpPageJsons = createAction();

export const fetchHelpPagesJson = createAction();
export const fetchHelpPagesJsonSuccess = createAction<IHelpPages>();
export const fetchHelpPagesJsonFailed = createAction();

export const fetchFAQJson = createAction();
export const fetchFAQJsonSuccess = createAction<IFaq[]>();
export const fetchFAQJsonFailed = createAction();

export const fetchCountries = createAction();
export const fetchTranslatedCountries = createAction();
export const fetchCountriesSuccess = createAction<Record<string, string>>();
export const fetchCountriesFailed = createAction();

export const fetchGeoLocation = createAction();
export const fetchGeoLocationFailed = createAction();
export const fetchGeoLocationSuccess = createAction<IGeoLocation[]>();
