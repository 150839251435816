import {Exist} from "components";
import React, {Component, MouseEvent, useCallback, useState} from "react";
import styled from "styled-components";
import chevron from "assets/images/icons/chevron_down.svg";

const AccordionButton = styled.button`
	padding: 0;
	margin: 0;
	background: transparent;
	border: none;
	color: currentColor;
	width: 60px;

	svg {
		width: 12px;
		height: 12px;
	}
`;

const AccordionItem = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;

	a {
	}

	&.open {
		> div,
		a,
		button {
			border-bottom: none;
		}
	}
`;

const AccordionWrapper = styled.div``;

const Icon = styled.img`
	transition: 0.25s;

	&.open {
		transform: rotate(180deg);
	}
`;

interface IAccordionProps {
	fullWidth?: boolean;
	item: React.ComponentElement<unknown, Component>;
	children: React.ReactNode;
}

export const HOCAccordion: React.FC<IAccordionProps> = ({item, fullWidth, children = null}) => {
	const [isMenuOpen, setMenuOpen] = useState(false);
	const isMenuOpenClass = isMenuOpen ? "open" : "";

	const toggleMenu = useCallback(
		(e: MouseEvent) => {
			e.stopPropagation();
			setMenuOpen((isOpen) => !isOpen);
		},
		[setMenuOpen]
	);

	const closeHandler = useCallback(() => {
		if (fullWidth) {
			setMenuOpen((isOpen) => !isOpen);
		} else {
			setMenuOpen(false);
		}
	}, [setMenuOpen, fullWidth]);

	return (
		<AccordionWrapper className="accordion">
			<AccordionItem className={isMenuOpenClass} onClick={closeHandler}>
				{item}
				<AccordionButton className="accordion-button" onClick={toggleMenu}>
					<Icon
						src={chevron}
						alt="chevron"
						color="currentColor"
						className={isMenuOpenClass}
					/>
				</AccordionButton>
			</AccordionItem>
			<Exist when={isMenuOpen}>{children}</Exist>
		</AccordionWrapper>
	);
};
