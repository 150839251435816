import React, {useCallback, useRef} from "react";
import styled from "styled-components";
import {media} from "assets/css/media";
import {SponsorBanner} from "./common";

const ModalWrapper = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.5);
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;

	@media screen and (max-width: ${media.mobile}) {
		align-items: flex-end;
	}
`;

interface IModalProps {
	width?: string;
}

const ModalInner = styled.div<IModalProps>`
	background: #fff;
	position: relative;
	box-sizing: border-box;
	max-height: 90%;
	max-width: ${({width}) => width || "600px"};
	width: 100%;
	overflow: auto;
	border-radius: 10px;

	@media screen and (max-width: ${media.mobile}) {
		max-width: 100%;
		z-index: 1000;
		left: 0;
		bottom: 0;
		max-height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 50px;
		border-radius: 10px 10px 0 0;
	}
`;

interface IProps {
	children?: React.ReactNode;
	onClose?: (args: React.SyntheticEvent<HTMLElement>) => null | void;
	width?: string;
}

export const BottomModal: React.FC<IProps> = ({children = null, onClose, ...rest}: IProps) => {
	const ref = useRef<HTMLDivElement>(null);

	const closeHandler = useCallback(
		(e: React.SyntheticEvent<HTMLElement>) => {
			if (!ref?.current?.contains(e.target as Node)) {
				onClose?.(e);
			}
		},
		[onClose]
	);

	return (
		<ModalWrapper className="backdrop" onClick={closeHandler}>
			<ModalInner {...rest} className="modal-inner" ref={ref}>
				{children}
				<SponsorBanner />
			</ModalInner>
		</ModalWrapper>
	);
};
