import {IUser} from "modules/types";
import {Storage} from "modules/utils";

export abstract class User {
	public static SAVE(user: IUser): void {
		Storage.SET("user", user);
		Storage.SET("is_authenticated", "true");
	}

	public static CLEAR(): void {
		["user", "is_authenticated"].forEach((key) => Storage.REMOVE(key));
	}

	public static GET(): IUser | null {
		return JSON.parse(Storage.GET("user") || "null") as IUser | null;
	}

	public static IS_AUTHENTICATED(): boolean {
		return JSON.parse(Storage.GET("is_authenticated") || "false") as boolean;
	}
}
