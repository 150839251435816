import React from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {PureButton} from "components/Button";
import {useDispatch, useSelector} from "react-redux";
import {openTutorial, toggleMenu} from "modules/actions";
import {useLocation, useNavigate} from "react-router-dom";
import {isMenuOpen} from "modules/selectors";
import {PROJECT_NAME, TUTORIAL} from "modules";

const TutorialButton = styled(PureButton)`
	position: relative;
	color: #505b73;
	font-size: 16px;
	font-weight: 500;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-inline-end: 20px;
	margin-inline-start: 20px;

	&:after {
		content: "";
		transition: width ease-in-out 250ms;
		position: absolute;
		width: 0;
		left: 50%;
		transform: translateX(-50%);
		bottom: 14px;
		height: 3px;
		background: linear-gradient(90deg, #9b26b6 0.2%, #e93cac 105.78%);
	}

	&:hover {
		background: linear-gradient(90deg, #9b26b6 0.2%, #e93cac 105.78%);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;

		&:after {
			width: 100%;
		}
	}

	&.menu-tutorial-button {
		display: block;
		font-size: 14px;
		font-weight: 400;
		padding: 14px 0;
		line-height: 160%;
		width: 100%;
		text-align: start;
		color: #ffffff;
		text-transform: uppercase;
		font-family: var(--fontFamilyBase);
		margin-inline-end: 0;
		margin-inline-start: 0;

		&:after {
			display: none;
		}

		&:hover {
			background: none;
			background-clip: border-box;
			-webkit-background-clip: border-box;
			-webkit-text-fill-color: currentColor;
			color: #00b8ff;
		}
	}
`;

const useIsMatch = () => {
	const {pathname} = useLocation();
	const allowedLocations = TUTORIAL[PROJECT_NAME];

	return Array.isArray(allowedLocations) ? allowedLocations.includes(pathname) : pathname === "/";
};

interface ITutorialLink {
	className?: string;
}

export const TutorialLink: React.FC<ITutorialLink> = ({className}) => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const navigate = useNavigate();
	const isOpen = useSelector(isMenuOpen);
	const isHome = useIsMatch();

	const startTutorial = () => {
		if (isOpen) {
			dispatch(toggleMenu(false));
		}

		if (!isHome && PROJECT_NAME !== "MATCH_PREDICTOR") {
			navigate("/", {
				state: {
					tutorial: true,
				},
			});
		}

		dispatch(openTutorial());
	};

	return (
		<TutorialButton onClick={startTutorial} className={className}>
			{t("subheader.fifa.tutorial")}
		</TutorialButton>
	);
};
