import {createAction} from "redux-act";
import {
	IPredictions,
	IPredictionGroup,
	IPredictionKnockout,
	IPredictionsScoring,
	IBracketSquad,
} from "apps/BRACKET_PREDICTOR/modules/reducers";
import {Tab} from "apps/BRACKET_PREDICTOR/components";

export const fetchPredictions = createAction();
export const fetchPredictionsSuccess = createAction<IPredictions>();
export const fetchPredictionsFailed = createAction<Error["message"]>();

export const fetchRealPredictionsResult = createAction();
export const fetchRealPredictionsResultSuccess = createAction<IPredictions>();
export const fetchRealPredictionsResultFailed = createAction<Error["message"]>();

export const setDisplayRealPredictionsResult = createAction<boolean>();

export const addPredictionToGroupStage = createAction<IPredictionGroup>();

export const changePlaceOfPredictions = createAction<{
	draggablePosition: number;
	droppablePosition: number;
	allowedSquadsIds: number[];
	selectedPredictionsInThisGroup: IPredictionGroup[];
}>();

export interface IPddPredictionToKnockoutStagePayload {
	prediction: IPredictionKnockout;
	state: "R16" | "QF" | "SF" | "F";
}

export const addPredictionToKnockoutStage = createAction<IPddPredictionToKnockoutStagePayload>();

export const savePredictions = createAction();
export const savePredictionsSuccess = createAction<IPredictions>();
export const savePredictionsFailed = createAction<Error["message"]>();

export const movePredictionsToTemporaryState = createAction();
export const saveTemporaryPredictions = createAction();
export const saveTemporaryPredictionsSuccess = createAction<IPredictions>();
export const saveTemporaryPredictionsFailed = createAction<Error["message"]>();

export const clearPredictions = createAction();
export const clearPredictionsSuccess = createAction();
export const clearPredictionsFailed = createAction<Error["message"]>();

export const autopickPredictions = createAction();
export const autopickPredictionsSuccess =
	createAction<{predictions: IPredictions; isGroupFilled: boolean}>();
export const autopickPredictionsFailed = createAction<Error["message"]>();

export const resetGroupStage = createAction<IBracketSquad[]>();

export const fetchPredictionsScores = createAction();
export const fetchPredictionsScoresSuccess = createAction<IPredictionsScoring>();
export const fetchPredictionsScoresFailed = createAction<Error["message"]>();

export const fetchPredictionsScoresAfterLockout = createAction();
export const fetchPredictionsScoresAfterLockoutSuccess = createAction<IPredictionsScoring>();
export const fetchPredictionsScoresAfterLockoutFailed = createAction<Error["message"]>();

export interface IAnotherLeagueMemberPredictions {
	userId: number;
}

export const fetchAnotherLeagueMemberPredictions = createAction<IAnotherLeagueMemberPredictions>();
export const fetchAnotherLeagueMemberPredictionsSuccess = createAction<IPredictions>();
export const fetchAnotherLeagueMemberPredictionsFailed = createAction<Error["message"]>();

export const changeActiveTab = createAction<Tab>();
