import {media} from "assets/css/media";
import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import gotcBannerImage from "assets/images/gotc_banner_image.png";
import gotcBannerImageMobile from "assets/images/gotc_banner_image_mobile.png";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {getIsAuth, getUser} from "modules/selectors";
import {every, get, isNull} from "lodash";
import {ReactComponent as CloseIcon} from "assets/images/icons/close.svg";
import {PureButton} from "components/Button";
import {useNavigate} from "react-router-dom";
import {MatchPredictorBanner} from "components";
import * as ls from "local-storage";
import {DateTime} from "luxon";

const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	flex-grow: 1;
	position: relative;

	@media (max-width: ${media.desktop}) {
		margin: 0;
		padding: 0;
	}
`;

const InfoLine = styled.div`
	width: 100%;
	min-height: 58px;
	background: url(${gotcBannerImage}) no-repeat 0 50% #002c5f;
	background-size: contain;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 20px 0 220px;
	position: relative;

	@media (max-width: ${media.mobile}) {
		background: url(${gotcBannerImageMobile}) no-repeat 0 50% #002c5f;
		background-size: contain;
		height: auto;
		min-height: 148px;
		flex-direction: column;
		padding: 35px 10px 10px 90px;
	}

	p {
		color: #fff;
		font-family: "Poppins", sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 160%;
		display: flex;
		align-items: center;

		@media (max-width: ${media.mobile}) {
			width: 100%;
			justify-content: flex-end;
			font-size: 14px;
			font-weight: 400;
			line-height: 130%;
		}
	}
`;
const ButtonCell = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media (max-width: ${media.mobile}) {
		width: 100%;
		justify-content: flex-end;
	}
`;
const Button = styled.button`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 6px 14px;
	gap: 10px;
	width: 188px;
	height: 34px;
	background: #36f597;
	border-radius: 5px;
	font-family: "Poppins", sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 160%;
	text-align: center;
	text-transform: capitalize;
	color: #002c5f;
	outline: none;
	border: none;
	margin: 0 5px 0 10px;

	@media (max-width: ${media.mobile}) {
		font-size: 12px;
		width: 178px;
	}
`;
const CloseButton = styled(PureButton)`
	position: relative;
	cursor: pointer;
	width: 40px;
	height: 40px;
	z-index: 2;

	@media (max-width: ${media.mobile}) {
		position: absolute;
		top: 0;
		right: 0;
	}

	svg {
		vertical-align: middle;

		path {
			fill: #fff;
		}
	}
`;

export const GotcBanner: React.FC = () => {
	const {t} = useTranslation("match_predictor");
	const navigate = useNavigate();
	const isAuth = useSelector(getIsAuth);
	const user = useSelector(getUser);
	const isGotcLeagueModalViewed = get(user, "isGotcLeagueModalViewed", null);

	const lastDateBannerViewed = JSON.parse(ls.get("lastDateBannerViewed") || "null") as
		| string
		| null;
	const [isOpen, setIsOpen] = useState<boolean | null>(null);

	const dateNow = DateTime.now().toString();
	const start = DateTime.fromISO(lastDateBannerViewed ?? dateNow);
	const end = DateTime.fromISO(dateNow);

	const {days} = end.diff(start, "days").toObject();

	useEffect(() => {
		if (days && days > 1) {
			ls.remove("lastDateBannerViewed");
			setIsOpen(true);
		}
	}, [days]);

	useEffect(() => {
		if (isNull(lastDateBannerViewed)) {
			setIsOpen(true);
		}
	}, [lastDateBannerViewed]);

	const onClose = () => {
		ls.set("lastDateBannerViewed", JSON.stringify(Date.now()));
		setIsOpen(false);
	};

	const isShowBanner = every([isAuth, isOpen]);

	const toGotcLeague = useCallback(() => {
		navigate("/hyundai-league");
	}, [navigate]);

	const incompleteBodyCopy = t("hyundai.banner.body_incomplete", "Incomplete");
	const completeBodyCopy = t("hyundai.banner.body_complete", "Complete");

	const incompleteBtnCopy = t("hyundai.banner.button_incomplete", "Incomplete Btn");
	const completeBtnCopy = t("hyundai.banner.button_complete", "Complete Btn");

	const bannerCopy = isNull(isGotcLeagueModalViewed) ? incompleteBodyCopy : completeBodyCopy;
	const buttonCopy = isNull(isGotcLeagueModalViewed) ? incompleteBtnCopy : completeBtnCopy;

	return isShowBanner ? (
		<Container>
			<MatchPredictorBanner className="mobile" />
			<InfoLine>
				<p>{bannerCopy}</p>
				<ButtonCell>
					<Button onClick={toGotcLeague}>{buttonCopy}</Button>
					<CloseButton onClick={onClose} className="modal-close" aria-label="close">
						<CloseIcon />
					</CloseButton>
				</ButtonCell>
			</InfoLine>
		</Container>
	) : null;
};
