import React from "react";
import {useTranslation} from "react-i18next";
import {SectionTitle, SectionContent, SectionWrapper} from "./common";
import {CONTACT_EMAIL} from "modules";

export const Contact: React.FC = () => {
	const {t} = useTranslation();

	return (
		<SectionWrapper>
			<SectionTitle>{t("help.fifa.contact_us_header")}</SectionTitle>
			<SectionContent>
				<p>{t("help.fifa.contact_us_desc")}</p>
			</SectionContent>
			<SectionContent>
				<p>
					<a href={"mailto:" + CONTACT_EMAIL}>{CONTACT_EMAIL}</a>
				</p>
			</SectionContent>
		</SectionWrapper>
	);
};
