import {IAchievement, ITrophy} from "apps/GAME_ZONE/modules/reducers";
import {projectRootReducer} from "apps/GAME_ZONE/modules/store";
import {DayStatus, GameStatus, MatchDayStatus, Stage} from "apps/GAME_ZONE/modules/utils";
import {LanguageType, StatusType} from "modules";
import {IError} from "modules/types";

export type IGameZoneStore = ReturnType<typeof projectRootReducer>;

export interface IAchievementsResponse {
	success: {
		achievements: IAchievement[];
	};
	errors: IError[];
}

export interface ITrophiesResponse {
	success: {
		trophies: ITrophy[];
	};
	errors: IError[];
}

export enum GameType {
	CONTENT_BRACKETS = "content_brackets",
	TRIVIA = "trivia",
	DRAW_PREDICTOR = "draw_predictor",
	FIVES = "fives",
	SUSTAINABILITY_QUIZ = "sustainability_quiz",
	FANTASY_CLASSIC = "fantasy_classic",
	FANTASY_DAILY = "fantasy_daily",
	BRACKET_PREDICTOR = "bracket_predictor",
	PLAYER_OF_THE_MATCH_VOTE = "player_of_the_match_vote",
	MATCH_PREDICTOR = "match_predictor",
	WHOAMI = "whoami",
	GAMEZONE = "gamezone",
	GOTT = "goal_of_the_tournament",
	PANINI = "panini",
	BEST_AWARDS = "best_awards",
	GOALSCORER = "goalscorer",
	FANCESTRY_QUIZ = "fancestry_quiz",
}

interface ITile {
	lockout: boolean;
	position: number;
	title: string;
	textColor: string;
	timerColor: string;
	timerTextColor: string;
	backgroundColor: string;
	userPlayed: boolean;
	lockoutDate: string;
	backgroundImageDesktop: string;
	backgroundImageMobile: string;
	/**
	 * For UI
	 */
	isPrimary?: boolean;
}

export interface IBracketPredictionTile extends ITile {
	game: GameType.BRACKET_PREDICTOR;
	points: number;
	rank?: number;
	lockoutDate: string;
	userPlayed: boolean;
}

export interface IMatchPredictionTile extends ITile {
	game: GameType.MATCH_PREDICTOR;
	maxPredictions: number;
	madePredictions: number;
	roundPoints: number;
	overallPoints: number;
	overallRank?: number;
}

export interface IFantasyClassicTile extends ITile {
	game: GameType.FANTASY_CLASSIC;
	playingPlayers: number;
	unavailablePlayers: number;
	availableTransfers: number;
	points: number;
	rank?: number;
}

export interface IFantasyDailyTile extends ITile {
	game: GameType.FANTASY_DAILY;
	todayPoints: number;
	points: number;
	dayNumber: number;
	dayStatus: DayStatus;
	lastDay: boolean;
}

export interface IWhoamiTile extends ITile {
	game: GameType.WHOAMI;
	userPlayedToday: boolean;
	streak: number;
	points: number;
}

export interface ISustainabilityQuizTile extends ITile {
	game: GameType.SUSTAINABILITY_QUIZ;
}

export interface IGottTile extends ITile {
	game: GameType.GOTT;
}

export interface ITriviaTile extends ITile {
	game: GameType.TRIVIA;
}

export interface IPotmTile extends ITile {
	game: GameType.PLAYER_OF_THE_MATCH_VOTE;
}

export interface IPaniniTile extends ITile {
	game: GameType.PANINI;
	className?: string;
}

export interface IFivesTile extends ITile {
	game: GameType.FIVES;
	points: number;
	position: number;
	rank: number;
	contestNumber: number | null;
	contestStatus: StatusType | null;
	lastContest: boolean;
	isPredictionMade: boolean;
}

export interface IBestAwardsTile extends ITile {
	game: GameType.BEST_AWARDS;
}

export interface IGoalscorerTile extends ITile {
	game: GameType.BEST_AWARDS;
	contestPoints: number;
	points: number;
	contestRank: number;
	rank: number;
	contestNumber: number | null;
	contestStatus: StatusType | null;
	lastContest: boolean;
	isPredictionMade: boolean;
}

export interface IContentBracketTile extends ITile {
	game: GameType.CONTENT_BRACKETS;
	competitionRound: null | "rd16" | "quarterfinals" | "semifinals" | "final";
	competitionRoundStatus: null | "complete" | "active";
	competitionTitles: null | Record<LanguageType, string>;
	userMadePickForCompetitionRound: boolean;
}

export interface IFancestryTile extends ITile {
	game: GameType.FANCESTRY_QUIZ;
}

export type TTileCard =
	| IBracketPredictionTile
	| IMatchPredictionTile
	| IFantasyClassicTile
	| IFantasyDailyTile
	| IWhoamiTile
	| ISustainabilityQuizTile
	| IGottTile
	| IPaniniTile
	| ITriviaTile
	| IContentBracketTile
	| IBestAwardsTile
	| IFancestryTile;

export interface ITilesResponse {
	primary: TTileCard[];
	other: TTileCard[];
	matchDay: number | null;
	gameStatus: GameStatus;
	matchDayStatus: MatchDayStatus;
	stage: Stage;
	tournamentStarted: boolean;
	tournamentFinished: boolean;
	scheduledTodayGamesExist: boolean;
	lastMatchDay: boolean;
}

export interface IChallenge {
	name: string;
	game: string;
	date: string;
	earned: boolean;
}

export interface IChallengeStreak {
	longestStreak: number;
	currentStreak: number;
	isOnStreak: boolean;
}

export interface IChallengesResponse {
	challenges: IChallenge[];
}
