import styled from "styled-components";
import {Checkbox, FormControlLabel, FormLabel, MenuItem, Radio, TextField} from "@mui/material";

export const FormInput = styled(TextField)`
	& {
		.MuiInput-root {
			font-family: var(--fontFamilyBase);
		}

		.MuiFormHelperText-root {
			font-family: var(--fontFamilyBase);
		}

		.MuiInput-input {
			font-size: 14px;
		}

		input {
			font-family: var(--fontFamilyBase);
		}

		fieldset {
			font-family: var(--fontFamilyBase);
		}

		.Mui-error fieldset {
			font-family: var(--fontFamilyBase);

			legend span {
				font-family: var(--fontFamilyBase);
			}
		}

		label {
			font-family: var(--fontFamilyBase);
			font-size: 14px;
			color: #6a6967;

			&.Mui-focused {
				color: #6a6967;
			}
		}

		&.custom-arrow .MuiSelect-icon {
			top: auto;
		}
	}
`;

export const FormCheckbox = styled(Checkbox)``;

export const FormRadio = styled(Radio)`
	&.Mui-checked {
		color: ${({theme}) => theme.leagues.leagues_checkbox_active} !important;
	}
`;

export const FormInputLabel = styled(FormLabel)`
	&.MuiFormLabel-root {
		font-family: var(--fontFamilyBase);
	}
`;

export const FormCheckboxWithLabel = styled(FormControlLabel)`
	& .MuiFormControlLabel-label {
		font-family: var(--fontFamilyBase);
		color: ${({theme}) => theme.leagues.leagues_text_tertiary};
		font-size: 14px;
		font-weight: 500;
		line-height: 18px;

		a {
			text-decoration: underline;
		}
	}
`;

export const FormMenuItem = styled(MenuItem)`
	&.MuiMenuItem-root {
		font-family: var(--fontFamilyBase);
	}
`;
