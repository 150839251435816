import {createAction} from "redux-act";
import {
	ITeam,
	ILineup,
	IFormation,
	INewTeam,
	IAutoPick,
	IShowTeam,
} from "apps/FANTASY_DAILY/modules/types";

export const requestTeams = createAction();
export const requestTeamsSuccess = createAction<IShowTeam[]>();
export const requestTeamsFailed = createAction<Error["message"]>();

export interface IAnotherUserTeamPayload {
	userId: number;
	matchdayId: number;
}

export const requestAnotherUserTeam = createAction<IAnotherUserTeamPayload>();
export const requestAnotherUserTeamSuccess = createAction<IShowTeam>();
export const requestAnotherUserTeamFailed = createAction<Error["message"]>();

export interface ICreateTeamPayload {
	lineup: ILineup;
	formation: IFormation;
	matchDay: number;
}

export const createTeam = createAction<ICreateTeamPayload>();
export const createTeamSuccess = createAction<ITeam>();
export const createTeamFailed = createAction<Error["message"]>();

export interface IUpdateTeamPayload {
	lineup: ILineup;
	formation: IFormation;
	matchDay: number;
}

export const updateTeam = createAction<IUpdateTeamPayload>();
export const updateTeamSuccess = createAction<ITeam>();
export const updateTeamFailed = createAction<Error["message"]>();

export const setTeamFormation = createAction<IFormation>();
export const setTeamFormationSuccess = createAction<INewTeam>();

export const updateLineup = createAction<ILineup>();

export const pickPlayerToTeam = createAction<number>();
export const removePlayerFromTeam = createAction<number>();
export const removePlayersFromTeam = createAction();

export const updateStarPlayers = createAction<number>();

export interface IAutoPickPayload {
	formation: IFormation;
	lineup: ILineup;
	matchDay: number;
}

export const autoPick = createAction<IAutoPickPayload>();
export const autoPickSuccess = createAction<IAutoPick>();
export const autoPickFailed = createAction<Error["message"]>();

export const setTeam = createAction<ITeam>();
export const clearTeam = createAction();

interface ITerms {
	termsFantasyDaily: boolean;
	optinCommsBankTermsAndConditions: boolean;
	optinCommsBank: boolean;
}

export interface ITermsAndTeamPayload {
	type: "create" | "update";
	terms: ITerms;
	team: ICreateTeamPayload;
}

export const acceptTermsAndSaveTeam = createAction<ITermsAndTeamPayload>();
