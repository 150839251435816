import {createAction} from "redux-act";
import {IColumnSortDirection} from "apps/GOALSCORER/modules/types";
import {FilterStats} from "apps/GOALSCORER/modules/enums";

export const openPlayerPool = createAction();
export const closePlayerPool = createAction();

export const openFilters = createAction();
export const closeFilters = createAction();

export interface IFiltersPayload {
	search?: string;
	competition?: number[];
	country?: number;
	stat?: FilterStats;
}

export const setFilters = createAction<IFiltersPayload>();

export const setColumnSort = createAction<IColumnSortDirection>();
