import React, {useEffect, useState, Fragment} from "react";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import {initReactI18next} from "react-i18next";
import {JSON_URL, LANGUAGE_PATH, SUPPORTED_LANGUAGES} from "modules/constants";
import {PagePreloader} from "components";
import {Settings} from "luxon";
import {i18nextPlugin} from "translation-check";
import {useQuery} from "modules";

interface IProps {
	children: React.ReactNode;
}

export const HOCi18n: React.FC<IProps> = ({children}) => {
	const [initialized, setInitialized] = useState(false);
	const queryParam = useQuery();
	const isShowKeys = queryParam.get("showTranslations") === "true";
	const isShowAllKeys = queryParam.get("cimode") === "true";

	useEffect(() => {
		i18n.use(LanguageDetector)
			.use(Backend)
			.use(initReactI18next)
			.use(i18nextPlugin)
			.init(
				{
					/**
					 * i18nextBrowserLanguageDetector
					 */
					detection: {
						order: ["path", "cookie", "localStorage", "sessionStorage", "navigator"],
						// Index is need check by 0 for Game Zone because this project is located in /
						lookupFromPathIndex: LANGUAGE_PATH === "game_zone" ? 0 : 1,
					},
					ns: ["global", LANGUAGE_PATH],
					supportedLngs: SUPPORTED_LANGUAGES,
					backend: {
						crossDomain: true,
						loadPath: `${JSON_URL}langs/{{ns}}/{{lng}}.json`,
					},
					debug: false, //ENV !== "production",
					keySeparator: ".",
					interpolation: {
						escapeValue: false,
						formatSeparator: ",",
					},
					fallbackLng: isShowKeys ? false : "en",
					returnEmptyString: !isShowKeys,
					parseMissingKeyHandler: function (key, defaultValue) {
						return isShowKeys ? key : defaultValue;
					},
					// i18next react doesn't have enough types
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					translationStats: {
						// optional options, if not provided it will guess based on your i18next config
						queryStringParam: "showTranslationsOwnTab",
						preserveEmptyStrings: false,
					},
				},
				() => {
					document.dir = i18n.dir();
					document.body.classList.add(i18n.dir());
					Settings.defaultLocale = i18n.language;
					setInitialized(true);
					if (isShowAllKeys) {
						void i18n.changeLanguage("cimode");
					}
				}
			)
			.catch((err) => console.log(err));
	}, [isShowKeys, isShowAllKeys]);

	return initialized ? <Fragment>{children}</Fragment> : <PagePreloader />;
};
