import React from "react";
import styled from "styled-components";
import twitter from "assets/images/icons/twitter.svg";
import facebook from "assets/images/icons/facebook_2.svg";
import instagram from "assets/images/icons/instagram.svg";
import youtube from "assets/images/icons/youtube.svg";
import ticket from "assets/images/icons/tiktok.svg";
import {media} from "assets/css/media";

const Wrapper = styled.div`
	display: flex;
	align-items: center;

	@media (max-width: ${media.mobile}) {
		display: none;
	}
`;

const SocialLink = styled.a`
	margin-inline-start: 16px;
	margin-inline-end: 16px;
`;

const SOCIAL_LINKS = [
	{
		name: "Twitter",
		icon: twitter,
		href: "https://twitter.com/FIFAcom",
	},
	{
		name: "Facebook",
		icon: facebook,
		href: "https://www.facebook.com/fifa",
	},
	{
		name: "Instagram",
		icon: instagram,
		href: "https://www.instagram.com/fifaworldcup/",
	},
	{
		name: "Youtube",
		icon: youtube,
		href: "https://www.youtube.com/FIFATV",
	},
	{
		name: "Tiktok",
		icon: ticket,
		href: "https://www.tiktok.com/@fifaworldcup",
	},
];

export const SocialLinks: React.FC = () => {
	return (
		<Wrapper>
			{SOCIAL_LINKS.map((link) => (
				<SocialLink key={link.name} href={link.href}>
					<img src={link.icon} alt={link.name} />
				</SocialLink>
			))}
		</Wrapper>
	);
};
