import Cookies from "js-cookie";
import {get, isString} from "lodash";
import {IFifaSessionCookie} from "modules/types";
import {ProjectName} from "modules/enums";

export const ENV = process.env.REACT_APP_ENVIRONMENT;
export const BASE_URL = process.env.REACT_APP_BASE_URL || "";
export const BASE_DOMAIN = process.env.REACT_APP_BASE_DOMAIN || "";
export const REDIRECT_SSO_URL = process.env.REACT_APP_SSO_REDIRECT_URL || "";
export const API_URL = process.env.REACT_APP_API_URL || "";
export const JSON_URL = process.env.REACT_APP_JSON_URL || "";
export const PROJECT_NAME = process.env.REACT_APP_PROJECT_NAME || "";
export const PROJECT_JSON_PATH = process.env.REACT_APP_PROJECT_JSON_PATH || "";
export const PROJECT_API_PATH = process.env.REACT_APP_PROJECT_API_PATH || "";
export const PROJECT_GLOBAL_DATA_PATH = process.env.REACT_APP_PROJECT_GLOBAL_DATA_PATH || "";
export const IMAGES_URL = process.env.REACT_APP_IMAGES_URL || "";
export const QUESTIONS_URL = process.env.REACT_APP_QUESTION_IMAGES_URL || "";
export const CONTEST_IMAGE_URL = process.env.REACT_APP_CONTEST_IMAGES_URL || "";
export const LANGUAGE_PATH = process.env.REACT_APP_PROJECT_PATH || "";
export const PROMO_NAME = process.env.REACT_APP_PROJECT_PROMO_NAME || "";
export const ADOBE_GAME_NAME = process.env.REACT_APP_ADOBE_GAME_NAME || "";
export const ADOBE_PAGE_NAME = process.env.REACT_APP_ADOBE_PAGE_NAME || "";
export const TAGBOARD_APP_ID = process.env.REACT_APP_TAGBOARD_APP_ID || "";
export const CONTACT_EMAIL = process.env.REACT_APP_CONTACT_EMAIL || "";
export const LOADER_BACKGROUND = process.env.REACT_APP_LOADER_BACKGROUND || "#03122B";
export const LOGOSTRIP_COMPETITION = process.env.REACT_APP_LOGOSTRIP_COMPETITION || "";
export const PRESEASON = JSON.parse(process.env.REACT_APP_PRESEASON || "false") as boolean;
export const IS_PRODUCTION = ENV === "production";
export const IS_LEAGUE_SHARE_DISABLED = JSON.parse(
	process.env.REACT_APP_IS_LEAGUE_SHARE_DISABLED || "false"
) as boolean;
export const IS_SHARE_DISABLED = JSON.parse(
	process.env.REACT_APP_IS_SHARE_DISABLED || "false"
) as boolean;
export const FAN_SSO_ENABLED = JSON.parse(
	process.env.REACT_APP_FAN_SSO_ENABLED || "false"
) as boolean;
export const SHOW_LOGOSTRIP = JSON.parse(
	process.env.REACT_APP_SHOW_LOGOSTRIP || "false"
) as boolean;

export const IS_GOTC_LEAGUE_ENABLE = JSON.parse(
	process.env.REACT_APP_IS_GOTC_LEAGUE_ENABLE || "false"
) as boolean;

export const SECRET_KEY_NAME = "enter_play_zone";
const isHideSecretKey = new URLSearchParams(window.location.search).get(SECRET_KEY_NAME);

if (isHideSecretKey) {
	localStorage.setItem(SECRET_KEY_NAME, "true");
}

export const HIDE_SECRET_KEY = JSON.parse(
	localStorage.getItem(SECRET_KEY_NAME) ||
		isHideSecretKey ||
		process.env.REACT_APP_IS_HIDE_SECRET ||
		"false"
) as boolean;

export const QUESTIONS_IMAGE_URL = `${QUESTIONS_URL}`;
export const CONTEST_IMAGES_URL = `${CONTEST_IMAGE_URL}`;

export const QUIZ_IMAGE_URL = `${IMAGES_URL}image/trivia/quizzes/`;
export const OPTION_IMAGE_URL = `${IMAGES_URL}image/trivia/options/`;

export const STORAGE_VERSION = 1;

const THOUSAND = 1000;
export const TIME_FOR_ANSWER: number = 19 * THOUSAND;
export const TIME_FOR_REVIEW_RESULT: number = 6 * THOUSAND;
export const EXTRA_TIME: number = 10 * THOUSAND;

const ALLOWED_LANGUAGES_STRING =
	process.env.REACT_APP_ALLOWED_LANGS || "en,es,fr,de,ar,pt,it,id,ko,ja";
export const ALLOWED_LANGUAGES = ALLOWED_LANGUAGES_STRING.split(",").map((lang) => lang.trim());

const SUPPORTED_LANGUAGES_STRING = process.env.REACT_APP_SUPPORTED_LANGS || "en,es,fr,de,ar,pt";
export const SUPPORTED_LANGUAGES = SUPPORTED_LANGUAGES_STRING.split(",").map((lang) => lang.trim());

export const LANGUAGES = [
	{name: "English", code: "en"},
	{name: "Français", code: "fr"},
	{name: "Deutsch", code: "de"},
	{name: "Español", code: "es"},
	{name: "Português", code: "pt"},
	{name: "Bahasa Indo", code: "id"},
	{name: "한국어", code: "ko"},
	{name: "عربى", code: "ar"},
].filter((language) => SUPPORTED_LANGUAGES.includes(language.code));

export const SUST_QUIZ_STORAGE_KEY = "r_quiz_answers";
export const SUST_QUIZ_COUNTRY_STORAGE_KEY = "r_country";

const fifaSession = Cookies.get("fifa_session");
const fifaSessionObject = JSON.parse(fifaSession || "null") as IFifaSessionCookie | null;
const fifaHash = Cookies.get("fifa_hash");
export const IS_APP = isString(fifaSession) && isString(fifaHash);
export const DEVICE = get(fifaSessionObject, "device", "web");
export const HEADER_HEIGHT = 178;
export const HEADER_HEIGHT_LD = 178;
export const HEADER_HEIGHT_TB = 102;
export const TOP_HEADER_HEIGHT = 32;
export const TOP_HEADER_HEIGHT_LD = 60;

export const PROJECTS_WITHOUT_CHECK_ON_LEAGUES = [
	ProjectName.WhoAmI,
	ProjectName.Goalscorer,
	ProjectName.Fives,
];

export * from "./navigation";
export * from "./landingPage";
export * from "./promoBanner";
export * from "./fantasyClassic";
