import {media} from "assets/css/media";
import styled from "styled-components";

export const SectionWrapper = styled.div`
	background-color: #fff;
	padding: 20px;
	border-bottom-left-radius: ${({theme}) => theme.help.help_border_radius};
	border-bottom-right-radius: ${({theme}) => theme.help.help_border_radius};

	@media (max-width: ${media.mobile}) {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
`;

export const SectionTitle = styled.div`
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 16px;
`;

export const SectionContent = styled.div`
	h1,
	h2,
	h3,
	h4,
	h5 {
		font-size: 16px;
		margin-bottom: 16px;
	}

	ul {
		list-style: disc outside none;
		padding-inline-start: 20px;
	}

	ul,
	p {
		font-size: 14px;
		line-height: 22px;
		margin-bottom: 16px;
	}

	a {
		text-decoration: underline;
	}

	strong {
		font-weight: 500;
	}

	table {
		width: 100%;
		td {
			padding: 20px;
			border: 1px solid;
		}
	}

	@media (max-width: ${media.mobile}) {
		h1,
		h2,
		h3,
		h4,
		h5 {
			font-size: 14px;
		}
	}
`;
