import {eq, find, first} from "lodash";
import {IStore} from "modules/types/store";
import {createSelector} from "reselect";

export const getJsonState = (state: IStore) => state.jsons;

export const getCountriesJson = createSelector(getJsonState, ({countries}) => countries);

export const getCountriesAsArray = createSelector(getCountriesJson, (countries) =>
	countries ? Object.entries<string>(countries) : []
);
export const getCountryByKey = createSelector(
	getCountriesAsArray,
	(countries) => (key: string) => find(countries, ([value, _name]) => eq(value, key))
);

export const getChecksums = createSelector(getJsonState, (jsons) => jsons.checksums);

export const getGeoLocation = createSelector(getJsonState, ({geoLocation}) => geoLocation);

export const getCountryCode = createSelector(getGeoLocation, (geoLocation) => {
	if (geoLocation) {
		return first(geoLocation)?.CountryCode ?? "GB";
	}
	return "GB";
});

export const getIsNZAUUser = createSelector(getCountryCode, (countryCode) =>
	["AU", "NZ"].includes(countryCode)
);

export const getIsUSCAUser = createSelector(getCountryCode, (countryCode) =>
	["US", "CA"].includes(countryCode)
);

export const getIsBRUser = createSelector(getCountryCode, (countryCode) =>
	["BR"].includes(countryCode)
);

export const getIsUSCAMXUser = createSelector(getCountryCode, (countryCode) =>
	["US", "CA", "MX"].includes(countryCode)
);
