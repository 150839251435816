import {createAction} from "redux-act";
import {IGetSavedAnswersRequest, ISaveAnswers, ISelectedOption} from "apps/FIVES/modules/types";

export const postAnswers = createAction<ISaveAnswers>();
export const postAnswersSuccess = createAction<ISaveAnswers>();
export const postAnswersFailed = createAction<Error["message"]>();

export const saveEditedAnswers = createAction<ISelectedOption[]>();

export const getSavedAnswers = createAction<IGetSavedAnswersRequest>();
export const getSavedAnswersSuccess = createAction<ISaveAnswers>();
export const getSavedAnswersFailed = createAction<Error["message"]>();
